import { useEffect, useState } from 'react';
import { Link } from 'react-router-dom';
import { useStyles } from './sidebar.styles';
import { SidebarEnum } from './types';

export const Sidebar = (): JSX.Element => {
  const [active, setActive] = useState<SidebarEnum>(SidebarEnum.profile);
  const classes = useStyles();
  const activeDiv = (value: SidebarEnum): void => {
    setActive(value);
  };

  useEffect(() => {
    const inc = (): SidebarEnum => {
      if (window.location.href.includes('profile')) {
        return SidebarEnum.profile;
      }
      if (window.location.href.includes('group')) {
        return SidebarEnum.groups;
      }
      return SidebarEnum.subscribe;
    };
    setActive(inc);
  }, []);

  return (
    <div className={classes.container}>
      <div className={classes.club}><h5 className={classes.titleSidebar}>#Клуб</h5></div>
      <Link className={classes.link} to={SidebarEnum.profile}>
        <div className={active === SidebarEnum.profile ? classes.active : classes.item} onClick={(): void => activeDiv(SidebarEnum.profile)}>
          <div className={classes.elem}><i className={classes.profileSidebar}></i>
            <span>
            Мой профиль
            </span></div>
          <div><i className={classes.arrow}></i></div>
        </div>
      </Link>
      <Link className={classes.link} to={SidebarEnum.groups}>
        <div className={active === SidebarEnum.groups ? classes.active : classes.item} onClick={(): void => activeDiv(SidebarEnum.groups)}>
          <div className={classes.elem}><i className={classes.groupSidebar}></i><span>Группы</span></div>
          <div><i className={classes.arrow}></i></div>
        </div>
      </Link>
      <Link className={classes.link} to={SidebarEnum.subscribe}>
        <div className={active === SidebarEnum.subscribe ? classes.activeSub : classes.item} onClick={(): void => activeDiv(SidebarEnum.subscribe)}>
          <div className={classes.elem}><i className={classes.subscribeSidebar}></i><span>Подписки</span></div>
          <div><i className={classes.arrow}></i></div>
        </div>
      </Link>
    </div>
  );
};
