import { createUseStyles } from 'react-jss';
import { theme } from '../theme';

const PREFIX = 'ButtonLarge';
export const useStyles = createUseStyles({
  button: {
    boxSizing: 'border-box',
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    padding: '12px 24px',
    height: '44px',
    background: theme.backgroundGradientButton,
    borderRadius: '4px',
    border: 'none',
    fontFamily: theme.ibm,
    fontWeight: 500,
    fontSize: '16px',
    lineHeight: '20px',
    color: '#fff',
    '&:hover, &:active': {
      cursor: 'pointer',
      background: '#005EAC',
    },
    '&:disabled': {
      color: '#FFF',
      background: '#BDBDBD',
    },
  },
  icon: {
    marginRight: '8px',
  },
}, { name: PREFIX });
