import { useStyles } from './buttonGhost.styles';
import { IButtonGhost } from './types';

export const ButtonGhost = ({ title, disabled, icon, callback }: IButtonGhost): JSX.Element => {
  const classes = useStyles();

  return (
    <button disabled={disabled} className={classes.button} onClick={ (): unknown => (callback ? callback() : null)}>
      <img className={icon !== undefined ? classes.icon : undefined} src={icon} alt="" />
      {title}</button>
  );
};
