import { useContext, useEffect, useState } from 'react';
import { useLocation } from 'react-router-dom';
import { observer } from 'mobx-react-lite';
import { SelectCustom } from '../../components/selectCustom/SelectCustom';
import { appStoreContext } from '../../store/context/context.store';
import { PaginationCustom } from './pagination/PaginationCustom';
import { useStyles } from './subscriptions.styles';
import { TabsCustom } from './tabs/TabsCustom';
import 'rc-pagination/assets/index.css';

export const Subscriptions = observer((): JSX.Element => {
  const { state } = useLocation();
  // @ts-ignore
  const [mySubs, setMySub] = useState(!state?.fromMain);
  const callback = function(key: unknown): void {
    setMySub(!mySubs);
  };

  useEffect(() => {
    appStore.user.checkIsSuperAdmin();
  }, []);

  const classes = useStyles();
  const appStore = useContext(appStoreContext);

  const nameOfUsers = mySubs ? 'подписок' : 'пользователей';


  return (
    <div>
      <div className={classes.headerTop}>
        <h2 className={classes.titleSubscription}>Подписки</h2>
        <p className={classes.textSubscription}>Всего {nameOfUsers}: {appStore.subscriptions.subscription.total}</p>
      </div>
      <div>
        <TabsCustom mySubs={mySubs} callback={callback} />
      </div>
      <div className={classes.footer}>
        <div className={classes.footerTop}>
          <div>Всего {nameOfUsers}: {appStore.subscriptions.subscription.total}</div>
          <div className={classes.pagination}>
            <span>На странице по :</span>
            <div>
              <SelectCustom value={appStore.pagination.pagination.pageSize} name="paginationNumbers"
                className={classes.selectProfile} submit={appStore.pagination.onChangeSelectPagination}
                label={''}
                option={['10', '20', '30']} />
            </div>
            <PaginationCustom mySubs={mySubs} />
          </div>
        </div>
      </div>
    </div>
  );
});
