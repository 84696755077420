import React, { useEffect, useState } from 'react';
import classNames from 'classnames';
import Dialog from 'rc-dialog';
import Input from 'rc-input';
import userImg from '../../../assets/icons/modalUser.svg';
import { BlueBtn } from '../../../components/blueBtn/BlueBtn';
import { TransparentBtn } from '../../../components/transparentBtn/TransparentBtn';
import { IProps } from './types';
import { useStyles } from './userAdd.styles';
import 'rc-dialog/assets/index.css';

export const UserAddModal = ({ visible, onClose }: IProps): JSX.Element => {
  const [copy, setCopy] = useState(false);
  const classes = useStyles();
  const inputClass = classNames(
    classes.addUserInp,
    copy && classes.addUserInpActive,
  );

  useEffect(() => {
    if (!visible) {
      setCopy(false);
    }
  }, [visible]);

  const handleClose = (): void => {
    setCopy(true);
    navigator.clipboard.writeText(window.location.href);
  };

  return (
    <Dialog
      title={<img src={userImg} />}
      className={classes.appModal}
      animation="zoom"
      maskAnimation="fade"
      visible={visible}
      onClose={onClose}
    >
      <div className={classes.userAddContent}>
        <div className={classes.userAddContentBlock}>Добавить пользователей</div>
        <p className={classes.userAddContentText}>
          Вы можете отправить ссылку-приглашение любому пользователю,
          зарегистрированному в системе UniOne
        </p>
      </div>
      <Input
        value={`${window.location.href}`}
        className={inputClass}
      />
      <div>
        {copy
          ? <TransparentBtn className={classes.addUserBtn}>
            Ссылка скопирована
          </TransparentBtn>
          : <BlueBtn
            className={classes.addUserBtn}
            onClick={handleClose}>
            Копировать ссылку
          </BlueBtn>
        }
      </div>
    </Dialog>
  );
};
