import { createUseStyles } from 'react-jss';

const PREFIX = 'RadioInput';
export const useStyles = createUseStyles(
  {
    container: {
      display: 'flex',
    },
    checkAllUsers: {
      cursor: 'pointer',
      marginRight: '8px',
      width: '16px',
      height: '16px',
      appearance: 'none',
      borderRadius: '50%',
      background: '#fff',
      border: '3px solid #fff',
      boxShadow: '0 0 0 1px #E4E7F2',
      '&:checked': {
        background: '#0071CE',
      },
      '&:hover': {
        boxShadow: '0 0 0 1px #0071CE',
      },
      '&:disabled': {
        background: '#99C6EB',
        boxShadow: '0 0 0 1px #E4E7F2',
      },
    },
    bigText: {
      fontSize: '16px',
      fontWeight: '400',
      lineHeight: '20px',
      color: '#3B4168',
      fontFamily: 'IBM Plex Sans',
      whiteSpace: 'nowrap',
    },
  },
  { name: PREFIX },
);
