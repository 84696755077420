import React, { useContext, useEffect } from 'react';
import { Navigate, useParams } from 'react-router';
import classNames from 'classnames';
import { observer } from 'mobx-react-lite';
import iconPlus from '../../assets/icons/iconPlus.svg';
import { Avatar } from '../../components/avatar/Avatar';
import { ButtonGhost } from '../../components/buttonGhost/ButtonGhost';
import { ButtonLarge } from '../../components/buttonLarge/ButtonLarge';
import { academic_degree, academic_title, scien_title } from '../../shared/list.constants';
import { appStoreContext } from '../../store/context/context.store';
import { useStyles } from '../profile/profile.style';

export const User = observer((): JSX.Element => {
  const classes = useStyles();
  const select = classNames(classes.interest, classes.containerMinBlock);
  const appStore = useContext(appStoreContext);
  const { profile } = appStore.profile.profileData;
  const { id } = useParams();

  useEffect(() => {
    if (id) {
      appStore.user.getProfile(id);
    }
  }, [id]);

  if (!id) {
    return <Navigate to="/subscribe" replace />;
  }

  return (
    <div>
      <div className={classes.breadCrumb}>
        <div>Подписки</div>
        <span className={classes.breadCrumbLine}>|</span>
        <h5 className={classes.titleSection}>{profile.full_name}</h5>
      </div>
      <hr className={classes.lineShareSection} />
      <div className={classes.cap}>
        <div className={classes.avatar}>
          {
            profile.avatar ? (
              <img className={classes.img} src={appStore.config.baseS3URL + profile.avatar} alt="" />
            ) : (
              <Avatar isBig name={profile.full_name || 'Нет имени'} />
            )
          }
        </div>
        <div className={classes.data}>
          <div>
            <div className={classes.header}>
              <div className={classes.id}>ID: {profile._id}</div>
            </div>
            <div>
              <h2 className={classes.nameUser}>{profile.full_name}</h2>
            </div>
            {
              appStore.user.user._id !== appStore.profile.profileData.profile._id && (
                <div className={classes.buttons}>
                  {appStore.profile.profileData.isMeSubscribed
                    ? <ButtonGhost title="Отписаться" callback={(): void => appStore.subscriptions.deleteSubscription(id)}></ButtonGhost>
                    : <ButtonLarge callback={(): void => appStore.subscriptions.addFriend(id)} title="Подписаться" icon={iconPlus} ></ButtonLarge> }
                  {/* <ButtonGhost title="Написать" icon={messageIcon} /> */}
                </div>
              )
            }
          </div>
        </div>
      </div>
      <div className={classes.body}>
        <hr className={classes.lineShareSection} />
        <div className={classes.item}>
          <h5 className={classes.titleSection}>Сфера деятельности и интересов</h5>
          <div className={classes.top}>
            <div>
              <label className={classes.label} htmlFor="">Наименование организации</label>
              <p className={classes.textUser}>{profile.work?.trim() !== '' ? profile.work : '-' }</p>
            </div>
            <div>
              <label className={classes.label} htmlFor="">Ученая степень</label>
              <p className={classes.textUser}>{profile.academic_degree !== 0 ? academic_degree[profile.academic_degree] : '-'}</p>
            </div>
            <div>
              <label className={classes.label} htmlFor="">Ученое звание</label>
              <p className={classes.textUser}>{profile.academic_title !== 0 ? academic_title[profile.academic_title] : '-'}</p>
            </div>
          </div>
          <div className={classes.bottom}>
            <div className={classes.left}>
              <label className={classes.label} htmlFor="">Отрасль</label>
              <p className={classes.textUser}>{profile.scien_for_degree !== 0 ? scien_title[profile.scien_for_degree] : '-'}</p>
            </div>
            <div className={classes.right}>
              <label className={classes.label} htmlFor="">Профильное направление, специфика экспертной позиции</label>
              <p className={classes.textUser}>
                {profile.directions?.trim() !== '' ? profile.directions : '-'}
              </p>
            </div>
          </div>
        </div>
        <hr className={classes.lineShareSection} />
        <div className={classes.item}><h5 className={classes.titleSection}>Контактные данные</h5>
          <div className={classes.contacts}>
            <div>
              <label className={classes.label} htmlFor="">Email</label>
              <p className={classes.textUser}>{profile.contacts.email}</p>
            </div>
            <div>
              <label className={classes.label} htmlFor="">Telegram</label>
              <div className={classes.containerA}>
                <a className={classes.userLink} target="_blank" href={`https://t.me/${profile.contacts.telegram}`} rel="noreferrer">@{profile.contacts.telegram}</a>
              </div>
            </div>
            <div>
              <label className={classes.label} htmlFor="">VK</label>
              <div className={classes.containerA}>
                <a className={classes.userLink} target="_blank" href={`https://vk.com/${profile.contacts.vk}`} rel="noreferrer">vk.com/{profile.contacts.vk}</a>
              </div>
            </div>
            <div>
              <label className={classes.label} htmlFor="">Предпочитаю связываться по</label>
              <p className={classes.textUser}>{profile.preffered?.trim() !== '' ? profile.preffered : '-'}</p>
            </div>
          </div>
        </div>
        <hr className={classes.lineShareSection} />
        <div className={classes.item}>
          <div>
            <h5 className={classes.titleSection}>Портфолио</h5>
          </div>
          <div className={classes.portfolio}>
            <div className={classes.containerPortfolio}>
              <div className={select}>
                <label className={classes.label} htmlFor="">Научные интересы</label>
                <div className={classes.containerA}>
                  <p className={classes.textUser}>{profile.interests?.trim() !== '' ? profile.interests : '-'}</p>
                </div>
              </div>
              <div className={select}>
                <label className={classes.label} htmlFor="">Портфолио</label>
                <div className={classes.containerA}>
                  {profile.interests?.trim() !== ''
                    ? <a className={classes.userLink} target="_blank" href={profile.portfolio}
                      rel="noreferrer">{profile.portfolio}</a> : <div>-</div>}
                </div>
              </div>
              <div className={classes.containerMinBlock}>
                <label className={classes.label} htmlFor="">Реализованные проекты</label>
                {profile.projects.length === 0 ? <div>-</div> : profile.projects.map((el, i) => <p key={i}
                  className={classes.textUser}>{el.value}</p>)}
              </div>
            </div>
          </div>
        </div>
        <hr className={classes.lineShareSection} />
        <div className={classes.item}>
          <div className={classes.achievements}>
            <h5 className={classes.titleSection}>Мои достижения</h5>
            <p className={classes.subTitle}>сертификаты, дипломы, участие в проектах</p>
          </div>
          {profile.achieve && profile.achieve.map((el, i) => <a className={classes.userLink} key={i} href={el.value} target="_blank" rel="noreferrer">{el.value}</a>)}
        </div>
        <hr className={classes.lineShareSection} />
        <div className={classes.item}>
          <h5 className={classes.titleSection}>Список моих публикаций</h5>
          {profile.pubs && profile.pubs.map((el, i) => <a className={classes.userLink} key={i} href={el.value} target="_blank" rel="noreferrer">{el.value}</a>)}
        </div>
        <hr className={classes.lineShareSection} />
      </div>
    </div>
  );
});
