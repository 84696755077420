import { createUseStyles } from 'react-jss';

const PREFIX = 'GroupCreate';
export const useStyles = createUseStyles(
  {
    appModal: {
      width: '800px',
      height: '796px',
      fontFamily: 'Montserrat, sans-serif',

      '& .rc-dialog-title': {
        fontWeight: '600',
        fontSize: '24px',
        lineHeight: '28px',
        color: '#3B4168',
      },
      '& .rc-dialog-close': {
        width: '28px',
        height: '28px',
        color: '#909EBB',
        fontSize: '35px',
        fontWeight: '100',
        opacity: '1',
        fontStyle: 'normal',
        right: '41px',
        top: '27px',
      },
      '& .rc-dialog-content': {
        padding: '28px 40px 32px',
      },
      '& .rc-dialog-header': {
        padding: '0 0 24px 0',
      },
      '& .rc-dialog-body': {
        padding: '0',
        paddingTop: '32px',
      },
    },
    radioItems: {
      display: 'flex',
      marginTop: '16px',
      marginBottom: '24px',
    },
    btnMainBlock: {
      display: 'flex',
      justifyContent: 'space-between',
      alignItems: 'center',
      marginTop: '16px',
    },
    addButton: {
      marginTop: '16px',
      position: 'absolute',
    },
    inputStyle: {
      opacity: '0',
      position: 'absolute',
      lineHeight: '20px',
      width: '23%',
    },
    btnBlock: {
      display: 'flex',
      right: '0',
      justifyContent: 'flex-end',
      marginTop: '16px',
    },
    blockTransparentBtn: {
      marginRight: '16px',
    },
    borderNotImage: {
      height: '100%',
      display: 'flex',
      justifyContent: 'center',
      alignItems: 'center',
    },
    textBorder: {
      display: 'flex',
      alignItems: 'center',
      fontSize: '16px',
      lineHeight: '1.25',
      color: '#6F7C98',
    },
    textChooseFile: {
      color: '#0071CE',
      cursor: 'pointer',
    },
    paperClip: {
      marginRight: '40px',
      width: '32px',
      height: '32px',
    },
    avatarDasher: {
      border: '0.2rem dashed #E4E7F2',
      height: '80px',
      marginTop: '4px',
    },
    avatarLabel: {
      height: '100%',
      display: 'block',
    },
    littleText: {
      marginTop: '24px',
      fontSize: '14px',
      fontWeight: '400',
      lineHeight: '20px',
      color: '#6F7C98',
    },
    bigText: {
      fontSize: '16px',
      fontWeight: '400',
      lineHeight: '20px',
      color: '#3B4168',
    },
    checkAdmin: {
      marginLeft: '24px',
    },
    inpNameGroup: {
      width: '97%',
      height: '40px',
      border: '1px solid #E4E7F2',
      borderRadius: '4px',
      paddingLeft: '16px',
      margin: '4px 0 24px 0',
      fontSize: '16px',
      fontWeight: '400',
      lineHeight: '20px',
      color: '#3B4168',
    },
    deleteGroup: {
      border: 'none',
      color: '#EB5757',
      paddingLeft: '0px',
    },
    line: {
      width: '100%',
      borderBottom: '1px solid #e9e9e9',
      marginTop: '32px',
    },
    inpSelect: {
      marginBottom: '4px',
    },
    warning: {
      color: 'red',
      position: 'absolute',
      lineHeight: '3px',
      fontSize: '12px',
      marginTop: '-2%',
    },
    industryWarning: {
      color: 'red',
      position: 'absolute',
      lineHeight: '3px',
      fontSize: '12px',
      marginTop: '21px !important',
    },
    warningName: {
      border: '1px solid red',
    },
    selectWidth: {
      width: '720px',
    },
  },
  { name: PREFIX },
);
