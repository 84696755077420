import { EnvKeys, IConfigStore } from '../../models/stores/config.models';

export class ConfigStore implements IConfigStore {

  public readonly localToken: string | undefined;
  public readonly baseURL: string;
  public readonly baseURLPostfix: string;
  public readonly baseS3URL: string;

  constructor() {
    this.localToken = this.getValue(EnvKeys.localToken);
    this.baseURL = this.getRequired(EnvKeys.baseURL);
    this.baseS3URL = this.getRequired(EnvKeys.baseS3URL);
    this.baseURLPostfix = this.getRequired(EnvKeys.baseURLPostfix);
  }

  private getValue(key: EnvKeys): string | undefined {
    const value = process.env[key];
    return value;
  }

  private getRequired(key: EnvKeys): string {
    const value = this.getValue(key);
    if (typeof value !== 'string' || value.length === 0) {
      throw new Error(`Cannot find "${key}" parameter from environment`);
    }
    return value;
  }

}
