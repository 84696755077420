import React, { MouseEvent, useContext, useState } from 'react';
import { observer } from 'mobx-react-lite';
import { appStoreContext } from '../../../../store/context/context.store';
import { useStyles } from './SelectFilter.style';

export const SelectFilter = observer(({
  optionsList,
  selectedOption,
  name,
}: { optionsList: string[], selectedOption: string, name: string }): JSX.Element => {
  const [showOptionList, setShowOptionList] = useState(false);
  const appStore = useContext(appStoreContext);
  const classes = useStyles();
  const handleListDisplay = (): void => {
    setShowOptionList(!showOptionList);
  };
  const handleOptionClick = (e: MouseEvent): void => {
    // @ts-ignore
    appStore.search.onChangeSelectCustom(name, e.target.getAttribute('data-name'));
    setShowOptionList(false);
  };

  return (
    <div className={classes.main}>
      <div
        className={classes.dropDownHeader}
        onClick={handleListDisplay}
      >
        <div className={classes.textWrapper}>
          <div className={classes.textWidth}>
            {selectedOption}
          </div>
        </div>
      </div>
      <div className={classes.dropDownListContainer}>
        {showOptionList && (
          <ul className={classes.dropDownList}>
            {optionsList.map((option, i) => (
              <li
                className={classes.listItem}
                data-name={option}
                key={i}
                onClick={handleOptionClick}
              >
                {option}
              </li>
            ))}
          </ul>
        )}
      </div>
    </div>
  );
});
