import { createUseStyles } from 'react-jss';

const PREFIX = 'Card';
export const useStyles = createUseStyles(
  {
    card: {
      display: 'flex',
      cursor: 'pointer',
      width: '100%',
    },
    cardBlock: {
      marginTop: '36px',
      display: 'flex',
      justifyContent: 'space-between',
      flexWrap: 'wrap',
    },
    cardImg: {
      width: '109px',
      height: '109px',
      borderRadius: '50%',
      margin: '24px 30px 0 32px',
    },
    statusBlock: {
      width: '100px',
      border: '1px solid #E4E7F2',
      borderRadius: '12px',
      marginTop: '24px',
      display: 'flex',
      justifyContent: 'center',
    },
    cardStatus: {
      padding: '6px 0',
      color: '#909EBB',
      fontWeight: '400',
      fontSize: '14px',
      lineHeight: '20px',
    },
    cardName: {
      color: '#3B4168',
      fontWeight: '600',
      fontSize: '24px',
      lineHeight: '28px',
      marginTop: '16px',
      width: '300px',
    },
    wrapper: {
      maxWidth: '90%',
    },
    name: {
      whiteSpace: 'nowrap',
      overflow: 'hidden',
      textOverflow: 'ellipsis',
      maxWidth: '85%',
    },
    cardUsers: {
      color: '#6F7C98',
      fontSize: '16px',
      fontWeight: '400',
      lineHeight: '20px',
      marginTop: '8px',
    },
    lockIcon: {
      height: '12px',
      margin: '10px 5px',
    },
    textBlock: {
      position: 'relative',
      width: '68.5%',
    },
    link: {
      textDecoration: 'none',
      width: '49%',
      height: '152px',
      borderRadius: '16px',
      border: '1px solid #E4E7F2',
      background: '#FBFDFF',
      marginBottom: '24px',
      transition: 'all 0.1s ease-out;',

      '&:hover': {
        background: '#F5FBFF',
        border: '1px solid #99C6EB',
      },
    },
    '@media (max-width: 1290px)': {
      cardName: {
        width: '150px',
      },
    },
    '@media (max-width: 1600px)': {
      cardName: {
        width: '200px',
      },
    },
  },
  { name: PREFIX },
);
