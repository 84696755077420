import { useContext } from 'react';
import { Link } from 'react-router-dom';
import { observer } from 'mobx-react';
import frame1 from '../../../assets/backgrounds/frame1.svg';
import frame2 from '../../../assets/backgrounds/frame2.svg';
import { appStoreContext } from '../../../store/context/context.store';
import { InputSearch } from '../inputSearch/InputSearch';
import { useStyles } from './body.styles';

export const Body = observer((): JSX.Element => {
  const appStore = useContext(appStoreContext);
  const classes = useStyles();

  return (
    <div className={classes.body}>
      <img className={classes.frame1} src={frame1} alt="" />
      <img className={classes.frame2} src={frame2} alt="" />
      <div className={classes.left}>
        <h1 className={classes.bodyTitle}>#Клуб</h1>
        <p className={classes.bodyText}>Площадка, объединяющая людей, для совместной работы и нацеленная на создание профессионального сообщества</p>
        <button className={classes.button}>
          <Link className={classes.bodyLink} to="profile"> Перейти в профиль</Link>
        </button>
      </div>
      <div className={classes.right}>
        <div className={classes.card}>
          <div><i className={classes.group}></i></div>
          <div className={classes.text}>
            <h2 className={classes.titleCard}>Группы</h2>
            <p className={classes.textCard}>Найдите группу, связанную с вашими интересами</p>
          </div>
          <div>
            <InputSearch nav="groups" name="groupSearch" value={appStore.search.searchData.groupSearch} />
          </div>
        </div>
        <div className={classes.card}>
          <div><i className={classes.profiles}></i></div>
          <div className={classes.text}>
            <h2 className={classes.titleCard}>Профили</h2>
            <p className={classes.textCard}>Найдите пользователя, с которым хотите начать общение</p>
          </div>
          <InputSearch nav="subscribe" name="inputSearch" value={appStore.search.searchData.inputSearch} />
        </div>
      </div>
    </div>
  );
});
