import React, { ChangeEvent, useContext, useEffect, useState } from 'react';
import classNames from 'classnames/bind';
import Dialog from 'rc-dialog';
import Input from 'rc-input';
import { BlueBtn } from '../../../components/blueBtn/BlueBtn';
import { DragAndDrop } from '../../../components/dragAndDrop/DragAndDrop';
import { InstrumentInput } from '../../../components/instrumentInput/InstrumentInput';
import { ModalRadioInput } from '../../../components/modalRadioInput/ModalRadioInput';
import { TransparentBtn } from '../../../components/transparentBtn/TransparentBtn';
import { appStoreContext } from '../../../store/context/context.store';
import { convertType } from '../../../utils/convert';
import { ConfirmModal } from '../confirmModal/ConfirmModal';
import { useStyles } from './groupSettings.styles';
import { IProps } from './types';
import 'rc-dialog/assets/index.css';

export const GroupSettings = ({ visible, onClose, onDelete, setSettingsVisible, groupDetails }: IProps): JSX.Element => {
  const classes = useStyles();
  const [imageName, setImageName] = useState('');
  const [image, setImage] = useState<string | Blob>();
  const [clicked, setClicked] = useState<boolean>(false);
  const [type, setType] = useState<string | undefined>('');
  const [name, setName] = useState<string | undefined>('');
  const [desc, setDesc] = useState<string | undefined>('');
  const [confirmVisible, setConfirmVisible] = useState(false);
  const [changeFileVisible, setChangeFileVisible] = useState(false);
  const searchString = new URLSearchParams(window.location.search);
  const appStore = useContext(appStoreContext);
  const groupController = appStore.user.isSuperAdmin ? 'admin' : 'groups';
  const groupId = searchString.get('groupId');
  const cx = classNames.bind(classes);

  useEffect(() => {
    if (visible) {
      setType(groupDetails?.type);
      setName(groupDetails?.name);
      setDesc(groupDetails?.desc);
      setImage(groupDetails?.avatar);
      setImageName('');
      setClicked(false);
    }
  }, [groupDetails, visible]);

  const warningName = cx({
    warningName: name === '' && clicked,
  });

  const handleChangeImage = (e: ChangeEvent<HTMLInputElement>):void => {
    e.preventDefault();
    if (e.target.files && e.target.files[0]) {
      setImageName(e.target.files[0].name);
      const FReader = new FileReader();
      FReader.onload = (event: ProgressEvent<FileReader>):void => {
        setImage(String(event?.target?.result));
      };
      FReader.readAsDataURL(e.target.files[0]);
      setChangeFileVisible(true);
    }
  };

  const appendData = (): void => {
    const data = {
      groupId,
      name,
      type: convertType(type),
      subject: String(groupDetails?.subject),
      desc,
      avatar: image,
    };
    appStore.group.updateGroup(data, groupController);
    setConfirmVisible(false);
  };

  const handleSubmit = (): void => {
    setClicked(true);
    if (name !== '' && desc !== '<p><br></p>' && desc !== '' && image !== '') {
      setConfirmVisible(true);
      setSettingsVisible && setSettingsVisible(false);
    }
  };

  return (
    <>
      <Dialog
        title="Управление группой"
        className={classes.appModal}
        animation="zoom"
        maskAnimation="fade"
        visible={visible}
        onClose={onClose}
      >
        <div>
          <div className={classes.littleText}>
            Кто может добавлять документы в группу
          </div>
          <div className={classes.radioItems}>
            <div>
              <ModalRadioInput
                name="preffered"
                value="private"
                label="Только администраторы"
                checked={true}
              />
            </div>
          </div>
        </div>
        <div>
          <div className={classes.littleText}>Название группы</div>
          <Input
            value={name}
            placeholder="Название группы"
            className={`${classes.inpNameGroup} ${warningName}`}
            onChange={(e): void => setName(e.target.value)}
          />
        </div>
        {(clicked && name === '') && <div className={classes.warning}>Вы не заполнили поле</div>}
        <div>
          <InstrumentInput description={desc} setDescription={setDesc} clicked={clicked} />
        </div>
        <div>
          <div className={classes.littleText}>Аватар группы</div>
          <DragAndDrop
            image={image}
            clicked={clicked}
            setImage={setImage}
            imageName={imageName}
            setImageName={setImageName}
            handleChangeImage={handleChangeImage}
          />
        </div>
        <div className={classes.line}></div>
        <div className={classes.btnBlock}>
          <div>
            <TransparentBtn className={classes.deleteGroup} onClick={onDelete}>
              Удалить группу
            </TransparentBtn>
          </div>
          <div>
            <TransparentBtn
              className={classes.blockTransparentBtn}
              onClick={onClose}
            >
              Отменить
            </TransparentBtn>
            <BlueBtn onClick={handleSubmit}>Сохранить изменения</BlueBtn>
          </div>
        </div>
      </Dialog>

      <ConfirmModal
        visible={confirmVisible}
        onClose={(): void => setConfirmVisible(false)}
        appendData={appendData}
        groupName={groupDetails?.name}
      />
      <Dialog
        title="Вы изменили файл"
        className={`${classes.appModal} ${classes.fileModal}`}
        animation="zoom"
        maskAnimation="fade"
        visible={changeFileVisible}
        onClose={(): void => setChangeFileVisible(false)}
      />
    </>
  );
};
