import { ChangeEvent } from 'react';
import { makeAutoObservable, observable } from 'mobx';
// eslint-disable-next-line import/no-cycle
import { IPagination } from '../../models/stores/pagination.store';
import { IRootStore } from '../../models/stores/root.store';

export class PaginationStore implements IPagination {

  public readonly rootStore: IRootStore;
  @observable pagination = {
    pageSize: 10,
    total: 100,
    current: 1,
  };

  constructor(rootStore: IRootStore) {
    this.rootStore = rootStore;

    makeAutoObservable(this, {
      rootStore: false,
    });
    this.onChangeSelectPagination = this.onChangeSelectPagination.bind(this);
    this.currentIncrement = this.currentIncrement.bind(this);
    this.onchangePagination = this.onchangePagination.bind(this);
  }

  currentIncrement(): void {
    this.pagination.current += 1;
  }

  onChangeSelectPagination(e: ChangeEvent<HTMLSelectElement>): void {
    this.pagination.pageSize = +e.target.value;
  }

  onchangePagination(current: number): void {
    this.pagination.current = current;
  }

}
