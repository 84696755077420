import { createUseStyles } from 'react-jss';

const PREFIX = 'GroupSettings';
export const useStyles = createUseStyles(
  {
    appModal: {
      width: '800px',
      height: '708px',
      fontFamily: 'Montserrat, sans-serif',

      '& .rc-dialog-title': {
        fontWeight: '600',
        fontSize: '24px',
        lineHeight: '28px',
        color: '#3B4168',
      },
      '& .rc-dialog-close': {
        width: '28px',
        height: '28px',
        color: '#909EBB',
        fontSize: '35px',
        fontWeight: '100',
        opacity: '1',
        fontStyle: 'normal',
        right: '41px',
        top: '27px',
      },
      '& .rc-dialog-content': {
        padding: '28px 40px 32px',
      },
      '& .rc-dialog-header': {
        padding: '0 0 24px 0',
      },
      '& .rc-dialog-body': {
        padding: '0',
        paddingTop: '32px',
      },
    },
    radioItems: {
      display: 'flex',
      marginTop: '16px',
      marginBottom: '24px',
    },
    btnBlock: {
      display: 'flex',
      justifyContent: 'space-between',
      marginTop: '16px',
    },
    blockTransparentBtn: {
      marginRight: '16px',
    },
    inputStyle: {
      display: 'none',
    },
    textBorder: {
      display: 'flex',
      alignItems: 'center',
      fontSize: '16px',
      lineHeight: '1.25',
      color: '#6F7C98',
    },
    littleText: {
      fontSize: '14px',
      fontWeight: '400',
      lineHeight: '20px',
      color: '#6F7C98',
      marginTop: '24px',
    },
    bigText: {
      fontSize: '16px',
      fontWeight: '400',
      lineHeight: '20px',
      color: '#3B4168',
    },
    inpNameGroup: {
      width: '97%',
      height: '40px',
      border: '1px solid #E4E7F2',
      borderRadius: '4px',
      paddingLeft: '16px',
      margin: '4px 0 24px 0',
      fontSize: '16px',
      fontWeight: '400',
      lineHeight: '20px',
      color: '#3B4168',
    },
    modalTextArea: {
      width: '716px',
      maxWidth: '716px',
      minWidth: '716px',
      height: '112px',
      border: '1px solid #E4E7F2',
      borderRadius: '4px 4px 0px 0px',
      fontSize: '16px',
      fontWeight: '400',
      lineHeight: '20px',
      color: '#3B4168',
      fontFamily: 'Montserrat, sans-serif',
      margin: '4px 0 0 0',
    },
    instrumentBlock: {
      width: '100%',
      height: '34px',
      background: '#F5F5F7',
      borderRadius: '0px 0px 4px 4px',
      border: '1px solid #E4E7F2',
      marginTop: '-4px',
      marginBottom: '24px',
    },
    instrumentBlockImg: {
      margin: '7px 0 0 16.5px',
      cursor: 'pointer',
    },
    deleteGroup: {
      border: 'none',
      color: '#EB5757',
      paddingLeft: '0px',
    },
    line: {
      width: '100%',
      borderBottom: '1px solid #e9e9e9',
      marginTop: '32px',
    },
    addButton: {
      marginBottom: '-20px',
    },
    warning: {
      color: 'red',
      position: 'absolute',
      lineHeight: '3px',
      fontSize: '12px',
      marginTop: '-2%',
    },
    warningName: {
      border: '1px solid red',
    },
    fileModal: {
      padding: '28px 40px 0px 32px',
      width: '400px',

      '& .rc-dialog-header': {
        borderBottom: 'none',
        padding: '0px',
      },

      '& .rc-dialog-body': {
        padding: '0',
      },
    },
  },
  { name: PREFIX },
);
