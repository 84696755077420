import { getUnioneToken } from '@unione-pro/unione.commons.webpack';
import { AxiosResponse } from 'axios';
import { makeAutoObservable, runInAction } from 'mobx';
import { IFilter, IProfileRequestUpdate } from '../../api/profile.api.types';
import { IBodyGetRequest, IResponseGetUser } from '../../api/search.api.types';
import { IProfile, IProfileDate } from '../../models/stores/profile.store';
import { IRootStore } from '../../models/stores/root.store';
import { IUserStore } from '../../models/stores/user.store';
import { getErrorMessage } from '../../shared/error-message';

export class UserStore implements IUserStore {

  public readonly rootStore: IRootStore;

  public token?: string = undefined;
  public loading: boolean = true;
  public error?: string = undefined;
  public user: IProfile = {} as IProfile;
  public isSuperAdmin?: boolean = false;

  constructor(rootStore: IRootStore) {
    this.rootStore = rootStore;

    makeAutoObservable(this, { rootStore: false });
  }

  public async getToken(): Promise<void> {
    try {
      runInAction(() => {
        this.token = undefined;
        this.loading = true;
        this.error = undefined;
      });

      const localToken = this.rootStore.config.localToken;
      if (typeof localToken === 'string' && localToken.length > 0) {
        runInAction(() => {
          this.token = localToken;
        });
        runInAction(() => {
          this.loading = false;
        });
        return;
      }
      const unioneToken = await getUnioneToken();
      if (typeof unioneToken === 'string' && unioneToken.length > 0) {
        runInAction(() => {
          this.token = unioneToken;
        });
        runInAction(() => {
          this.loading = false;
        });
      }
    }
    catch (error) {
      runInAction(() => {
        this.error = getErrorMessage(error);
        this.loading = false;
      });
    }

    this.loading = false;
  }

  public async getUserInfo(): Promise<void> {
    try {
      const user = await this.rootStore.requests.json<IProfile>({
        baseURL: this.rootStore.config.baseURL + this.rootStore.config.baseURLPostfix,
        headers: {
          authorization: this.token || '',
        },
        method: 'POST',
        route: 'profiles/create-profile',
      });
      this.user = { ...user };
    }
    catch (error) {
      console.log('Backend error: ', error);
    }
  }

  public async getProfile(profileId: string): Promise<void> {
    try {
      await this.rootStore.requests.json<IProfileDate>({
        baseURL: this.rootStore.config.baseURL + this.rootStore.config.baseURLPostfix,
        headers: {
          authorization: this.token || '',
        },
        method: 'GET',
        route: 'profiles/profile-by-id',
        params: { profileId },
      }).then((res) => this.rootStore.profile.update(res)).then((res) => res);
    }
    catch (error) {
      runInAction(() => {
        this.error = getErrorMessage(error);
        this.loading = false;
      });
    }
  }

  public async updateProfile(body: IProfileRequestUpdate): Promise<void> {
    try {
      await this.rootStore.requests.json<IProfile>({
        baseURL: this.rootStore.config.baseURL + this.rootStore.config.baseURLPostfix,
        headers: {
          authorization: this.token || '',
        },
        method: 'PUT',
        route: 'profiles/update-profile',
        data: body,
      }).then((res) => {
        this.getProfile(res._id);
      });
    }
    catch (error) {
      runInAction(() => {
        this.error = getErrorMessage(error);
        this.loading = false;
      });
    }
  }

  public async filterProfile(filter: IFilter, profileController: string): Promise<void> {
    try {
      const data = await this.rootStore.requests.json<{ total: number, profiles: IProfile[] }>({
        baseURL: this.rootStore.config.baseURL + this.rootStore.config.baseURLPostfix,
        headers: {
          authorization: this.token || '',
        },
        method: 'POST',
        route: `${profileController}/find-profiles-with-filters`,
        data: filter,
      });
      this.rootStore.subscriptions.updateTable({ total: data.total,
        subs: data.profiles.map((item) => ({
          name: item.full_name,
          _id: item._id,
          academic_degree: item.academic_degree,
          academic_title: item.academic_title,
          scien_for_degree: item.scien_for_degree,
          avatar: item.avatar,
        })) });
    }
    catch (error) {
      console.log(error);
      runInAction(() => {
        this.error = getErrorMessage(error);
        this.loading = false;
      });
    }
  }


  public async getProfilesMeSubscribed(body: IBodyGetRequest): Promise<void | AxiosResponse<IResponseGetUser>> {
    try {
      await this.rootStore.requests.json<IResponseGetUser>({
        baseURL: this.rootStore.config.baseURL + this.rootStore.config.baseURLPostfix,
        headers: {
          authorization: this.token || '',
        },
        method: 'POST',
        route: 'search/get-profiles-me-subscribed',
        data: body,
      }).then((res) => {
        this.rootStore.subscriptions.updateTable({
          total: res.total,
          subs: res.subs.map((item) => ({
            name: item.name,
            _id: item.id,
            academic_degree: item.degree,
            academic_title: item.title,
            scien_for_degree: item.scien_for_degree,
            avatar: item.avatar,
          })),
        });
      });
    }
    catch (error) {
      runInAction(() => {
        this.error = getErrorMessage(error);
        this.loading = false;
      });
    }
  }

  public async getMyProfileSubscribers(body: IBodyGetRequest): Promise<void | AxiosResponse<IResponseGetUser>> {
    try {
      await this.rootStore.requests.json<IResponseGetUser>({
        baseURL: this.rootStore.config.baseURL + this.rootStore.config.baseURLPostfix,
        headers: {
          authorization: this.token || '',
        },
        method: 'POST',
        route: 'search/get-my-profile-subscribers',
        data: body,
      }).then((res) => {
        this.rootStore.subscriptions.updateTable({
          total: res.total,
          subs: res.subs.map((item) => ({
            name: item.name,
            _id: item._id,
            academic_degree: item.degree,
            academic_title: item.title,
            scien_for_degree: item.scien_for_degree,
            avatar: item.avatar,
          })),
        });
      });
    }
    catch (error) {
      runInAction(() => {
        this.error = getErrorMessage(error);
        this.loading = false;
      });
    }
  }


  public async addProfile(id: string): Promise<void | AxiosResponse<unknown>> {
    try {
      await this.rootStore.requests.json<IResponseGetUser>({
        baseURL: this.rootStore.config.baseURL + this.rootStore.config.baseURLPostfix,
        headers: {
          authorization: this.token || '',
        },
        method: 'POST',
        route: 'subscriptions/subscribe-to-profile',
        params: { id },
      });
    }
    catch (error) {
      runInAction(() => {
        this.error = getErrorMessage(error);
        this.loading = false;
      });
    }
  }

  public async deleteSubscriber(id: string): Promise<void | AxiosResponse<unknown>> {
    try {
      await this.rootStore.requests.json<IResponseGetUser>({
        baseURL: this.rootStore.config.baseURL + this.rootStore.config.baseURLPostfix,
        headers: {
          authorization: this.token || '',
        },
        method: 'DELETE',
        route: 'subscriptions/delete-subscriber',
        params: { subscriberId: id },
      });
    }
    catch (error) {
      runInAction(() => {
        this.error = getErrorMessage(error);
        this.loading = false;
      });
    }
  }

  public async deleteSubscription(id: string): Promise<void | AxiosResponse<unknown>> {
    try {
      await this.rootStore.requests.json<IResponseGetUser>({
        baseURL: this.rootStore.config.baseURL + this.rootStore.config.baseURLPostfix,
        headers: {
          authorization: this.token || '',
        },
        method: 'DELETE',
        route: 'subscriptions/delete-subscription',
        params: { id },
      });
    }
    catch (error) {
      runInAction(() => {
        this.error = getErrorMessage(error);
        this.loading = false;
      });
    }
  }

  public async checkIsSuperAdmin(): Promise<void> {
    try {
      await this.rootStore.requests.json<boolean>({
        baseURL: this.rootStore.config.baseURL + this.rootStore.config.baseURLPostfix,
        headers: {
          authorization: this.token || '',
        },
        method: 'GET',
        route: 'profiles/is-super-admin',
      }).then((res) => {
        this.isSuperAdmin = res;
      });
    }
    catch (error) {
      runInAction(() => {
        this.error = getErrorMessage(error);
        this.loading = false;
      });
    }
  }

}
