import React, { useContext } from 'react';
import { useNavigate } from 'react-router';
import Dialog from 'rc-dialog';
import deleteIcon from '../../../assets/icons/deleteIcon.svg';
import { BlueBtn } from '../../../components/blueBtn/BlueBtn';
import { TransparentBtn } from '../../../components/transparentBtn/TransparentBtn';
import { appStoreContext } from '../../../store/context/context.store';
import { useStyles } from './groupDelete.styles';
import { IProps } from './types';
import 'rc-dialog/assets/index.css';

export const GroupDeleteModal = ({ visible, onClose }: IProps): JSX.Element => {
  const searchString = new URLSearchParams(window.location.search);
  const groupId = searchString.get('groupId');
  const appStore = useContext(appStoreContext);
  const groupController = appStore.user.isSuperAdmin ? 'admin' : 'groups';
  const navigate = useNavigate();
  const classes = useStyles();

  const deleteGroup = (): void => {
    appStore.group.deleteGroup(groupId, groupController);
    navigate('/groups');
  };

  return (
    <Dialog
      title={<img src={deleteIcon} />}
      className={classes.appModal}
      animation="zoom"
      maskAnimation="fade"
      visible={visible}
      onClose={onClose}
    >
      <div className={classes.userAddContent}>
        <div className={classes.userAddContentBlock}>Вы уверены?</div>
        <p className={classes.userAddContentText}>
          После того как вы удалите группу все её данные, документы и информация
          об учатниках будут удалены безвозвратно
        </p>
      </div>
      <div>
        <BlueBtn className={classes.deleteBtn} onClick={deleteGroup}>Удалить группу</BlueBtn>
      </div>
      <div>
        <TransparentBtn className={classes.closeBtn} onClick={onClose}>
          Отмена
        </TransparentBtn>
      </div>
    </Dialog>
  );
};
