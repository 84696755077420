export enum EnvKeys {
  baseURL = 'REACT_APP_BASE_URL',
  localToken = 'REACT_APP_LOCAL_TOKEN',
  baseURLPostfix = 'REACT_APP_BASE_URL_POSTFIX',
  baseS3URL = 'REACT_APP_S3_URL',
}

export interface IConfigStore {
  baseURL: string;
  localToken?: string | undefined;
  baseURLPostfix: string;
  baseS3URL: string;
}
