import { FC } from 'react';
import { Link, useLocation } from 'react-router-dom';

export const ErrorPage: FC = () => {
  const { pathname } = useLocation();

  return (
    <>
      <h1>Ошибка 404: Страница не найдена</h1>
      <p>
        Извините, страница <code>{pathname}</code> не может быть найдена
      </p>
      <Link to="/">
        <button>Вернуться Домой</button>
      </Link>
    </>
  );
};
