import { useEffect, useRef, useState } from 'react';
import classNames from 'classnames/bind';
import { useStyles } from './myCustomSelect.styles';
import { IOption, IProps } from './types';

export const MyCustomSelect = ({
  setSubject,
  label,
  option,
  className,
  clicked,
  selectWidth,
  list,
  selectedOption,
  setSelectedOption,
}: IProps): JSX.Element => {
  const classes = useStyles();
  const [isOpen, setIsOpen] = useState(false);
  const cx = classNames.bind(classes);
  const myRef = useRef<HTMLDivElement | null>(null);

  const handleClickOutside = (e: MouseEvent):void => {
    if (!myRef?.current?.contains(e.target as HTMLDivElement)) {
      setIsOpen(false);
    }
  };

  useEffect(() => {
    document.addEventListener('mousedown', handleClickOutside);
    return () => document.removeEventListener('mousedown', handleClickOutside);
  });

  const errorStyle = cx({
    error: clicked && selectedOption === '',
  });

  const toggling = ():void => setIsOpen(!isOpen);
  const onOptionClicked = (value:IOption):void => {
    setSubject && setSubject(value.num);
    setSelectedOption && setSelectedOption(value.sub);
    setIsOpen(false);
  };

  return (
    <div className={classes.main} ref={myRef}>
      <label htmlFor="">{label}</label>
      <div
        className={ className ? `${errorStyle} ${className}` : `${classes.dropDownHeader} ${errorStyle}`}
        onClick={toggling}>
        {list
          ? (
            <div className={classes.textWrapper}>
              <div className={classes.textWidth}>
                {selectedOption || 'Отрасль'}
              </div>
            </div>
          )
          : (
            <>
              {selectedOption || 'Отрасль'}
            </>
          )
        }
      </div>
      <div className={classes.dropDownListContainer}>
        {isOpen && (
          <ul className={`${classes.dropDownList} ${selectWidth}`}>
            {option?.map((item, i) => (
              <li className={classes.listItem} onClick={():void => onOptionClicked(item)} key={i}>
                <div>{item.sub}</div>
              </li>
            ))}
          </ul>
        )}
      </div>
    </div>
  );
};
