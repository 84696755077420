import { createUseStyles } from 'react-jss';

const PREFIX = 'BlueButton';
export const useStyles = createUseStyles(
  {
    button: {
      background: 'linear-gradient(94.25deg, #007BE0 -0.43%, #0058A0 54.69%)',
      fontWeight: '500',
      fontSize: '16px',
      lineHeight: '20px',
      color: '#FFFFFF',
      borderRadius: '4px',
      cursor: 'pointer',
      padding: '12px 24px',
      border: 'none',
    },
  },
  { name: PREFIX },
);
