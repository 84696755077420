import { createUseStyles } from 'react-jss';
import arrow from '../../../assets/icons/arrow.svg';
import searchIcon from '../../../assets/icons/serchMain.svg';
import { theme } from '../../../components/theme';

const PREFIX = 'InputSearch';
export const useStyles = createUseStyles({
  container: {
    position: 'relative',
    textAlign: 'right',
    '&:before': {
      position: 'absolute',
      content: '""',
      width: '20px',
      height: '20px',
      backgroundImage: `url(${searchIcon})`,
      top: '38px',
      right: '10px',
    },
  },
  input: {
    boxSizing: 'border-box',
    width: '100%',
    borderRadius: '8px',
    background: 'rgba(255, 255, 255, 0.12)',
    height: '48px',
    fontSize: '16px',
    outline: 'none',
    border: 'none',
    margin: '24px 0',
    color: '#fff',
    fontFamily: theme.ibm,
    fontWeight: 400,
    lineHeight: '20px',
    letterSpacing: '0px',
    textAlign: 'left',
    '&:focus': {
      border: '1px solid #fff',
      background: 'rgba(255,255,255,0.12)',
    },
  },
  button: {
    width: '56px',
    height: '56px',
    borderRadius: '20px',
    border: '1px solid #FFFFFF',
    '&:hover': {
      opacity: 0.62,
      cursor: 'pointer',
    },
    '&:active': {
      opacity: 0.32,
    },
  },
  icon: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    position: 'relative',
    '&:before': {
      position: 'absolute',
      content: '""',
      background: `url(${arrow})`,
      width: '24px',
      height: '24px',
    },
  },
}, { name: PREFIX });
