import { createUseStyles } from 'react-jss';
import { theme } from '../../../components/theme';

const PREFIX = 'Tabs';
export const useStyles = createUseStyles({
  tabs: {
    border: 'none',
    margin: '26px 0 48px 0',
    '& .rc-tabs-ink-bar': {
      background: theme.colorPrimary,
      height: '2px',
    },
    '& .rc-tabs-tab': {
      color: theme.textColorH,
    },
    '& .rc-tabs-tab-active': {
      color: theme.colorPrimary,
    },
    '& .rc-tabs-tab-btn': {
      margin: '10px 20px',
      fontFamily: theme.mont,
      fontSize: '18px',
      fontWeight: '500',
      lineHeight: '24px',
      letterSpacing: '0px',
      textAlign: 'center',
    },
    '& .rc-tabs-nav-wrap': {
      position: 'relative',
      '&:before': {
        position: 'absolute',
        content: "''",
        width: '100%',
        height: '2px',
        background: '#E4E7F2',
        bottom: 0,
      },
    },
  },
}, { name: PREFIX });
