import { createUseStyles } from 'react-jss';

const PREFIX = 'Group';
export const useStyles = createUseStyles(
  {
    mainBlock: {
      color: '#3B4168',
      lineHeight: '20px',
      borderRadius: '16px',
      fontFamily: 'Montserrat, sans-serif',
      background: '#FFFFFF',
    },
    mainHeader: {
      display: 'flex',
    },
    textGroup2: {
      color: '#909EBB',
      fontSize: '16px',
      fontWeight: '500',
      marginTop: '8px',
      cursor: 'pointer',
    },
    darkText: {
      color: '#3B4168',
    },
    line: {
      height: '15px',
      width: '1.5px',
      background: '#909EBB',
      margin: '10px 16px',
    },
    cardImg: {
      width: '109px',
      height: '109px',
      borderRadius: '50%',
      margin: '38px 30px 0 32px',
    },
    cardName: {
      color: '#FFFFFF',
      fontWeight: '600',
      fontSize: '24px',
      lineHeight: '28px',
      marginTop: '40px',
    },
    btnCreate: {
      marginTop: '24px',
      fontWeight: '500',
      fontSize: '16px',
      lineHeight: '20px',
      backgroundColor: '#73D071',
      color: '#FFFFFF',
      height: '44px',
      width: '214px',
      border: '1px solid #FFFFFF',
      borderRadius: '4px',
      background: 'white',
      cursor: 'pointer',
    },
    groupRequest: {
      marginTop: '24px',
      width: '100%',
      height: '184px',
      backgroundColor: '#73D071',
      borderRadius: '16px',
      display: 'flex',
      justifyContent: 'space-between',
    },
    statusBlock: {
      height: '32px',
      width: '115px',
      background: 'rgba(255, 255, 255, 0.16)',
      borderRadius: '12px',
      marginTop: '42px',
      display: 'flex',
      justifyContent: 'center',
      marginRight: '16px',
    },
    lockIcon: {
      height: '12px',
      margin: '10px 5px',
    },
    cardStatus: {
      padding: '6px 0',
      color: '#FFFFFF',
      fontWeight: '400',
      fontSize: '14px',
      lineHeight: '20px',
    },
    circleBlock: {
      position: 'relative',
    },
    firstCircle: {
      position: 'absolute',
      top: '0px',
      right: '0px',
    },
    secondCircle: {
      position: 'absolute',
      top: '0px',
      left: '-10px',
    },
    thirdCircle: {
      position: 'absolute',
      top: '75px',
      right: '-50px',
    },
    fourthCircle: {
      position: 'absolute',
      top: '24px',
      left: '41px',
    },
    displayFlex: {
      display: 'flex',
    },
    displayFlex2: {
      display: 'flex',
      justifyContent: 'space-between',
      width: '400px',
    },
    displayFlex3: {
      display: 'flex',
      marginRight: '40px',
    },
    contentBlock: {
      display: 'flex',
      marginTop: '32px',
    },
    childContent1: {
      width: '67%',
    },
    childContent2: {
      width: '33%',
      height: '388px',
      display: 'flex',
      justifyContent: 'flex-end',
    },
    textBlock: {
      marginTop: '32px',
    },
    blockText1: {
      fontWeight: '500',
      fontSize: '16px',
      lineHeight: '20px',
      color: '#3B4168',
    },
    blockText2: {
      marginTop: '24px',
      fontWeight: '400',
      fontSize: '16px',
      lineHeight: '20px',
      color: '#6F7C98',
    },
    grayLine: {
      width: '100%',
      height: '1px',
      background: '#E4E7F2',
      margin: '32px 0',
    },
    docBlock: {
      display: 'flex',
      justifyContent: 'space-between',
      marginBottom: '24px',
    },
    document: {
      width: '100%',
      padding: '13.67px 0',
      background: '#F5FBFF',
      borderRadius: '8px',
      color: '#909EBB',
      fontWeight: '400',
      fontSize: '16px',
      lineHeight: '20px',
      display: 'flex',
      justifyContent: 'space-between',
      marginTop: '12px',
    },
    documentChild: {
      display: 'flex',
      width: '300px',
      marginLeft: '22.5px',
    },
    documentChildImg: {
      width: '20px',
      height: '20px',
      marginRight: '18.5px',
    },
    documentChild2: {
      marginRight: '13.67px',
    },
    documentChild2Img: {
      marginLeft: '22.5px',
    },
    usersList: {
      width: '80%',
      padding: '24px 0 32px 24px',
      border: '1px solid #E4E7F2',
      borderRadius: '16px',
      cursor: 'pointer',
    },
    userCount: {
      marginRight: '24px',
      marginTop: '-5px',
      width: '37px',
      height: '28px',
      borderRadius: '4px',
      background: '#F5FBFF',
      fontWeight: '400',
      fontSize: '14px',
      lineHeight: '20px',
      color: '#909EBB',
      display: 'flex',
      justifyContent: 'center',
      alignItems: 'center',
    },
    userListHeader: {
      display: 'flex',
      justifyContent: 'space-between',
    },
    userListBody: {
      height: '290px',
      overflowY: 'scroll',
    },
    userBlock: {
      display: 'flex',
      marginTop: '20px',
    },
    userBlockImg: {
      borderRadius: '8px',
    },
    userBlockText: {
      fontWeight: '400',
      fontSize: '14px',
      lineHeight: '20px',
      color: '#3B4168',
      margin: '8px 0 0 16px',
    },
    link: {
      textDecoration: 'none',
    },
    uploadBtn: {
      border: 'none',
      padding: '0px',
    },
  },
  { name: PREFIX },
);
