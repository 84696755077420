import { IProps } from './type';

export const sortText = ({ itemCount, countGroup }: IProps): string => {
  if (itemCount === 1 || countGroup === 1) {
    return `${itemCount || countGroup} участник`;
  }
  if (itemCount && itemCount > 1 && itemCount && itemCount < 5) {
    return `${itemCount} участника`;
  }
  if (countGroup && countGroup > 1 && countGroup && countGroup < 5) {
    return `${countGroup} участника`;
  }
  if (itemCount && itemCount > 4 || countGroup && countGroup > 4) {
    return `${itemCount || countGroup} участников`;
  }
  return '0 участников';
};
