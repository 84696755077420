import { createUseStyles } from 'react-jss';

const PREFIX = 'DocumentAddModal';
export const useStyles = createUseStyles(
  {
    appModal: {
      width: '600px',
      height: '518px',
      fontFamily: 'Montserrat, sans-serif',

      '& .rc-dialog-title': {
        fontWeight: '600',
        fontSize: '24px',
        lineHeight: '28px',
        color: '#3B4168',
      },
      '& .rc-dialog-close': {
        width: '28px',
        height: '28px',
        color: '#909EBB',
        fontSize: '35px',
        fontWeight: '100',
        opacity: '1',
        fontStyle: 'normal',
        right: '41px',
        top: '27px',
      },
      '& .rc-dialog-content': {
        padding: '28px 40px 32px',
      },
      '& .rc-dialog-header': {
        padding: '0 0 24px 0',
      },
      '& .rc-dialog-body': {
        padding: '0',
        paddingTop: '32px',
      },
    },
    btnBlock: {
      display: 'flex',
      justifyContent: 'flex-end',
      marginTop: '16px',
    },
    blockTransparentBtn: {
      marginRight: '16px',
    },
    borderNotImage: {
      height: '100%',
      display: 'flex',
      justifyContent: 'center',
      alignItems: 'center',
    },
    inputStyle: {
      display: 'none',
    },
    textBorder: {
      display: 'flex',
      alignItems: 'center',
      fontSize: '16px',
      lineHeight: '1.25',
      color: '#6F7C98',
    },
    textChooseFile: {
      color: '#0071CE',
      cursor: 'pointer',
    },
    paperClip: {
      marginRight: '40px',
      width: '32px',
      height: '32px',
    },
    avatarDasher: {
      border: '0.2rem dashed #E4E7F2',
      height: '80px',
      marginTop: '4px',
    },
    avatarLabel: {
      height: '100%',
      display: 'block',
    },
    littleText: {
      fontSize: '14px',
      fontWeight: '400',
      lineHeight: '20px',
      color: '#6F7C98',
    },
    bigText: {
      fontSize: '16px',
      fontWeight: '400',
      lineHeight: '20px',
      color: '#3B4168',
    },
    line: {
      width: '100%',
      borderBottom: '1px solid #e9e9e9',
      marginTop: '32px',
    },
    documentBlock: {
      marginBottom: '24px',
    },
    textHidden: {
      overflow: 'hidden',
    },
  },
  { name: PREFIX },
);
