import { createUseStyles } from 'react-jss';
import addIcon from '../../../assets/icons/addIcon.svg';
import deleteIcon from '../../../assets/icons/deleteLink.svg';
import { theme } from '../../../components/theme';

const PREFIX = 'InputLink';
export const useStyles = createUseStyles({
  container: {
    width: '100%',
  },
  group: {
    display: 'flex',
    flexDirection: 'column',
  },
  inputGroup: {
    display: 'flex',
    alignItems: 'center',
  },
  buttonAdd: {
    float: 'right',
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'center',
    color: theme.colorPrimary,
    margin: '16px 60px 0px 0px',
    fontFamily: 'IBM Plex Sans',
    fontSize: '16px',
    fontWeight: 500,
    lineHeight: '20px',
    letterSpacing: '0px',
    border: 'none',
    background: '#fff',
    gap: '8px',
  },
  delete: {
    content: `url(${deleteIcon})`,
    width: '24px',
    height: '24px',
    margin: '0 20px 0 20px',
    padding: 0,
  },
  addIcon: {
    content: `url(${addIcon})`,
    width: '20px',
    height: '20px',
    padding: 0,
  },
}, { name: PREFIX });
