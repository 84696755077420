import { createUseStyles } from 'react-jss';

const PREFIX = 'RadioInput';
export const useStyles = createUseStyles(
  {
    container: {
      display: 'flex',
      gap: '32px',
    },
    checkAllUsers: {
      cursor: 'pointer',
    },
    bigText: {
      fontSize: '16px',
      fontWeight: '400',
      lineHeight: '20px',
      color: '#3B4168',
      fontFamily: 'IBM Plex Sans',
    },
  },
  { name: PREFIX },
);
