import React, { useContext } from 'react';
import classNames from 'classnames';
import { observer } from 'mobx-react-lite';
import { appStoreContext } from '../../../store/context/context.store';
import { useStyles } from './switch.styles';
import { ISwitchTypes } from './switch.types';

export const Switch = observer(({ setActive, disabled, activeModalOpenProfile }: ISwitchTypes): JSX.Element => {
  const classes = useStyles();
  const appStore = useContext(appStoreContext);
  const { profileData } = appStore.profile;
  const switchButtonCN = classNames(
    classes.reactSwitchButton,
    { [classes.reactSwitchButtonActive]: profileData.profile.is_private },
  );
  const switchButtonLB = classNames(
    classes.reactSwitchLabel,
    { [classes.reactSwitchActive]: profileData.profile.is_private },
    disabled && classes.disabled,
  );

  const switchOn = (): void => {
    if (setActive) {
      if (!appStore.profile.profileData.profile.is_private) {
        setActive(true);
      }
      if (appStore.profile.profileData.profile.is_private) {
        if (activeModalOpenProfile) {
          activeModalOpenProfile(true);
        }
      }
    }
  };

  return (
    <>
      <input
        name="is_private"
        className={classes.reactSwitchCheckbox}
        id={'react-switch-new'}
        type="checkbox"
        onClick={switchOn}
        disabled={disabled}
      />
      <label
        className={switchButtonLB}
        htmlFor={'react-switch-new'}
      >
        <span
          className={switchButtonCN}
        />
      </label>
    </>
  );
});
