import { FC } from 'react';
import { BrowserRouter, Route, Routes } from 'react-router-dom';
import { Header } from '@unione-pro/unione.commons.webpack';
import { Layout } from '../components/layout/Layout';
import { Admin } from '../pages/adminLayout/admin/Admin';
import { AdminLayout } from '../pages/adminLayout/AdminLayout';
import { ErrorPage } from '../pages/ErrorPage';
import { Group } from '../pages/group/Group';
import { GroupList } from '../pages/groupList/GroupList';
import { Main } from '../pages/main/Main';
import { Profile } from '../pages/profile/Profile';
import { Subscriptions } from '../pages/subscriptions/Subscriptions';
import { User } from '../pages/user/User';

export const Routing: FC = () => (
  <BrowserRouter>
    <Header />
    <Routes>
      <Route path="/" element={<Main />} />
      <Route path="" element={<Layout />}>
        <Route path="subscribe" element={<Subscriptions />} />
        <Route path="subscribe/user/:id" element={<User />} />
        <Route path="profile" element={<Profile />} />
        <Route path="group" element={<Group />} />
        <Route path="groups" element={<GroupList />} />
        <Route path="*" element={<ErrorPage />} />
      </Route>
      <Route path="/admin" element={<AdminLayout />}>
        <Route path="edit-group" element={<Admin />} />
      </Route>
    </Routes>
  </BrowserRouter>
);
