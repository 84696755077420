import React, { useContext, useEffect, useState } from 'react';
import Dialog from 'rc-dialog';
import Input from 'rc-input';
import SearchIcon from '../../../assets/icons/searchIcon.svg';
import { appStoreContext } from '../../../store/context/context.store';
import { IProps } from './types';
import { User } from './user/User';
import { useStyles } from './usersList.styles';
import 'rc-dropdown/assets/index.css';
import 'rc-dialog/assets/index.css';
import 'rc-menu/assets/index.css';

export const UsersListModal = ({ visible, onClose, isAdmin, isOwner }: IProps): JSX.Element => {
  const [name, setName] = useState('');
  const searchString = new URLSearchParams(window.location.search);
  const groupId = searchString.get('groupId');
  const appStore = useContext(appStoreContext);
  const users = appStore.group.subscriber;
  const classes = useStyles();

  useEffect(() => {
    getGroupsSubscribers();
  }, []);

  const getGroupsSubscribers = (): void => {
    const bodyGetGroupsSubs = {
      page: 1,
      limit: 10,
      groupId,
      name,
    };
    appStore.group.getGroupsSubscribers(bodyGetGroupsSubs);
  };

  return (
    <Dialog
      title="Список участников"
      className={classes.appModal}
      animation="zoom"
      maskAnimation="fade"
      visible={visible}
      onClose={onClose}
    >
      <div className={classes.inputBlock}>
        <Input
          placeholder="Поиск по ФИО или организации"
          className={classes.searchInput}
          onPressEnter={(): void => getGroupsSubscribers()}
          onChange={(e): void => setName(e.target.value)}
        />
        <img
          src={SearchIcon}
          alt=""
          className={classes.searchIcon}
          onClick={getGroupsSubscribers}
        />
      </div>
      <div className={`${classes.appUsers} ${classes.paddingBody}`}>
        <div className={classes.appAvatar}>Аватар</div>
        <div className={classes.appFio}>ФИО</div>
        <div className={classes.appRole}>Роль</div>
      </div>
      {users ? users.map((item) => (
        <User item={item} isOwner={isOwner} isAdmin={isAdmin} key={item.sub_id} />
      ))
        : <div>Нет участников</div>
      }
    </Dialog>
  );
};
