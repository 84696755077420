import { FC, memo } from 'react';
import cn from 'classnames/bind';
import { useStyles } from './avatar.styles';
import { IAvatar } from './types';

export const Avatar: FC<IAvatar> = memo(({ name, isBig, className }) => {
  const classes = useStyles();
  const rndInt: number = Math.floor(Math.random() * 4) + 1;
  const firstLetter = name?.split(' ')[0]?.charAt(0);
  const secondLetter = name?.split(' ')[1]?.charAt(0);

  // @ts-ignore
  const cx = cn(className, classes.avatar, classes[`style${rndInt}`], {
    [classes.big]: isBig,
  });

  return (
    <div className={cx}>{name && firstLetter}{name && secondLetter}</div>
  );
});
