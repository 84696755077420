import { createUseStyles } from 'react-jss';

const PREFIX = 'GroupHeader';
export const useStyles = createUseStyles(
  {
    cardImg: {
      width: '109px',
      height: '109px',
      borderRadius: '50%',
      margin: '38px 30px 0 32px',
    },
    cardName: {
      color: '#FFFFFF',
      fontWeight: '600',
      fontSize: '24px',
      lineHeight: '28px',
      marginTop: '40px',
      position: 'relative',
    },
    btnCreate: {
      marginTop: '44px',
      fontWeight: '500',
      fontSize: '16px',
      lineHeight: '20px',
      backgroundColor: '#73D071',
      color: '#FFFFFF',
      height: '44px',
      width: '257px',
      border: '1px solid #FFFFFF',
      borderRadius: '4px',
      background: 'white',
      cursor: 'pointer',
    },
    btnCreateGroup: {
      marginTop: '44px',
      fontWeight: '500',
      fontSize: '16px',
      lineHeight: '20px',
      backgroundColor: '#73D071',
      color: '#FFFFFF',
      height: '44px',
      width: '214px',
      border: '1px solid #FFFFFF',
      borderRadius: '4px',
      background: 'white',
      cursor: 'pointer',
    },
    groupRequest: {
      marginTop: '24px',
      width: '100%',
      height: '184px',
      backgroundColor: '#73D071',
      borderRadius: '16px',
      display: 'flex',
      justifyContent: 'space-between',
    },
    statusBlock: {
      height: '32px',
      width: '115px',
      background: 'rgba(255, 255, 255, 0.16)',
      borderRadius: '12px',
      marginTop: '42px',
      display: 'flex',
      justifyContent: 'center',
      marginRight: '16px',
    },
    lockIcon: {
      height: '12px',
      margin: '10px 5px',
    },
    cardStatus: {
      padding: '6px 0',
      color: '#FFFFFF',
      fontWeight: '400',
      fontSize: '14px',
      lineHeight: '20px',
    },
    circleBlock: {
      position: 'relative',
      opacity: '0.2',
    },
    firstCircle: {
      position: 'absolute',
      top: '0px',
      right: '0px',
    },
    secondCircle: {
      position: 'absolute',
      top: '0px',
      left: '-10px',
    },
    thirdCircle: {
      position: 'absolute',
      top: '75px',
      right: '-50px',
    },
    fourthCircle: {
      position: 'absolute',
      top: '24px',
      left: '41px',
    },
    displayFlex: {
      display: 'flex',
      width: '50%',
    },
    displayFlex2: {
      display: 'flex',
      justifyContent: 'space-between',
      width: '400px',
    },
    displayFlex3: {
      display: 'flex',
      marginRight: '40px',
    },
    settingsBlock: {
      width: '32px',
      height: '32px',
      background: '#FFFFFF',
      borderRadius: '12px',
      marginTop: '42px',
      display: 'flex',
      justifyContent: 'center',
      alignItems: 'center',
      cursor: 'pointer',
    },
    check: {
      color: '#fff',
      marginLeft: '10px',
      height: '13px',
      filter: 'brightness(0) saturate(100%) invert(100%) sepia(0%) saturate(0%) hue-rotate(190deg) brightness(110%) contrast(101%);',
    },
    wrapper: {
      width: '60%',
    },
    nameAdmin: {
      marginTop: '-20px',
      marginBottom: '10px',
    },
    adminBtns: {
      display: 'flex',
      flexDirection: 'column',
    },
    btnAdmin: {
      '&:first-child': {
        marginTop: '21px',
      },
      marginTop: '10px',
    },
    name: {
      maxWidth: '100%',
      whiteSpace: 'nowrap',
      overflow: 'hidden',
      textOverflow: 'ellipsis',
      position: 'absolute',
    },
    circleMainBlock: {},
    '@media (max-width: 1450px)': {
      circleMainBlock: {
        marginLeft: '50px',
      },
    },
    '@media (max-width: 1280px)': {
      circleMainBlock: {
        marginLeft: '100px',
      },
    },
    '@media (min-width: 1690px)': {
      adminBtns: {
        flexDirection: 'row',
        justifyContent: 'center',
        alignItems: 'center',
        gap: '10px',
      },
      wrapper: {
        width: '70%',
      },
      btnAdmin: {
        '&:first-child': {
          marginTop: '41px',
        },
        '&:last-child': {
          marginTop: '41px',
        },
      },
      nameAdmin: {
        marginTop: '0px',
        marginBottom: '20px',
      },
    },
  },
  { name: PREFIX },
);
