import React, { useEffect, useRef, useState } from 'react';
import classNames from 'classnames/bind';
import { observer } from 'mobx-react-lite';
import Pagination from 'rc-pagination';
import arrowSelect from '../../assets/icons/arrowSelect.svg';
import arrowNext from '../../assets/icons/next.svg';
import arrowPrev from '../../assets/icons/prev.svg';
import { useStyles } from './paginationCustom.styles';
import { IPagination } from './types';

export const PaginationCustom = observer(({ setPage, page, pages, setLimit, limit, totalGroups }: IPagination): JSX.Element => {
  const classes = useStyles();
  const cx = classNames.bind(classes);
  const [isOpen, setIsOpen] = useState(false);
  const [selectedOption, setSelectedOption] = useState<number>(10);
  const myRef = useRef<HTMLDivElement | null>(null);

  const prevDisabled = cx({
    prevDisabled: page === 1,
  });

  const nextDisabled = cx({
    prevDisabled: page === pages || totalGroups < limit,
  });


  const handlePrev = ():void => {
    setPage(((prev:number) => prev - 1));
  };

  const handleClickOutside = (e: MouseEvent):void => {
    if (!myRef?.current?.contains(e.target as HTMLDivElement)) {
      setIsOpen(false);
    }
  };

  useEffect(() => {
    document.addEventListener('mousedown', handleClickOutside);
    return () => document.removeEventListener('mousedown', handleClickOutside);
  }, [isOpen]);

  const toggling = ():void => setIsOpen(!isOpen);
  const onOptionClicked = (value:number):void => {
    setSelectedOption(value);
    setIsOpen(false);
    setPage(((prev:number) => 1));
    setLimit && setLimit(value);
  };

  const handleNext = ():void => {
    if (page !== pages) {
      setPage((prev:number) => prev + 1);
    }
  };

  return (
    <div className={classes.footer}>
      <div className={classes.footerTop}>
        <div>Всего групп: {totalGroups}</div>
        <div className={classes.pagination}>
          <span>На странице по :</span>
          <div className={classes.paginationSelect} ref={myRef}>
            <div
              className={classes.dropDownHeader}
              onClick={toggling}>
              <div>
                {selectedOption || 10}
              </div>
              <img src={arrowSelect} className={classes.icon} />
            </div>
            <div className={classes.dropDownListContainer}>
              {isOpen && (
                <ul className={classes.dropDownList}>
                  {[10, 20, 30]?.map((item, i) => (
                    <li className={classes.listItem} onClick={():void => onOptionClicked(item)} key={i}>
                      <div>{item}</div>
                    </li>
                  ))}
                </ul>
              )}
            </div>
          </div>
          <div className={classes.paginationCustom}>
            <Pagination
              showTotal={(total, range): string => `${range[0]} - ${range[1]} из ${total} групп`}
              total={totalGroups}
              pageSize={limit}
              current={page}
              prevIcon={
                <button
                  disabled={page === 1}
                  className={`${classes.rcPaginationPrev} ${prevDisabled}`}
                  onClick={handlePrev}>
                  <img src={arrowPrev} className={classes.icon} />
                </button>}
              nextIcon={
                <button
                  disabled={page === pages || totalGroups < limit}
                  className={`${classes.rcPaginationPrev} ${nextDisabled}`}
                  onClick={handleNext}>
                  <img src={arrowNext} className={classes.icon} />
                </button>}
            />
          </div>
        </div>
      </div>
    </div>
  );
});
