import user from '../../assets/icons/user.jpg';
import user2 from '../../assets/icons/user2.jpg';

export const documents = [
  {
    _id: '1',
    name: 'Длинное название файла.docx',
    link: 'not link',
  },
  {
    _id: '2',
    name: 'Длинное название файла.docx',
    link: 'not link',
  },
  {
    _id: '3',
    name: 'Длинное название файла.docx',
    link: 'not link',
  },
];

export const users = [
  {
    img: user,
    name: 'Гуджиджиани И.А.',
  },
  {
    img: user2,
    name: 'Прохоров В.И.',
  },
  {
    img: user,
    name: 'Гуджиджиани В.Р.',
  },
  {
    img: user2,
    name: 'Покрасин В.А.',
  },
  {
    img: user,
    name: 'Коновалов С.М.',
  },
  {
    img: user,
    name: 'Гуджиджиани И.А.',
  },
  {
    img: user2,
    name: 'Прохоров В.И.',
  },
  {
    img: user,
    name: 'Гуджиджиани В.Р.',
  },
  {
    img: user2,
    name: 'Покрасин В.А.',
  },
  {
    img: user,
    name: 'Коновалов С.М.',
  },
];
