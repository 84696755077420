import { createUseStyles } from 'react-jss';
import tableArrow from '../../../assets/icons/tableArrow.svg';
import { theme } from '../../../components/theme';

const PREFIX = 'TableCustom';
export const useStyles = createUseStyles({
  container: {
    width: '100%',
  },
  tableCapCell: {
    fontFamily: theme.ibm,
    fontSize: '16px',
    fontWeight: 400,
    lineHeight: '20px',
    letterSpacing: '0px',
    textAlign: 'left',
    color: '#6F7C98',
  },
  tableBodyCell: {
    color: '#3B4168',
    fontFamily: theme.ibm,
    fontSize: '16px',
    fontWeight: 400,
    lineHeight: '20px',
    letterSpacing: '0px',
    textAlign: 'left',
  },
  row: {
    display: 'grid',
    gridTemplateColumns: '0.8fr 3fr 2fr 1fr 1fr 0.3fr',
  },
  tableRow: {
    display: 'grid',
    gridTemplateColumns: '0.8fr 3fr 2fr 1fr 1fr 0.3fr',
    borderBottom: '1px solid #E4E7F2',
    justifyContent: 'center',
    alignItems: 'center',
    '&:hover': {
      cursor: 'pointer',
      background: '#F5FBFF',
      '& td:nth-child(6)': {
        height: '15px',
        backgroundPositionX: 'center',
        backgroundImage: `url(${tableArrow})`,
        backgroundRepeat: 'no-repeat',
        backgroundPosition: 'center',
      },
    },
  },
  six: {
    width: '30px',
  },
  table: {
    width: '100%',
    borderCollapse: 'collapse',
  },
  image: {
    width: '56px',
    height: '56px',
    borderRadius: '50%',
    margin: '10px 16px 10px 12px',
  },
}, { name: PREFIX });
