import { createUseStyles } from 'react-jss';
import arrowSelect from '../../assets/icons/drots.svg';
import { theme } from '../../components/theme';

const PREFIX = 'GroupList';
export const useStyles = createUseStyles(
  {
    tabs: {
      border: 'none',
      fontSize: '18px',
      fontWeight: '500',
      '& .rc-tabs-nav': {
        margin: '26px 0 48px 0',
      },
      '& .rc-tabs-ink-bar': {
        background: '#0071CE',
        height: '2px',
      },
      '& .rc-tabs-tab-active': {
        fontWeight: 'normal',
        color: '#0071CE',
      },
      '& .rc-tabs-tab-btn': {
        margin: '10px 20px',
        fontFamily: theme.mont,
        fontSize: '18px',
        fontWeight: '500',
        lineHeight: '24px',
        letterSpacing: '0px',
        textAlign: 'center',
      },
      '& .rc-tabs-nav-wrap': {
        position: 'relative',
        '&:before': {
          position: 'absolute',
          content: "''",
          width: '100%',
          height: '2px',
          background: '#E4E7F2',
          bottom: 0,
        },
      },
    },
    mainBlock: {
      color: '#3B4168',
      lineHeight: '20px',
      borderRadius: '16px',
      fontFamily: 'Montserrat, sans-serif',
      background: '#FFFFFF',
    },
    textGroup: {
      fontSize: '28px',
      lineHeight: '36px',
      fontWeight: '700',
    },
    mainHeader: {
      display: 'flex',
      justifyContent: 'space-between',
      alignItems: 'center',
    },
    textGroup2: {
      color: '#909EBB',
      fontSize: '16px',
      fontWeight: '400',
    },
    searchInput: {
      boxSizing: 'border-box',
      width: '100%',
      height: '48px',
      borderRadius: '8px',
      background: 'rgba(153, 198, 235, 0.07)',
      color: '#3B4168',
      border: '1px solid #99C6EB',
      paddingLeft: '24px',
      fontSize: '16px',
      '&::placeholder': {
        color: '#909EBB',
        fontFamily: theme.ibm,
        lineHeight: '20px',
        fontWeight: '400',
      },
    },
    inputBlock: {
      position: 'relative',
      height: '48px',
    },
    searchIcon: {
      position: 'absolute',
      right: '25px',
      top: '13px',
    },
    btnCreate: {
      fontWeight: '500',
      fontSize: '16px',
      lineHeight: '20px',
      color: '#0071CE',
      height: '42px',
      width: '172px',
      border: '1px solid #0071CE',
      borderRadius: '4px',
      background: 'white',
      cursor: 'pointer',
    },
    selectBlock: {
      display: 'flex',
      justifyContent: 'space-between',
      marginTop: '28px',
    },
    drotsBlock: {
      width: '20%',
      position: 'relative',
    },
    errorText: {
      marginTop: '30px',
      marginLeft: '10px',
    },
    inpSelect: {
      width: '240px',
      height: '36px',
      boxShadow: '0px 4px 15px rgba(0, 0, 0, 0.05)',
      borderRadius: '10px',
      border: 'none',
      backgroundImage: `url(${arrowSelect})`,
      backgroundRepeat: 'no-repeat',
      backgroundPositionX: '92%',
      backgroundPositionY: 'center',
      outline: 'none',
      appearance: 'none',
      overflow: 'hidden',
      background: 'white',
      color: '#6F7C98',
      fontWeight: '400',
      fontSize: '14px',
      lineHeight: '20px',
      paddingLeft: '1em',
      display: 'flex',
      alignItems: 'center',
    },
    selectWidth: {
      width: '400px',
    },
  },
  { name: PREFIX },
);
