import { createUseStyles } from 'react-jss';
import group from '../../../assets/icons/group.svg';
import profiles from '../../../assets/icons/profils.svg';
import { theme } from '../../../components/theme';

const PREFIX = 'Body';
export const useStyles = createUseStyles({
  '@global': {
    body: {
      background: '#F5F6F8',
    },
    '::-webkit-scrollbar': {
      display: 'none',
    },
  },
  body: {
    top: 0,
    display: 'flex',
    paddingTop: '100px',
    paddingLeft: '158px',
    paddingRight: '158px',
    zIndex: 1,
    position: 'relative',
  },
  '@media (max-width: 1400px)': {
    body: {
      display: 'block',
      zIndex: 1,
      position: 'relative',
      textAlign: 'center',
    },
    right: {
      marginTop: 32,
      justifyContent: 'center',
    },
    left: {
      paddingRight: 70,
    },
  },
  frame1: {
    position: 'absolute',
    width: '80px',
    height: '80px',
    top: '114px',
    left: '38px',
    zIndex: 0,
  },
  frame2: {
    position: 'absolute',
    width: '80px',
    height: '80px',
    top: '180px',
    left: '468px',
    zIndex: 0,
  },
  left: {
    paddingRight: 0,
    zIndex: 1,
  },
  bodyTitle: {
    margin: 0,
    height: '163px',
    left: '158px',
    top: '260px',
    fontFamily: theme.mont,
    fontStyle: 'normal',
    fontWeight: 700,
    fontSize: '8.5rem',
    lineHeight: '120%',
    color: '#3B4168',
  },
  bodyText: {
    height: '87px',
    left: '158px',
    top: '435px',
    fontFamily: theme.mont,
    fontStyle: 'normal',
    fontWeight: 500,
    fontSize: '1.125rem',
    lineHeight: '160%',
    color: '#3B4168',
  },
  button: {
    padding: '0px',
    width: '15.5rem',
    height: '44px',
    left: '158px',
    top: '570px',
    borderRadius: '4px',
    border: '1px solid #0071CE',
    background: '#F5F6F8',
  },
  bodyLink: {
    textDecoration: 'none',
    fontFamily: theme.ibm,
    fontWeight: 500,
    fontSize: '1rem',
    lineHeight: '20px',
    textAlign: 'center',
    color: '#0071CE',
  },
  right: {
    display: 'flex',
    gap: '3rem',
    width: '100%',
  },
  card: {
    boxSizing: 'border-box',
    borderRadius: '32px',
    padding: '48px',
    zIndex: 2,
    width: '23rem',
    '&:nth-child(1)': {
      backgroundColor: theme.backgroundMain,
      '& button': {
        backgroundColor: theme.backgroundMain,
      },
    },
    '&:nth-child(2)': {
      backgroundColor: theme.backgroundTwo,
      '& button': {
        backgroundColor: theme.backgroundTwo,
      },
    },
  },
  group: {
    content: `url(${group})`,
    width: '4.5rem',
    height: '72px',
    padding: 0,
  },
  text: {
    width: '100%',
    color: '#FFF',
  },
  titleCard: {
    fontFamily: theme.mont,
    fontSize: '3rem',
    fontWeight: 700,
    lineHeight: '58px',
    letterSpacing: '0px',
    textAlign: 'left',
  },
  textCard: {
    fontFamily: theme.ibm,
    fontSize: '1rem',
    fontWeight: 400,
    lineHeight: '20px',
    letterSpacing: 0,
    textAlign: 'left',
    paddingBottom: '24px',
    height: '40px',
  },
  card_footer: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'flex-end',
    alignItems: 'center',
    padding: 0,
  },
  profiles: {
    content: `url(${profiles})`,
    width: '4.5rem',
    height: '4.5rem',
    padding: 0,
  },
  '@media (max-width: 1500px)': {
    body: {
      paddingLeft: '100px',
      paddingRight: '100px',
    },
    card: {
      width: '21rem',
    },
  },
}, { name: PREFIX });
