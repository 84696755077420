import { createUseStyles } from 'react-jss';

const PREFIX = 'ApplicationsModal';
export const useStyles = createUseStyles(
  {
    appModal: {
      width: '800px',
      height: '482px',
      fontFamily: 'Montserrat, sans-serif',

      '& .rc-dialog-title': {
        fontWeight: '600',
        fontSize: '24px',
        lineHeight: '28px',
        color: '#3B4168',
      },
      '& .rc-dialog-close': {
        width: '28px',
        height: '28px',
        color: '#909EBB',
        fontSize: '35px',
        fontWeight: '100',
        opacity: '1',
        fontStyle: 'normal',
        right: '41px',
        top: '27px',
      },
      '& .rc-dialog-content': {
        padding: '28px 40px 32px',
      },
      '& .rc-dialog-header': {
        padding: '0 0 14px 0',
      },
      '& .rc-dialog-body': {
        padding: '0',
        paddingTop: '10px',
      },
    },
    appUsers: {
      display: 'flex',
      justifyContent: 'space-between',
      padding: '10px 0',
      borderBottom: '1px solid #E4E7F2',
    },
    appUsersBlock: {
      display: 'flex',
    },
    appUsersImg: {
      width: '40px',
      height: '40px',
      borderRadius: '8px',
      margin: '0 0 0 19px',
    },
    appModalName: {
      width: '350px',
      color: '#3B4168',
      fontWeight: '400',
      fontSize: '16px',
      lineHeight: '20px',
      margin: '13px 0 0 40px',
    },
    appModalBtn: {
      border: 'none',
    },
    appModalGrayBtn: {
      border: 'none',
      color: '#BDBDBD',
    },
    appModalTitle: {
      fontWeight: '400',
      fontSize: '16px',
      lineHeight: '20px',
      color: '#6F7C98',
    },
  },
  { name: PREFIX },
);
