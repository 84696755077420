import { FC } from 'react';
import Tabs, { TabPane } from 'rc-tabs';
import { TableCustom } from '../table/TableCustom';
import { useStyles } from './tabs.styles';
import { ITabsCustom } from './types';

export const TabsCustom: FC<ITabsCustom> = ({ mySubs, callback }): JSX.Element => {
  const classes = useStyles();

  return (
    <Tabs className={classes.tabs} onChange={callback} defaultActiveKey={mySubs ? '1' : '3'}>
      <TabPane tab="Мои подписки" key="1">
        <TableCustom mySub={mySubs} />
      </TabPane>
      <TabPane tab="Все пользователи" key="3">
        <TableCustom mySub={mySubs} />
      </TabPane>
      {/* <TabPane tab="Мои подписчики" key="2"> */}
      {/*  <TableCustom mySub={mySubs} /> */}
      {/* </TabPane> */}
    </Tabs>
  );
};
