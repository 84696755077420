export const subjects = [
  {
    num: 0,
    sub: 'Все',
  },
  {
    num: 1,
    sub: 'Энергетическая инфраструктура',
  },
  {
    num: 2,
    sub: 'Образование',
  },
  {
    num: 3,
    sub: 'Здравоохранение',
  },
  {
    num: 4,
    sub: 'Сельское хозяйство и агропромышленный комплекс',
  },
  {
    num: 5,
    sub: 'Транспортная инфраструктура',
  },
  {
    num: 6,
    sub: 'Строительство',
  },
  {
    num: 7,
    sub: 'Добывающая промышленность',
  },
  {
    num: 8,
    sub: 'Обрабатывающая промышленность',
  },
  {
    num: 9,
    sub: 'Городское хозяйство',
  },
  {
    num: 10,
    sub: 'Финансовые услуги',
  },
  {
    num: 11,
    sub: 'Информационно-коммуникационные технологии',
  },
  {
    num: 12,
    sub: 'Интеллектуальная собственность и трансфер технологий',
  },
];
