import { createUseStyles } from 'react-jss';

const PREFIX = 'ConfirmModal';
export const useStyles = createUseStyles(
  {
    confirmModal: {
      width: '440px',
      height: '364px',
      fontFamily: 'Montserrat, sans-serif',
      outline: 'top yellow',
      position: 'relative',

      '& .rc-dialog-title': {
        fontWeight: '600',
        fontSize: '24px',
        lineHeight: '28px',
        color: '#3B4168',
      },
      '& .rc-dialog-close': {
        width: '28px',
        height: '28px',
        color: '#909EBB',
        fontSize: '35px',
        fontWeight: '100',
        opacity: '1',
        fontStyle: 'normal',
        right: '41px',
        top: '27px',
      },
      '& .rc-dialog-content': {
        padding: '28px 40px 32px',

        '& img': {
          marginTop: '5px',
        },
      },
      '& .rc-dialog-header': {
        padding: '0 0 12px 0',
        borderBottom: 'none',
      },
      '& .rc-dialog-body': {
        padding: '0',
        paddingTop: '32px',
      },
    },
    confirmContent: {
      marginBottom: '40px',
    },
    confirmContentBlock: {
      fontWeight: '600',
      fontSize: '24px',
      lineHeight: '28px',
      color: '#3B4168',
    },
    confirmContentText: {
      fontWeight: '400',
      fontSize: '16px',
      lineHeight: '20px',
      color: '#6F7C98',
    },
    confirmBtn: {
      width: '100%',
    },
    cancelBtn: {
      marginTop: '10px',
      border: 'none',
      width: '100%',
      color: '#6F7C98',
    },
  },
  { name: PREFIX },
);
