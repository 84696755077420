import { makeAutoObservable, runInAction } from 'mobx';
import { IGetCountGroups, IGroupData, IGroupUpdateData } from '../../models/requests/createGroup.models';
import {
  IFetchResponse,
  IFetchResponseGroupsSubs,
} from '../../models/requests/findGroupWithFilter.models';
import { IFetchResponseGetGroupReqs, IFetchResponseGetGroupSubs, IGetGroupsRequests, IGetGroupsSubscribers } from '../../models/requests/getGroupsSubscribers';
import { IGetMyGroupsSubscriptions } from '../../models/requests/getMyGroupsSubscriptions.models';
import {
  IFindGroupWithFilter,
  IGroup,
  IGroupsCount,
  IGroupsSubscriptions,
  IGroupStore,
  IResponseGroupFindById,
  IResponseGroupsCount,
  ISubscriber,
} from '../../models/stores/group.store';
import { IRootStore } from '../../models/stores/root.store';
import { convertType } from '../../utils/convert';

export class GroupStore implements IGroupStore {

  public readonly rootStore: IRootStore;
  public group: IGroup[] | null = [];
  public groupsSubscriptions: IGroupsSubscriptions[] | null = [];
  public groupDetails: IGroup | null = { _id: '', name: '', type: '', subject: 0, avatar: '' };
  public subscriber: ISubscriber[] | null = [];
  public countsGroup: IGroupsCount[] | null = [];
  public requested: ISubscriber[] = [];
  public myGroupTotal: number = 0;
  public allGroupTotal: number = 0;
  public subsTotal: number = 0;
  public isOwner: boolean = false;
  public isAdmin: boolean = false;

  constructor(rootStore: IRootStore) {
    this.rootStore = rootStore;

    makeAutoObservable(this, {
      rootStore: false,
    });
  }

  public async createGroup(data: IGroupData): Promise<void> {
    try {
      await this.rootStore.requests.json<IGroupData>({
        baseURL: this.rootStore.config.baseURL + this.rootStore.config.baseURLPostfix,
        headers: {
          authorization: this.rootStore.user.token || '',
        },
        method: 'POST',
        data,
        route: 'groups/create-group',
      }).then(() => this.getMyGroupsSubscriptions({ limit: 50, page: 1 }));
    }
    catch (error) {
      console.log(error);
    }
  }

  public async deleteGroup(id: string | null, groupController: string): Promise<void> {
    try {
      await this.rootStore.requests.json<void>({
        baseURL: this.rootStore.config.baseURL + this.rootStore.config.baseURLPostfix,
        headers: {
          authorization: this.rootStore.user.token || '',
        },
        method: 'DELETE',
        route: `${groupController}/delete-group?groupId=${id}`,
      });
    }
    catch (error) {
      console.log(error);
    }
  }

  public async updateGroup(data: IGroupUpdateData, groupController: string): Promise<void> {
    try {
      await this.rootStore.requests.json<IGroupUpdateData>({
        baseURL: this.rootStore.config.baseURL + this.rootStore.config.baseURLPostfix,
        headers: {
          authorization: this.rootStore.user.token || '',
        },
        method: 'PUT',
        data,
        route: `${groupController}/update-group`,
      }).then(() => this.findGroupById(data.groupId));
    }
    catch (error) {
      console.log(error);
    }
  }

  public async getCountGroups(data: IGetCountGroups): Promise<void> {
    try {
      const response = await this.rootStore.requests.json<IResponseGroupsCount>({
        baseURL: this.rootStore.config.baseURL + this.rootStore.config.baseURLPostfix,
        headers: {
          authorization: this.rootStore.user.token || '',
        },
        method: 'POST',
        data,
        route: 'groups/get-count-groups',
      });
      runInAction(() => {
        if (response.counts.length > 0) {
          this.countsGroup = response.counts.map((item) => ({
            _id: item._id,
            count: item.count,
          }));
        }
        else {
          runInAction(() => {
            this.countsGroup = null;
          });
        }
      });
    }
    catch (error) {
      console.log(error);
    }
  }

  public async findGroupWithFilter(data: IFindGroupWithFilter, groupController: string): Promise<void> {
    try {
      const response = await this.rootStore.requests.json<IFetchResponse>({
        baseURL: this.rootStore.config.baseURL + this.rootStore.config.baseURLPostfix,
        headers: {
          authorization: this.rootStore.user.token || '',
        },
        method: 'POST',
        data,
        route: `${groupController}/find-groups-with-filters`,
      });
      runInAction(() => {
        if (response.groups.length > 0) {
          this.allGroupTotal = response.total;
          this.group = response.groups.map((item) => ({
            _id: item._id,
            name: item.name,
            type: convertType(item.type),
            subject: item.subject,
            desc: item.desc && item.desc,
            avatar: item.avatar && item.avatar,
            count: item.count,
          }));
        }
        else {
          runInAction(() => {
            this.group = null;
            this.allGroupTotal = 0;
          });
        }
      });
    }
    catch (error) {
      console.log(error);
    }
  }

  public async findGroupById(data: string | null): Promise<void> {
    try {
      const response = await this.rootStore.requests.json<IResponseGroupFindById>({
        baseURL: this.rootStore.config.baseURL + this.rootStore.config.baseURLPostfix,
        headers: {
          authorization: this.rootStore.user.token || '',
        },
        method: 'GET',
        route: `groups/find-group-by-id?groupId=${data}`,
      });
      runInAction(() => {
        if (response.group) {
          this.isOwner = response.isOwner;
          this.isAdmin = response.isAdmin;
          this.groupDetails = {
            _id: response.group._id,
            name: response.group.name,
            type: convertType(response.group.type),
            subject: response.group.subject,
            desc: response.group.desc && response.group.desc,
            avatar: response.group.avatar && response.group.avatar,
            count: response.group.count,
          };
        }
        else {
          runInAction(() => {
            this.groupDetails = null;
          });
        }
      });
    }
    catch (error) {
      console.log(error);
    }
  }

  public async getMyGroupsSubscriptions(data: IGetMyGroupsSubscriptions | null): Promise<void> {
    try {
      const response = await this.rootStore.requests.json<IFetchResponseGroupsSubs>({
        baseURL: this.rootStore.config.baseURL + this.rootStore.config.baseURLPostfix,
        headers: {
          authorization: this.rootStore.user.token || '',
        },
        method: 'POST',
        data,
        route: 'search/get-my-groups-subscriptions',
      });
      runInAction(() => {
        if (response.subs.length > 0) {
          this.myGroupTotal = response.total;
          this.groupsSubscriptions = response.subs.map((item) => ({
            subscriber: item.subscriber,
            _id: item.id,
            name: item.name,
            type: convertType(item.g_type),
            subject: item.subject,
            desc: item.desc && item.desc,
            avatar: item.avatar && item.avatar,
            count: item.count ? item.count : 0,
          }));
        }
        else {
          runInAction(() => {
            this.groupsSubscriptions = null;
            this.myGroupTotal = 0;
          });
        }
      });
    }
    catch (error) {
      console.log(error);
    }
  }

  public async getGroupsSubscribers(data: IGetGroupsSubscribers): Promise<void> {
    try {
      const response = await this.rootStore.requests.json<IFetchResponseGetGroupSubs>({
        baseURL: this.rootStore.config.baseURL + this.rootStore.config.baseURLPostfix,
        headers: {
          authorization: this.rootStore.user.token || '',
        },
        method: 'POST',
        data,
        route: 'search/get-groups-subscribers',
      });
      runInAction(() => {
        if (response.subs.length > 0) {
          this.subsTotal = response.total;
          this.subscriber = response.subs.map((item) => ({
            sub_id: item.subscriber?.sub_id,
            name_sub: item.subscriber?.name_sub,
            name_sub_low: item.subscriber?.name_sub,
            avatar_sub: item.subscriber?.avatar_sub,
            degree: item.subscriber?.degree,
            scien_for_degree: item.subscriber?.scien_for_degree,
            role: item.role,
            status: item.status,
          }));
        }
        else {
          runInAction(() => {
            this.subscriber = null;
          });
        }
      });
    }
    catch (error) {
      console.log(error);
    }
  }

  public async getGroupsRequests(data: IGetGroupsRequests): Promise<void> {
    try {
      const response = await this.rootStore.requests.json<IFetchResponseGetGroupReqs>({
        baseURL: this.rootStore.config.baseURL + this.rootStore.config.baseURLPostfix,
        headers: {
          authorization: this.rootStore.user.token || '',
        },
        method: 'POST',
        data,
        route: 'search/get-groups-subscribers-pending',
      });
      runInAction(() => {
        if (response.subs.length > 0) {
          this.requested = response.subs.map((item) => ({
            sub_id: item.subscriber?.sub_id,
            name_sub: item.subscriber?.name_sub,
            name_sub_low: item.subscriber?.name_sub,
            avatar_sub: item.subscriber?.avatar_sub,
            degree: item.subscriber?.degree,
            scien_for_degree: item.subscriber?.scien_for_degree,
            role: item.role,
            status: item.status,
          }));
        }
        else {
          runInAction(() => {
            this.requested = [];
          });
        }
      });
    }
    catch (error) {
      console.log(error);
    }
  }

}
