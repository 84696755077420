import { useContext } from 'react';
import { useNavigate } from 'react-router';
import { observer } from 'mobx-react';
import Form, { Field } from 'rc-field-form';
import { appStoreContext } from '../../../store/context/context.store';
import { useStyles } from './inputSearch.styles';

export interface IInputSearch {
  value: string
  name: string
  nav: string
}

export const InputSearch = observer((props: IInputSearch): JSX.Element => {
  const appStore = useContext(appStoreContext);
  const classes = useStyles();
  const navigate = useNavigate();
  const onInputFinish = (values: {
    inputSearch: string;
    groupSearch: string;
  }): void => {
    if (props.name === 'groupSearch') {
      if (values.groupSearch) {
        appStore.search.updateInputSearch(values.groupSearch, 'groupSearch');
      }
      navigate('/groups', { state: { fromMain: true } });
    }
    else if (props.name === 'inputSearch') {
      if (
        values.inputSearch) {
        appStore.search.updateInputSearch(values.inputSearch, 'inputSearch');
      }
      navigate('/subscribe', { state: { fromMain: true } });
    }
    console.log('here');
  };

  return (
    <Form className={classes.container} onFinish={onInputFinish}>
      <Field name={props.name}>
        <Input value={props.value} className={classes.input} />
      </Field>
      <button className={classes.button}><i className={classes.icon}></i></button>
    </Form>
  );
});

export const Input: React.FC<React.InputHTMLAttributes<HTMLInputElement>> = ({
  value = '',
  ...props
}) => <input value={value} {...props} />;
