import { createUseStyles } from 'react-jss';
import { theme } from '../theme';

const PREFIX = 'Avatar';
export const useStyles = createUseStyles(
  {
    avatar: {
      width: 56,
      height: 56,
      fontSize: 22,
      fontFamily: theme.mont,
      fontWeight: 600,
      borderRadius: 16,
      display: 'flex',
      justifyContent: 'center',
      alignItems: 'center',
      margin: '10px 16px 10px 12px',
    },
    big: {
      width: 152,
      height: 152,
      fontSize: 50,
    },
    style1: {
      background: '#EEF9EE',
      color: '#15B012',
    },
    style2: {
      background: '#F8F3ED',
      color: '#F7B055',
    },
    style3: {
      background: '#F6F4FF',
      color: '#7D6FE1',
    },
    style4: {
      background: '#EDF5FB',
      color: '#0071CE',
    },
  },
  { name: PREFIX },
);
