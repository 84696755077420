export const theme: ITheme = {
  textColorH: '#3B4168',
  textColorLabelAndP: '#6F7C98',
  textColorPlaceholder: '#909EBB',
  backgroundMain: '#73AAFF',
  backgroundTwo: '#73D071',
  colorPrimary: '#0071CE',
  backgroundGradientButton: 'linear-gradient(94.25deg, #007BE0 -0.43%, #0058A0 54.69%)',
  mont: 'Montserrat',
  ibm: 'IBM Plex Sans',
};

export interface ITheme {
  textColorH: string,
  textColorLabelAndP: string,
  textColorPlaceholder: string,
  backgroundMain: string,
  backgroundTwo: string,
  colorPrimary: string,
  backgroundGradientButton: string,
  mont: string,
  ibm: string,
}
