import React from 'react';
import Dialog from 'rc-dialog';
import alert from '../../../assets/icons/allert.svg';
import { BlueBtn } from '../../../components/blueBtn/BlueBtn';
import { TransparentBtn } from '../../../components/transparentBtn/TransparentBtn';
import 'rc-dialog/assets/index.css';
import { useStyles } from '../saveChanges/SaveChangesModal.styles';
import { IProps } from './types';

export const OpenProfileModal = ({ visible, onClose, save }: IProps): JSX.Element => {
  const classes = useStyles();
  return (
    <Dialog
      title={<img src={alert} />}
      className={classes.appModal}
      animation="zoom"
      maskAnimation="fade"
      visible={visible}
      onClose={onClose}
    >
      <div className={classes.userAddContent}>
        <div className={classes.userAddContentBlock}>Открыть профиль</div>
        <p className={classes.userAddContentText}>
          вас можно будет найти через поиск, другие пользователи смогут просматривать ваш профиль, вы уверены? </p>
      </div>
      <div>
        <BlueBtn className={classes.saveBtn} onClick={save}>Открыть профиль</BlueBtn>
      </div>
      <div>
        <TransparentBtn className={classes.closeBtn} onClick={onClose}>
          Отмена
        </TransparentBtn>
      </div>
    </Dialog>
  );
};
