import React, { ChangeEvent, useContext, useEffect, useState } from 'react';
import { useLocation } from 'react-router-dom';
import { observer } from 'mobx-react';
import Input from 'rc-input';
import Tabs, { TabPane } from 'rc-tabs';
import SearchIcon from '../../assets/icons/searchIcon.svg';
import { MyCustomSelect } from '../../components/MySelect/MyCustomSelect';
import { PaginationCustom } from '../../components/paginationCustom/PaginationCustom';
import { appStoreContext } from '../../store/context/context.store';
import { GroupCreate } from '../modal/groupCreate/GroupCreate';
import { subjects } from '../modal/groupCreate/mockValues';
import { Card } from './card/Card';
import { useStyles } from './groupList.styles';
import 'rc-tabs/assets/index.css';
import 'rc-input/assets/index.css';

const TabAllGroups = observer((): JSX.Element => {
  const appStore = useContext(appStoreContext);
  const [page, setPage] = useState<number>(1);
  const [pages, setPages] = useState<number>(1);
  const [limit, setLimit] = useState<number>(10);
  const [createVisible, setCreateVisible] = useState(false);
  const [selectedOption, setSelectedOption] = useState<string>('');
  const [searchValue, setSearchValue] = useState(appStore.search.searchData.groupSearch);
  const groupController = appStore.user.isSuperAdmin ? 'admin' : 'groups';
  const allGroupTotal = appStore.group.allGroupTotal;
  const group = appStore.group.group;
  const classes = useStyles();

  useEffect(() => {
    findGroup();
    setPages(Math.ceil(allGroupTotal / limit));
  }, [limit, page]);

  useEffect(() => {
    findGroup();
  }, []);

  const findGroup = (subject?: number): void => {
    const body = { filter: {}, limit, page };
    if (subject) {
      body.filter = {
        name_low: searchValue,
        subject: [subject],
      };
    }
    if (!subject) {
      body.filter = {
        name_low: searchValue,
      };
    }
    appStore.group.findGroupWithFilter(body, groupController);
  };

  return (
    <div>
      <div>
        <div className={classes.inputBlock}>
          <Input
            placeholder="Поиск по названию"
            className={classes.searchInput}
            onPressEnter={(): void => findGroup()}
            onChange={(e): void => setSearchValue(e.target.value)}
            value={searchValue}
          />
          <img src={SearchIcon} alt="image error" className={classes.searchIcon} />
        </div>
        <div className={classes.selectBlock}>
          <div className={classes.drotsBlock}>
            <MyCustomSelect
              list
              option={subjects}
              setSubject={findGroup}
              className={classes.inpSelect}
              selectedOption={selectedOption}
              selectWidth={classes.selectWidth}
              setSelectedOption={setSelectedOption}
            />
          </div>
          <button
            className={classes.btnCreate}
            onClick={(): void => setCreateVisible(true)}
          >
        + Создать группу
          </button>
        </div>
        {
          group?.length
            ? <Card groupList={group} />
            : <div className={classes.errorText}>Вы еще не подписаны на группы</div>
        }
        <PaginationCustom
          pages={pages}
          setPage={setPage}
          setLimit={setLimit}
          page={page}
          limit={limit}
          totalGroups={group?.length ? allGroupTotal : 0}
        />
      </div>
      <GroupCreate
        visible={createVisible}
        setCreateVisible={setCreateVisible}
      />
    </div>
  );
});

const TabMyGroups = observer((): JSX.Element => {
  const [page, setPage] = useState<number>(1);
  const [pages, setPages] = useState<number>(1);
  const [limit, setLimit] = useState<number>(10);
  const [searchValue, setSearchValue] = useState('');
  const [createVisible, setCreateVisible] = useState(false);
  const [selectedOption, setSelectedOption] = useState<string>('');
  const appStore = useContext(appStoreContext);
  const classes = useStyles();
  const groupsSubscriptions = appStore.group.groupsSubscriptions;
  const myGroupTotal = appStore.group.myGroupTotal;
  const countsGroup = appStore.group.countsGroup;

  useEffect(() => {
    if (groupsSubscriptions?.length) {
      const arr: string[] = [];
      const data = {
        groupIds: arr,
      };
      groupsSubscriptions.map((item) => {
        arr.push(item._id || '');
      });
      appStore.group.getCountGroups(data);
    }
  }, [groupsSubscriptions]);

  useEffect(() => {
    const data = {
      limit,
      page,
    };
    appStore.group.getMyGroupsSubscriptions(data);
    setPages(Math.ceil(myGroupTotal / limit));
  }, [limit, page]);

  const findGroup = (subject?: number): void => {
    let data = null;
    if (subject) {
      data = {
        limit, page, name: searchValue, subjects: [subject],
      };
    }
    else {
      data = {
        limit, page, name: searchValue,
      };
    }
    appStore.group.getMyGroupsSubscriptions(data);
  };

  const onChangeValue = (e: ChangeEvent<HTMLInputElement>): void => {
    const { name, value } = e.target;
    setSearchValue(value);
    appStore.search.updateInputSearch(value, name);
  };

  const returnCountGroup = (id: string): number => {
    let count = 0;
    countsGroup?.map((item) => {
      if (item._id === id) {
        count = item.count;
      }
      return 0;
    });
    return count;
  };

  return (
    <div>
      <div>
        <div className={classes.inputBlock}>
          <Input
            placeholder="Поиск по названию"
            className={classes.searchInput}
            onPressEnter={(): void => findGroup()}
            onChange={onChangeValue}
            name="groupSearch"
            value={appStore.search.searchData.groupSearch}
          />
          <img src={SearchIcon} alt="image error" className={classes.searchIcon} />
        </div>
        <div className={classes.selectBlock}>
          <div className={classes.drotsBlock}>
            <MyCustomSelect
              list
              option={subjects}
              setSubject={findGroup}
              className={classes.inpSelect}
              selectedOption={selectedOption}
              selectWidth={classes.selectWidth}
              setSelectedOption={setSelectedOption}
            />
          </div>
          <button
            className={classes.btnCreate}
            onClick={(): void => setCreateVisible(true)}
          >
        + Создать группу
          </button>
        </div>
        {
          groupsSubscriptions?.length
            ? <Card groupList={groupsSubscriptions} returnCountGroup={returnCountGroup} />
            : <div className={classes.errorText}>Вы еще не подписаны на группы</div>
        }
        <PaginationCustom
          pages={pages}
          setPage={setPage}
          setLimit={setLimit}
          page={page}
          limit={limit}
          totalGroups={myGroupTotal}
        />
      </div>
      <GroupCreate
        visible={createVisible}
        setCreateVisible={setCreateVisible}
      />
    </div>
  );
});

export const GroupList = observer((): JSX.Element => {
  const { state } = useLocation();
  // @ts-ignore
  const [activeTab, setActiveTab] = useState<string>(state?.fromMain ? '2' : '1');
  const appStore = useContext(appStoreContext);
  const myGroupTotal = appStore.group.myGroupTotal;
  const allGroupTotal = appStore.group.allGroupTotal;
  const classes = useStyles();

  useEffect(() => {
    appStore.user.checkIsSuperAdmin();
  }, []);

  const handleTab = (key:string):void => {
    setActiveTab(key);
  };

  return (
    <div>
      <div className={classes.mainBlock}>
        <div className={classes.mainHeader}>
          <div className={classes.textGroup}>Группы</div>
          <div className={classes.textGroup2}>{`Всего групп: ${activeTab === '1' ? myGroupTotal : allGroupTotal}`}</div>
        </div>
        <Tabs className={classes.tabs} defaultActiveKey={activeTab} onChange={handleTab}>
          <TabPane tab="Мои группы" key="1">
            <div>
              <TabMyGroups />
            </div>
          </TabPane>
          <TabPane tab="Все группы" key="2">
            <div>
              <TabAllGroups />
            </div>
          </TabPane>
        </Tabs>
      </div>
    </div>
  );
});
