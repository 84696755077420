import { createUseStyles } from 'react-jss';
import { theme } from '../theme';

const PREFIX = 'SelectSearchInput';
export const useStyles = createUseStyles({
  customSelectContainer: {
    display: 'inline-block',
    position: 'relative',
  },
  selectedText: {
    fontFamily: theme.ibm,
    backgroundColor: '#F5FBFF;',
    width: '13.5em',
    margin: '0 auto',
    fontWeight: '100',
    fontSize: '1.1rem',
    '&:after': {
      content: "''",
      position: 'absolute',
      right: '0',
      top: 'calc(50% - 3px)',
      border: '7px solid transparent',
      borderColor: '#A0A1AA transparent transparent transparent',
    },
  },

  selectedTextActive: {
    top: '8px',
    borderColor: 'transparent transparent #fff transparent',
  },
  selectOptions: {
    zIndex: 1,
    position: 'absolute',
    width: '100%',
    marginTop: '15px',
    padding: 0,
    textAlign: 'left',
  },
  containerOptions: {
    boxShadow: '0px 4px 15px rgba(0, 0, 0, 0.05)',
    borderRadius: '4px',
    background: '#fff',
    width: '100%',
    maxHeight: '165px',
    overflowY: 'scroll',
    boxSizing: 'border-box',
    '&::-webkit-scrollbar': {
      display: 'none !important',
    },
  },
  customSelectOption: {
    paddingLeft: '1em',
    fontFamily: theme.ibm,
    fontWeight: 400,
    fontSize: '14px',
    lineHeight: '20px',
    listStyleType: 'none',
    background: '#fff',
    cursor: 'pointer',
    height: '100%',
    display: 'flex',
    alignItems: 'center',
    padding: '8px 0',
    marginLeft: '-1em',
    color: '#3B4168',
    '&:hover': {
      background: '#F5FBFF',
    },
    '&:first-child': {
      borderRadius: '4px 4px 0 0',
    },
    '&:last-child': {
      borderRadius: '0 0 4px 4px',
    },
  },
}, { name: PREFIX });
