import { ChangeEvent, useContext } from 'react';
import classNames from 'classnames';
import { observer } from 'mobx-react-lite';
import Input from 'rc-input';
import { appStoreContext } from '../../store/context/context.store';
import { useStyles } from './inputCustom.styles';
import { IInputCustom } from './types';

export const InputCustom = observer((props: IInputCustom): JSX.Element => {
  const classes = useStyles();
  const input = classNames(`${classes.input} ${props.name === 'telegram' ? classes.inputTelegram : props.name === 'vk' ? classes.inputVk : ''}
   ${props.error && classes.error} ${props.name === 'interests' && classes.marg} ${props.name === 'portfolio' && classes.marg}`);
  const appStore = useContext(appStoreContext);

  const onChangeInput = (e: ChangeEvent<HTMLInputElement>): void | boolean => {
    appStore.profile.onChangeInput(e);
    if (props.error) {
      props.setError && props.setError(false);
      if (e.target.value) {
        const regex = /(?:https?):\/\/(\w+:?\w*)?(\S+)(:\d+)?(\/|\/([\w#!:.?+=&%!\-\/]))?/;
        if (!regex.test(e.target.value)) {
          props.setError && props.setError(true);
          return false;
        }
        return true;
      }
    }
    return true;
  };
  return (
    <div className={classes.container}>
      <label className={classes.label} htmlFor="">{props.label}</label>
      <div className={classes.iconContainer}>
        {
          // @ts-ignore
          <Input value={props.value} name={props.name} onChange={onChangeInput} className={input}
            placeholder={props.placeholder} />
        }
        {props.children}
      </div>
    </div>
  );
});
