import { makeAutoObservable, runInAction } from 'mobx';
import { IDeleteDataDocuments } from '../../models/requests/document.models';
import { IDocuments, IDocumentsStore } from '../../models/stores/documents.store';
import { IRootStore } from '../../models/stores/root.store';

export class DocumentsStore implements IDocumentsStore {

  public readonly rootStore: IRootStore;
  public documents: IDocuments[] | null = [];

  constructor(rootStore: IRootStore) {
    this.rootStore = rootStore;
    makeAutoObservable(this, {
      rootStore: false,
    });
  }

  public async uploadDocument(file: FormData, id: string, imageName: string, groupController: string): Promise<void> {
    try {
      await this.rootStore.requests.json<FormData>({
        baseURL: this.rootStore.config.baseURL + this.rootStore.config.baseURLPostfix,
        headers: {
          authorization: this.rootStore.user.token || '',
        },
        method: 'POST',
        data: file,
        route: `groups/upload-doc-to-group?groupId=${id}&fileName=${imageName}`,
      }).then(() => this.getDocuments(id, groupController));
    }
    catch (error) {
      console.log(error);
    }
  }

  public async deleteDocument(data: IDeleteDataDocuments, groupController: string): Promise<void> {
    try {
      await this.rootStore.requests.json<void>({
        baseURL: this.rootStore.config.baseURL + this.rootStore.config.baseURLPostfix,
        headers: {
          authorization: this.rootStore.user.token || '',
        },
        method: 'DELETE',
        data,
        route: `${groupController}/delete-doc`,
      });
    }
    catch (error) {
      console.log(error);
    }
  }

  public async getDocuments(groupId: string | null, groupController: string): Promise<void> {
    try {
      const response = await this.rootStore.requests.json<IDocuments[]>({
        baseURL: this.rootStore.config.baseURL + this.rootStore.config.baseURLPostfix,
        headers: {
          authorization: this.rootStore.user.token || '',
        },
        method: 'GET',
        route: `${groupController}/get-docs-from-group?groupId=${groupId}&page=1&limit=10`,
      });
      runInAction(() => {
        if (response.length > 0) {
          this.documents = response.map((item) => ({
            _id: item._id,
            name: item.name,
            link: item.link,
          }));
        }
        else {
          runInAction(() => {
            this.documents = null;
          });
        }
      });
    }
    catch (error) {
      runInAction(() => {
        this.documents = null;
      });
    }
  }

}
