import React, { useContext, useEffect, useState } from 'react';
import classNames from 'classnames';
import classnames from 'classnames/bind';
import { observer } from 'mobx-react-lite';
import refactoringProfile from '../../assets/icons/redactorProfile.svg';
import { Avatar } from '../../components/avatar/Avatar';
import { ButtonLarge } from '../../components/buttonLarge/ButtonLarge';
import { academic_degree, academic_title, scien_title } from '../../shared/list.constants';
import { appStoreContext } from '../../store/context/context.store';
import { useStyles } from './profile.style';
import { ProfileRefactoring } from './ProfileRefactoring/ProfileRefactoring';

export const Profile = observer((): JSX.Element => {
  const [activeRefactoring, setActiveRefactoring] = useState<boolean>(false);
  const classes = useStyles();
  const appStore = useContext(appStoreContext);
  const { profile } = appStore.profile.profileData;
  const select = classNames(classes.interest, classes.containerMinBlock);
  const cx = classnames.bind(classes);
  const profileInt = cx('containerMinBlock', {
    lineOne: profile.scien_for_degree === 0,
  });

  useEffect(() => {
    if (appStore.user.user._id) {
      appStore.user.getProfile(appStore.user.user._id);
    }
  }, [appStore.user.user._id]);

  return (
    <>
      {
        activeRefactoring && <ProfileRefactoring active={activeRefactoring} setActive={setActiveRefactoring} />
        || <div>

          <div className={classes.cap}>
            <div className={classes.avatar}>
              {
                appStore.user.user.avatar ? (
                  <img className={classes.img} src={process.env.REACT_APP_S3_URL + appStore.user.user.avatar} alt="" />
                ) : (
                  <Avatar name={appStore.user.user.full_name} isBig />
                )
              }
            </div>
            <div className={classes.data}>
              <div>
                <div className={classes.header}>
                  <div className={classes.id}>ID: {profile._id}</div>
                  <div className={classes.openProfile}>
                    {profile.is_private ? 'Закрытый профиль' : 'Открытый профиль'}
                  </div>
                </div>
                <div>
                  <h2 className={classes.nameUser}>{profile.full_name}</h2>
                </div>
              </div>
            </div>
          </div>
          <div className={classes.body}>
            <hr className={classes.lineShareSection} />
            <div className={classes.item}>
              <h5 className={classes.titleSection}>Сфера деятельности и интересов</h5>
              <div className={classes.top}>
                {profile.work?.trim() !== '' && <div className={classes.containerMinBlock}>
                  <label className={classes.label} htmlFor="">Наименование организации</label>
                  <p className={classes.textUser}>{profile.work}</p>
                </div>}
                {profile.academic_degree !== 0 && <div className={classes.containerMinBlock}>
                  <label className={classes.label} htmlFor="">Ученая степень</label>
                  <p className={classes.textUser}>{academic_degree[profile.academic_degree]}</p>
                </div>}
                {profile.academic_title !== 0 && <div className={classes.containerMinBlock}>
                  <label className={classes.label} htmlFor="">Ученое звание</label>
                  <p className={classes.textUser}>{academic_title[profile.academic_title]}</p>
                </div>}
              </div>
              {(profile.scien_for_degree !== 0 || profile.directions?.trim() !== '') && <div className={classes.bottom}>
                {profile.scien_for_degree !== 0 && <div className={classes.containerMinBlock}>
                  <label className={classes.label} htmlFor="">Отрасль</label>
                  <p className={classes.textUser}>{scien_title[profile.scien_for_degree]}</p>
                </div>}
                {profile.directions?.trim() !== '' && <div className={profileInt}>
                  <label className={classes.label} htmlFor="">Профильное направление, специфика экспертной позиции</label>
                  <p className={classes.textUser}>{profile.directions}</p>
                </div>}
              </div>}
            </div>
            <hr className={classes.lineShareSection} />
            <div className={classes.item}><h5 className={classes.titleSection}>Контактные данные</h5>
              <div className={classes.contacts}>
                <div className={classes.containerMinBlock}>
                  <label className={classes.label} htmlFor="">Email</label>
                  <p className={classes.textUser}>{profile.contacts.email}</p>
                </div>
                <div className={classes.containerMinBlock}>
                  <label className={classes.label} htmlFor="">Telegram</label>
                  <div className={classes.containerA}>
                    <a className={classes.userLink} target="_blank"
                      href={`https://t.me/${profile.contacts.telegram}`}
                      rel="noreferrer">@{profile.contacts.telegram}</a>
                  </div>
                </div>
                <div className={classes.containerMinBlock}>
                  <label className={classes.label} htmlFor="">VK</label>
                  <div className={classes.containerA}>
                    <a className={classes.userLink} target="_blank"
                      href={`https://vk.com/${profile.contacts.vk}`} rel="noreferrer">vk.com/{profile.contacts.vk}</a>
                  </div>
                </div>
                <div className={classes.containerMinBlock}>
                  <label className={classes.label} htmlFor="">Предпочитаю связываться по</label>
                  <p className={classes.textUser}>{profile.preffered}</p>
                </div>
              </div>
            </div>
            <hr className={classes.lineShareSection} />
            <div className={classes.item}>
              <div>
                <h5 className={classes.titleSection}>Портфолио</h5>
              </div>
              <div className={classes.portfolio}>
                <div className={classes.containerPortfolio}>
                  {profile.interests?.trim() !== '' && <div className={select}>
                    <label className={classes.label} htmlFor="">Научные интересы</label>
                    <div className={classes.containerA}>
                      <p className={classes.textUser}>{profile.interests}</p>
                    </div>
                  </div>}
                  {profile.portfolio?.trim() !== '' && <div className={select}>
                    <label className={classes.label} htmlFor="">Портфолио</label>
                    <div className={classes.containerA}>
                      <a className={classes.userLink} target="_blank" href={profile.portfolio}
                        rel="noreferrer">{profile.portfolio}</a>
                    </div>
                  </div>}
                  {profile.projects.length !== 0 && <div className={classes.containerMinBlock}>
                    <label className={classes.label} htmlFor="">Реализованные проекты</label>
                    {profile.projects && profile.projects.map((el, i) => <p key={i}
                      className={classes.textUser}>{el.value}</p>)}
                  </div>}
                </div>
              </div>
            </div>
            <hr className={classes.lineShareSection} />
            <div className={classes.item}>
              <div className={classes.achievements}>
                <h5 className={classes.titleSection}>Мои достижения</h5>
                <p className={classes.subTitle}>сертификаты, дипломы, участие в проектах</p>
              </div>
              <div className={classes.containerMinBlock}>
                {profile.achieve && profile.achieve.map((el, i) => <a className={classes.userLink} target="_blank" key={i}
                  href={el.value} rel="noreferrer">{el.value}</a>)}
              </div>
            </div>
            <hr className={classes.lineShareSection} />
            <div className={classes.item}>
              <h5 className={classes.titleSection}>Список моих публикаций</h5>
              <div className={classes.containerMinBlock}>
                {profile.pubs && profile.pubs.map((el, i) => <a className={classes.userLink} key={i}
                  target="_blank" href={el.value}
                  rel="noreferrer">{el.value}</a>)}
              </div>
            </div>
            <hr className={classes.lineShareSection} />
            <ButtonLarge title="Редактировать профиль" icon={refactoringProfile}
              callback={(): void => setActiveRefactoring(!activeRefactoring)} />
          </div>
        </div>
      }
    </>
  );
});
