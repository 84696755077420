import { createUseStyles } from 'react-jss';
import arrowSelect from '../../assets/icons/arrowSelect.svg';
import { theme } from '../theme';

const PREFIX = 'SelectCustom';
export const useStyles = createUseStyles({
  container: {
    display: 'flex',
    flexDirection: 'column',
    width: '100%',
    minWidth: '30px',
  },
  select: {
    outline: 'none',
    appearance: 'none',
    backgroundImage: `url(${arrowSelect})`,
    backgroundRepeat: 'no-repeat',
    backgroundPositionX: '97%',
    backgroundPositionY: 'center',
    boxSizing: 'border-box',
    padding: '8px 16px',
    width: '100%',
    height: '40px',
    background: '#FFFFFF',
    border: '1px solid #E4E7F2',
    borderRadius: '4px',
    fontFamily: 'IBM Plex Sans',
    fontSize: '16px',
    fontWeight: 400,
    lineHeight: '20px',
    letterSpacing: '0px',
    textAlign: 'left',
    color: theme.textColorPlaceholder,
    textOverflow: 'ellipsis',
  },
  label: {
    fontFamily: theme.ibm,
    fontSize: '14px',
    fontWeight: 400,
    lineHeight: '20px',
    letterSpacing: '0px',
    textAlign: 'left',
    color: theme.textColorLabelAndP,
    marginBottom: '4px',
  },
  labelNo: {
    display: 'none',
  },
}, { name: PREFIX });
