import { useContext } from 'react';
import { observer } from 'mobx-react-lite';
import { InputCustom } from '../../../components/inputCustom/InputCustom';
import { appStoreContext } from '../../../store/context/context.store';
import { useStyles } from './inputLink.styles';
import { IInputLink } from './types';

export const InputLink = observer((props: IInputLink): JSX.Element => {
  const classes = useStyles();
  const appStore = useContext(appStoreContext);
  const { profileData } = appStore.profile;

  return (
    <div className={classes.container}>
      <div className={classes.group}>
        <div>
          {profileData && profileData.profile[props.name].map((el, i) => <div key={i}>
            <div className={classes.inputGroup}>
              <InputCustom value={profileData.profile[props.name][i].value} name={`${props.name + i.toString()}`} label={props.title} placeholder="Введите ссылку"><i onClick={():void => {
                appStore.profile.deleteInputLink(props.name, el._id);
              }}
              className={classes.delete}></i></InputCustom>
            </div>
          </div>)}
        </div>
        <div>
          <button className={classes.buttonAdd} onClick={(): void => {
            appStore.profile.addInputLink(props.name);
          }}>
            <i className={classes.addIcon}></i>
            <span>добавить еще</span>
          </button>
        </div>
      </div>
    </div>
  );
});
