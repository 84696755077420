import React from 'react';
import { useStyles } from './transparentBtn.styles';
import { IProps } from './types';

export const TransparentBtn = ({
  children,
  onClick,
  className,
}: IProps): JSX.Element => {
  const classes = useStyles();

  return (
    <button className={`${classes.button} ${className}`} onClick={onClick}>
      {children}
    </button>
  );
};
