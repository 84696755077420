import classNames from 'classnames';
import { observer } from 'mobx-react-lite';
import { useStyles } from './selectCustom.styles';
import { ISelectCustom } from './types';

export const SelectCustom = observer((props: ISelectCustom): JSX.Element => {
  const classes = useStyles();
  const labelClass = classNames(props.label === '' ? classes.labelNo : classes.label);
  const select = classNames(props.className, classes.select);

  return (
    <div className={classes.container}>
      <label className={labelClass} htmlFor="">{props.label}</label>
      {
        // @ts-ignore
        <select value={props.value} onChange={props.submit} className={select} name={props.name} id="">
          {props.option.map((el, i) => <option key={i} value={el}>{el}</option>)}
        </select>
      }
    </div>
  );
});
