import { createUseStyles } from 'react-jss';

const PREFIX = 'Pagination';
export const useStyles = createUseStyles({
  pagination: {
    '& .rc-pagination-item': {
      display: 'none',
    },
    '& .rc-pagination-item-link': {
      border: 'none',
      '& button': {
        border: 'none',
      },
    },
    '&.rc-pagination-prev, button:after': {
      transform: 'scale(2.0)',
    },
    '& .rc-pagination-jump-prev': {
      display: 'none',
    },
    '& .rc-pagination-jump-next': {
      display: 'none',
    },
    '& .rc-pagination-options': {
      margin: 0,
    },
    '& .rc-pagination': {
      listStyle: 'none',
      display: 'flex',
      paddingLeft: '40px',
      fontSize: '16px',
    },
    '& .rc-pagination-total-text': {
      width: '170px',
      height: '22px',
    },
  },
}, { name: PREFIX });
