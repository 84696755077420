import { ThemeProvider } from 'react-jss';
import ReactDOM from 'react-dom/client';
import { Auth } from './components/auth/auth.view';
import { theme } from './components/theme';
import { ErrorBoundary } from './ErrorBoundary';
import { Routing } from './routes/index';

const root = ReactDOM.createRoot(document.getElementById('root') as HTMLElement);
root.render(
  <ErrorBoundary>
    <ThemeProvider theme={theme}>
      <Auth>
        <Routing />
      </Auth>
    </ThemeProvider>
  </ErrorBoundary>,
);
