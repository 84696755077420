import React, { useContext, useEffect, useState } from 'react';
import { Link } from 'react-router-dom';
import DOMPurify from 'dompurify';
import { observer } from 'mobx-react';
import { TransparentBtn } from '../../components/transparentBtn/TransparentBtn';
import { appStoreContext } from '../../store/context/context.store';
import { Document } from '../adminLayout/admin/documents/Document';
import { GroupHeader } from '../adminLayout/admin/groupHeader/GroupHeader';
import { UserApplication } from '../adminLayout/admin/userApplications/UserApplication';
import { User } from '../adminLayout/admin/users/User';
import { ApplicationsModal } from '../modal/applications/Applications';
import { DocumentAddModal } from '../modal/documentAdd/DocumentAdd';
import { GroupDeleteModal } from '../modal/groupDelete/GroupDelete';
import { GroupSettings } from '../modal/groupSettings/GroupSettings';
import { UserAddModal } from '../modal/userAdd/UserAdd';
import { UsersListModal } from '../modal/usersList/UsersList';
import { useStyles } from './group.styles';
import 'rc-tabs/assets/index.css';
import 'rc-input/assets/index.css';

export const Group = observer((): JSX.Element => {
  const [groupDeleteVisible, setGroupDeleteVisible] = useState(false);
  const [settingsVisible, setSettingsVisible] = useState(false);
  const [userAddVisible, setUserAddVisible] = useState(false);
  const [docAddVisible, setDocAddVisible] = useState(false);
  const [usersVisible, setUsersVisible] = useState(false);
  const [leaveGroup, setLeaveGroup] = useState(false);
  const [appVisible, setAppVisible] = useState(false);
  const appStore = useContext(appStoreContext);
  const classes = useStyles();
  const searchString = new URLSearchParams(window.location.search);
  const groupController = appStore.user.isSuperAdmin ? 'admin' : 'groups';
  const groupId = searchString.get('groupId');
  const groupDetails = appStore.group.groupDetails;
  const requested = appStore.group.requested;
  const isSuperAdmin = appStore.user.isSuperAdmin;
  const documents = appStore.document.documents;
  const usersTotal = appStore.group.subsTotal;
  const users = appStore.group.subscriber;
  const isOwner = appStore.group.isOwner;
  const isAdmin = appStore.group.isAdmin;
  const userId = appStore.user.user._id;
  const bodyGetGroupsSubs = {
    page: 1,
    limit: 10,
    groupId,
  };

  useEffect(() => {
    appStore.group.findGroupById(groupId);
    appStore.document.getDocuments(groupId, groupController);
    appStore.group.getGroupsSubscribers(bodyGetGroupsSubs);
  }, []);

  useEffect(() => {
    if (groupDetails?.type === 'Закрытый') {
      appStore.group.getGroupsRequests(bodyGetGroupsSubs);
    }
  }, [groupDetails?.type]);

  useEffect(() => {
    const changeLeaveGroup = changeBtnToLeaveGroup();
    setLeaveGroup(changeLeaveGroup);
  }, [users]);

  const deleteGroup = (): void => {
    setSettingsVisible(false);
    setGroupDeleteVisible(true);
  };

  // eslint-disable-next-line init-declarations
  let description;
  if (groupDetails?.desc) {
    description = DOMPurify.sanitize(groupDetails.desc, {
      ADD_TAGS: ['b', 'i', 'em', 'strong', 'a'],
      ADD_ATTR: ['href', 'target'],
    });
  }

  const returnDocumentText = (): string => {
    let docText = 'Вы не являетесь участником данной группы, поэтому не можете просматривать её документы';
    if (isSuperAdmin) {
      docText = 'В этой группе отсутствуют документы';
    }
    users?.map((item) => {
      if (item.sub_id === userId) {
        docText = 'У вас нет прав на загрузку документа';
      }
    });
    if (isOwner || isAdmin) {
      docText = 'Вы еще не загрузили документы';
    }
    return docText;
  };

  const changeBtnToLeaveGroup = (): boolean => {
    let btnState = false;
    users?.map((item) => {
      if (!isOwner && !isAdmin && userId === item.sub_id) {
        btnState = true;
      }
    });
    return btnState;
  };

  const returnDocBtn = (): JSX.Element | null => {
    if (isOwner || isAdmin) {
      return (<TransparentBtn
        className={classes.uploadBtn}
        onClick={(): void => setDocAddVisible(true)}
      >
        + Добавить документ
      </TransparentBtn>);
    }
    return null;
  };

  return (
    <div className={classes.mainBlock}>
      <div className={classes.mainHeader}>
        <Link to="/groups" className={classes.link}>
          <div className={classes.textGroup2}>
            Группы
          </div>
        </Link>
        <span className={classes.line}></span>
        <div className={`${classes.textGroup2} ${classes.darkText}`}>
          {groupDetails?.name}
        </div>
      </div>
      <GroupHeader
        groupDetails={groupDetails}
        setUserAddVisible={setUserAddVisible}
        setSettingsVisible={setSettingsVisible}
        leaveGroup={leaveGroup}
      />
      <div className={classes.contentBlock}>
        <div className={classes.childContent1}>
          {
            (requested.length && (isAdmin || isOwner))
              ? <UserApplication setAppVisible={setAppVisible} />
              : 'В данной группе еще нет заявок'
          }
          <div className={classes.textBlock}>
            <div className={classes.blockText1}>Описание группы</div>
            <div className={classes.blockText2}>
              <div
                className={classes.blockText2}
                dangerouslySetInnerHTML={{ __html: description || '' }}
              >
              </div>
            </div>
          </div>
          <div className={classes.grayLine}></div>
          <div>
            <div className={classes.docBlock}>
              <div className={classes.blockText1}>Документы группы</div>
              <div>
                {returnDocBtn()}
              </div>
            </div>
            <div>
              {documents?.length
                ? documents.map((item) => (
                  <Document item={item} key={item._id} />
                ))
                : <div>{returnDocumentText()}</div>
              }
            </div>
          </div>
        </div>
        <div className={classes.childContent2}>
          <div
            className={classes.usersList}
            onClick={(): void => setUsersVisible(true)}
          >
            <div className={classes.userListHeader}>
              <div className={classes.blockText1}>Список участников</div>
              <div className={classes.userCount}>{usersTotal}</div>
            </div>
            <div className={classes.userListBody}>
              {users ? users.map((item) => (
                <User item={item} key={item.sub_id} />
              ))
                : <div>Участников нет</div>
              }
            </div>
          </div>
        </div>
      </div>
      <ApplicationsModal
        visible={appVisible}
        onClose={(): void => setAppVisible(false)}
        setAppVisible={setAppVisible}
      />
      <UsersListModal
        visible={usersVisible}
        onClose={(): void => setUsersVisible(false)}
        isOwner={isOwner}
        isAdmin={isAdmin}
      />
      <DocumentAddModal
        visible={docAddVisible}
        onClose={(): void => setDocAddVisible(false)}
        setDocAddVisible={setDocAddVisible}
      />
      <GroupSettings
        visible={settingsVisible}
        onClose={(): void => setSettingsVisible(false)}
        onDelete={deleteGroup}
        setSettingsVisible={setSettingsVisible}
        groupDetails={groupDetails}
      />
      <GroupDeleteModal
        visible={groupDeleteVisible}
        onClose={(): void => setGroupDeleteVisible(false)}
      />
      <UserAddModal
        visible={userAddVisible}
        onClose={(): void => setUserAddVisible(false)}
      />
    </div>
  );
});
