import React, { useContext } from 'react';
import { useNavigate } from 'react-router';
import classNames from 'classnames/bind';
import Dropdown from 'rc-dropdown';
import menu from '../../../../assets/icons/Menu.svg';
import { Avatar } from '../../../../components/avatar/Avatar';
import { IManageSubsData } from '../../../../models/stores/subscriber.store';
import { appStoreContext } from '../../../../store/context/context.store';
import { IUser } from '../types';
import { useStyles } from './user.styles';

export const User = ({ item, isOwner }: IUser): JSX.Element => {
  const searchString = new URLSearchParams(window.location.search);
  const groupId = searchString.get('groupId');
  const appStore = useContext(appStoreContext);
  const userId = appStore.user.user._id;
  const navigate = useNavigate();
  const classes = useStyles();
  const cx = classNames.bind(classes);

  const data = {
    groupId,
    subscriberId: item.sub_id,
    status: 2,
  };

  const classForRole = cx({
    participant: item.role === 0,
    admin: item.role === 1,
    owner: item.role === 2,
  });

  const tranlateRole = (role: number): string => {
    if (role === 1) {
      return 'Администратор';
    }
    if (role === 2) {
      return 'Владелец';
    }
    return 'Участник группы';
  };

  const roleData = (role: number): IManageSubsData => {
    const params = {
      groupId,
      subscriberId: item.sub_id,
      role,
    };
    return params;
  };

  const manageRole = async(role: number): Promise<void> => {
    await appStore.subscriber.manageSubsRole(roleData(role));
    appStore.group.getGroupsSubscribers({ groupId, page: 1, limit: 10 });
  };

  const returnManageSubs = (): JSX.Element => {
    if (item.role === 1) {
      return (
        <li
          className={classes.menuItem}
          onClick={(): Promise<void> => manageRole(0)}>
          Изъять роль
        </li>
      );
    }
    return (
      <li
        className={classes.menuItem}
        onClick={(): Promise<void> => manageRole(1)}>
        Назначить администратором
      </li>
    );
  };

  const handleDelete = async(): Promise<void> => {
    await appStore.subscriber.deleteSubs(data);
    appStore.group.getGroupsSubscribers({ groupId, page: 1, limit: 10 });
  };

  return (
    <div className={classes.appUsers}>
      <div className={classes.appUsersBlock}>
        {
          item.avatar_sub
            ? <img
              src={`${process.env.REACT_APP_S3_URL}${item.avatar_sub}`}
              alt="avatar"
              className={classes.appUsersImg}
            />
            : <Avatar
              name={item.name_sub || 'Нет имени'}
              className={classes.appUsersImg}
            />
        }
        <div className={classes.appModalName}>{item.name_sub || 'Нет имени'}</div>
      </div>
      <div className={`${classes.roleBlock} ${classes.appUsersBlock}`}>
        <div className={classForRole}>{tranlateRole(item.role || 0)}</div>
      </div>
      <div className={`${classes.dropBlock} ${classes.appUsersBlock}`}>
        <Dropdown
          trigger={['hover']}
          overlay={
            <ul className={classes.menu}>
              <li
                className={classes.menuItem}
                onClick={(): void => navigate(`/subscribe/user/${item.sub_id}`)}>
                Перейти в профиль
              </li>
              {(isOwner && userId !== item.sub_id) &&
              <>
                {returnManageSubs()}
                <li
                  className={classes.menuItem}
                  onClick={handleDelete}>
                  Исключить из группы
                </li>
              </>
              }
            </ul>
          }
          animation="slide-up"
        >
          <img src={menu} alt="" className={classes.dropImg} />
        </Dropdown>
      </div>
    </div>
  );
};
