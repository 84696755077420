import React, { ChangeEvent, useContext } from 'react';
import { observer } from 'mobx-react-lite';
import Input from 'rc-input';
import closeIcon from '../../../assets/icons/close.svg';
import SearchIcon from '../../../assets/icons/searchIcon.svg';
import { SelectSearchInput } from '../../../components/selectSearchInput/SelectSearchInput';
import {
  academic_degree,
  academic_title,
  academicDegreeConst,
  academicTitleConst,
  branch,
  scien_title,
  selectSearchConst,
} from '../../../shared/list.constants';
import { appStoreContext } from '../../../store/context/context.store';
import { useStyles } from './search.styles';
import { SelectFilter } from './SelectFilter/SelectFilter';

export const Search = observer((): JSX.Element => {
  const appStore = useContext(appStoreContext);
  const { filter } = appStore.search.searchData;
  const classes = useStyles();
  const placeholderInput = appStore.search.searchData.selectSearch &&
  +appStore.search.searchData.selectSearch[0] === 0
    ? 'Поиск по профильному направлению' : appStore.search.searchData.selectSearch &&
    +appStore.search.searchData.selectSearch[0] === 1 ? 'Поиск по организации' : 'Поиск по ФИО';

  const onChangeValue = (e: ChangeEvent<HTMLInputElement>): void => {
    const value = e.target.value;
    const name = e.target.name;
    appStore.search.updateInputSearch(value, name);
  };

  return (
    <div className={classes.container}>
      <div className={classes.inputBlock}>
        <SelectSearchInput optionsList={selectSearchConst} name="selectSearch" />
        <div className={classes.vector}> <img src="" alt="" /> </div>
        <Input
          onChange={onChangeValue}
          value={appStore.search.searchData.inputSearch}
          placeholder={placeholderInput}
          className={classes.searchInput}
          name="inputSearch"
        />
        <div>
          <img src={SearchIcon} alt="" className={classes.searchIcon} />
        </div>
      </div>
      <div className={classes.selects}>
        <SelectFilter optionsList={branch} selectedOption={filter.scien_for_degree ? scien_title[filter.scien_for_degree[0]] : 'Отрасль'} name="scien_for_degree" />
        <SelectFilter optionsList={academicDegreeConst} selectedOption={filter.academic_degree ? academic_degree[filter.academic_degree[0]] : 'Ученая степень'} name="academic_degree" />
        <SelectFilter optionsList={academicTitleConst} selectedOption={filter.academic_title ? academic_title[filter.academic_title[0]] : 'Ученое звание'} name="academic_title" />
        {((filter.scien_for_degree && filter.scien_for_degree[0] !== 0)
            || (filter.academic_degree && filter.academic_degree[0] !== 0)
            || (filter.academic_title && filter.academic_title[0] !== 0)) &&
          <div onClick={appStore.search.clearFilters} className={classes.clearFiltersBtn}><img src={closeIcon} alt="" />Сбросить фильтр</div>}
      </div>
    </div>
  );
});
