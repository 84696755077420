import { createUseStyles } from 'react-jss';

const PREFIX = 'QuillToolbar';
export const useStyles = createUseStyles(
  {
    toolbar: {
      background: '#F5F5F7',
      border: '1px solid #E4E7F2',
      borderRadius: '0px 0px 4px 4px',
    },
  },
  { name: PREFIX },
);
