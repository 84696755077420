import { useContext, useEffect, FC } from 'react';
import { observer } from 'mobx-react-lite';
import Pagination from 'rc-pagination';
import { appStoreContext } from '../../../store/context/context.store';
import { useStyles } from './pagination.styles';

export const PaginationCustom: FC<{ mySubs?: boolean }> = observer(({ mySubs }): JSX.Element => {
  const classes = useStyles();
  const appStore = useContext(appStoreContext);
  const { pagination } = appStore.pagination;

  useEffect(() => {
    appStore.pagination.onchangePagination(1);
  }, [
    mySubs,
    appStore.search.searchData.inputSearch,
    appStore.search.searchData.filter.academic_degree,
    appStore.search.searchData.filter.academic_title,
    appStore.search.searchData.filter.scien_for_degree,
  ]);

  return (
    <div className={classes.pagination}>
      <Pagination
        showTotal={(total, range): string => `${range[0]} - ${range[1]} из ${total} пользователей`}
        total={appStore.subscriptions.subscription.total}
        pageSize={pagination.pageSize}
        current={pagination.current}
        onChange={appStore.pagination.onchangePagination}
      />
    </div>
  );
});
