import { Component, ErrorInfo, ReactNode } from 'react';

interface IState {
  hasError: boolean, message: string
}

export class ErrorBoundary extends Component<{ children: ReactNode }> {

  state: IState = {
    hasError: false,
    message: '',
  };

  static getDerivedStateFromError(error: Error): IState {
    // Update state so the next render will show the fallback UI.
    return { hasError: true, message: error.message };
  }

  componentDidCatch(error: Error, errorInfo: ErrorInfo): void {
    // You can also log the error to an error reporting service
    console.error(error, errorInfo);
  }

  render(): ReactNode {
    if (this.state.hasError) {
      // You can render any custom fallback UI
      return <pre>{this.state.message}</pre>;
    }

    return this.props.children;
  }

}
