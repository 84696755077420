import { createUseStyles } from 'react-jss';

const PREFIX = 'Main';
export const useStyles = createUseStyles(
  {
    col: {
      position: 'relative',
      overflowY: 'hidden',
    },
    container: {
      minWidth: '100vw',
      background: '#F5F6F8',
      overflow: 'hidden',
      zIndex: -999,
    },
    oracle: {
      transform: 'rotate(-17.09deg)',
      position: 'absolute',
      top: '47.51%',
      left: '-2.84%',
      zIndex: 0,
    },
    triangle: {
      position: 'absolute',
      left: '39.51%',
      zIndex: 0,
    },
    footer: {
      paddingBottom: '52px',
      display: 'flex',
      justifyContent: 'space-around',
      width: '100vw',
      height: '128px',
      left: '0px',
      top: '952px',
      bottom: 0,
    },
  },
  { name: PREFIX },
);
