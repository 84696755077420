import { createUseStyles } from 'react-jss';
import { theme } from '../theme';

const PREFIX = 'ButtonGhost';
export const useStyles = createUseStyles({
  button: {
    boxSizing: 'border-box',
    display: 'flex',
    alignItems: 'center',
    padding: '12px 24px',
    border: '1px solid #0071CE',
    borderRadius: '4px',
    fontFamily: theme.ibm,
    fontSize: '16px',
    fontWeight: 500,
    lineHeight: '20px',
    letterSpacing: '0px',
    textAlign: 'left',
    color: theme.colorPrimary,
    background: '#fff',
    cursor: 'pointer',
    '&:hover': {
      background: '#F5FBFF',
    },
    '&:active': { background: '#F5F5F7' },
    '&:disabled': {
      color: '#BDBDBD',
      background: '#F5F5F7',
      border: '1px solid #BDBDBD',
    },
  },
  icon: {
    marginRight: '8px',
  },
}, { name: PREFIX });
