import { createUseStyles } from 'react-jss';
import { theme } from '../theme';

const PREFIX = 'PaginationCustom';
export const useStyles = createUseStyles(
  {
    footer: {
      display: 'flex',
      flexDirection: 'column',
      alignItems: 'center',
      gap: '32px',
      paddingBottom: '80px',
    },
    footerTop: {
      display: 'flex',
      width: '100%',
      justifyContent: 'space-between',
      alignItems: 'center',
      fontFamily: theme.ibm,
      fontSize: '16px',
      fontWeight: 400,
      color: theme.textColorPlaceholder,
    },
    selectProfile: {
      border: 'none',
      color: theme.colorPrimary,
      width: '65px',
    },
    pagination: {
      display: 'flex',
      alignItems: 'center',
    },
    paginationSelect: {
      position: 'relative',
      cursor: 'pointer',
    },
    dropDownHeader: {
      color: '#0071CE',
      background: '#FFFFFF',
      height: '30px',
      display: 'flex',
      paddingLeft: '4px',
      alignItems: 'center',
    },
    dropDownListContainer: {
      position: 'absolute',
      width: '100%',
      marginTop: '-16px',
      marginLeft: '-7px',
    },
    dropDownList: {
      listStyle: 'none',
      width: '100%',
      maxHeight: '85px',
      zIndex: '10',
      boxSizing: 'border-box',
      background: '#FFFFFF',
      boxShadow: '0px 4px 15px rgba(0, 0, 0, 0.05) !important',
      borderRadius: '4px !important',
      '&::-webkit-scrollbar': {
        display: 'none !important',
      },
    },
    listItem: {
      height: '100%',
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'center',
      padding: '2px 0',
      marginLeft: '-2.5em',
      color: '#3B4168',
      fontWeight: '400',
      fontSize: '16px',
      lineHeight: '20px',
      '&:hover': {
        background: '#F5FBFF',
      },
    },
    paginationCustom: {
      '& .rc-pagination-item': {
        display: 'none',
      },
      '& .rc-pagination-item-link': {
        border: 'none',
        '& button': {
          border: 'none',
        },
      },
      '&.rc-pagination-next, button': {
        backgroundColor: 'white !important',
      },
      '&.rc-pagination-prev, button': {
        backgroundColor: 'white !important',
      },
      '&.rc-pagination-prev, button:after': {
        display: 'none',
      },
      '&.rc-pagination-next, button:after': {
        display: 'none',
      },
      '& .rc-pagination-jump-prev': {
        display: 'none',
      },
      '& .rc-pagination-jump-next': {
        display: 'none',
      },
      '& .rc-pagination-options': {
        margin: 0,
      },
      '& .rc-pagination': {
        listStyle: 'none',
        display: 'flex',
        paddingLeft: '40px',
        fontSize: '16px',
      },
      '& .rc-pagination-total-text': {
        width: '170px',
        height: '22px',
      },
    },
    rcPaginationNext: {
      display: 'block',
      position: 'relative',
      width: '100%',
      height: '100%',
      fontSize: '12px',
      textAlign: 'center',
      border: 'none',
      outline: 'none',
      transition: 'all 0.3s',
    },
    rcPaginationPrev: {
      display: 'block',
      width: '100%',
      height: '100%',
      fontSize: '12px',
      textAlign: 'center',
      border: 'none',
      outline: 'none',
      transition: 'all 0.3s',
    },
    prevDisabled: {
      color: '#909EBB !important',
      cursor: 'not-allowed !important',
      pointerEvents: 'none',
    },
    icon: {
      filter: 'invert(52%) sepia(10%) saturate(441%) hue-rotate(194deg) brightness(83%) contrast(81%);',
      '&:hover': {
        filter: 'invert(27%) sepia(69%) saturate(2093%) hue-rotate(189deg) brightness(98%) contrast(103%);',
      },
    },
  },

  { name: PREFIX },
);
