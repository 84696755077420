export const branch = [
  'Отрасль',
  'Архитектура',
  'Биологические',
  'Ветеринарные',
  'Географические',
  'Геолого-минералогические',
  'Искусствоведение',
  'Исторические',
  'Культурологические',
  'Культурология',
  'Медицинские',
  'Педагогические',
  'Политические',
  'Психологические',
  'Сельскохозяйственные',
  'Социологические',
  'Теология',
  'Технические',
  'Фармацевтические',
  'Физико-математические',
  'Филологические',
  'Философские',
  'Химические',
  'Экономические',
  'Юридические',
  'Интеллектуальная собственность и трансфер технологий',
];
export const academicDegreeConst = ['Ученая степень', 'Кандидат наук', 'Доктор наук'];
export const academicTitleConst = ['Ученое звание', 'Доцент', 'Профессор'];
export const selectSearchConst = ['Профильное направление', 'Организация', 'ФИО'];

export const scien_title = {
  0: 'Отрасль',
  1: 'Архитектура',
  2: 'Биологические',
  3: 'Ветеринарные',
  4: 'Географические',
  5: 'Геолого-минералогические',
  6: 'Искусствоведение',
  7: 'Исторические',
  8: 'Культурологические',
  9: 'Культурология',
  10: 'Медицинские',
  11: 'Педагогические',
  12: 'Политические',
  13: 'Психологические',
  14: 'Сельскохозяйственные',
  15: 'Социологические',
  16: 'Теология',
  17: 'Технические',
  18: 'Фармацевтические',
  19: 'Физико-математические',
  20: 'Филологические',
  21: 'Философские',
  22: 'Химические',
  23: 'Экономические',
  24: 'Юридические',
  25: 'Интеллектуальная собственность и трансфер технологий',
};

export const academic_title = {
  0: 'Ученое звание',
  1: 'Доцент',
  2: 'Профессор',
};

export const academic_degree = {
  0: 'Ученая степень',
  1: 'Кандидат наук',
  2: 'Доктор наук',
};

export const select_search = {
  0: 'Профильное направление',
  1: 'Организация',
  2: 'ФИО',
};

const title = Object
  .entries(academic_title)
  .map(([key, value]) => [value, key]);

export const academic_title_back = Object.fromEntries(title);


const degree = Object
  .entries(academic_degree)
  .map(([key, value]) => [value, key]);

export const academic_degree_back = Object.fromEntries(degree);

const scien = Object
  .entries(scien_title)
  .map(([key, value]) => [value, key]);

export const scien_title_back = Object.fromEntries(scien);

const select_search_value = Object
  .entries(select_search)
  .map(([key, value]) => [value, key]);

export const select_search_back = Object.fromEntries(select_search_value);
