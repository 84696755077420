import { createUseStyles } from 'react-jss';

const PREFIX = 'InstrumentInput';
export const useStyles = createUseStyles(
  {
    littleText: {
      fontSize: '14px',
      fontWeight: '400',
      lineHeight: '20px',
      color: '#6F7C98',
    },
    modalTextArea: {
      width: '716px',
      maxWidth: '716px',
      minWidth: '716px',
      height: '112px',
      border: '1px solid #E4E7F2',
      borderRadius: '4px 4px 0px 0px',
      fontSize: '16px',
      fontWeight: '400',
      lineHeight: '20px',
      color: '#3B4168',
      fontFamily: 'Montserrat, sans-serif',
      margin: '4px 0 0 0',
    },
    instrumentBlock: {
      width: '100%',
      height: '34px',
      background: '#F5F5F7',
      borderRadius: '0px 0px 4px 4px',
      border: '1px solid #E4E7F2',
      marginTop: '-4px',
      marginBottom: '24px',
    },
    instrumentBlockImg: {
      margin: '7px 0 0 16.5px',
      cursor: 'pointer',
    },
    quill: {
      height: '100px',
      '& .ql-container.ql-snow': {
        background: '#FFFFFF',
        border: '1px solid #E4E7F2',
        borderRadius: '4px 4px 0px 0px',
        fontWeight: '400',
        fontSize: '16px',
        lineHeight: '20px',
        color: '#3B4168',
      },
    },
    warningNull: {
      color: 'red',
      position: 'absolute',
      lineHeight: '3px',
      fontSize: '12px',
      marginTop: '1%',
      opacity: '0',
      transition: '0.3s',
    },
    warning: {
      color: 'red',
      position: 'absolute',
      lineHeight: '3px',
      fontSize: '12px',
      marginTop: '1%',
      opacity: '1',
      transition: '0.2s',
    },
    warningName: {
      border: '1px solid red',
      borderRadius: '4px',
    },
  },
  { name: PREFIX },
);
