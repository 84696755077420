import React from 'react';
import { Avatar } from '../../../../components/avatar/Avatar';
import { IUser } from './types';
import { useStyles } from './user.styles';

export const User = ({ item }: IUser): JSX.Element => {
  const classes = useStyles();

  return (
    <div className={classes.userBlock}>
      <div>
        {
          item.avatar_sub
            ? <img
              src={`${process.env.REACT_APP_S3_URL}${item.avatar_sub}`}
              alt="avatar"
              className={classes.userBlockImg}
            />
            : <Avatar
              name={item.name_sub || 'Нет имени'}
              className={classes.userBlockImg}
            />
        }
      </div>
      <div>
        <div className={classes.userBlockText}>{item.name_sub || 'Нет имени'}</div>
      </div>
    </div>
  );
};
