import { ChangeEvent } from 'react';
import { configure, makeAutoObservable, observable } from 'mobx';
import { v1 as uuidv1 } from 'uuid';
import { IProfileDate, IProfiles } from '../../models/stores/profile.store';
import { IRootStore } from '../../models/stores/root.store';
import { academic_degree_back, academic_title_back, scien_title_back } from '../../shared/list.constants';

configure({ enforceActions: 'observed' });

export class ProfileStore implements IProfiles {

  public readonly rootStore: IRootStore;
  @observable profileData: IProfileDate = {
    isMeSubscribed: false,
    isSubscribed: false,
    profile: {
      _id: '',
      work: ' ',
      achieve: [{ name: '0', value: '', _id: '' }],
      contacts: { telegram: '', email: '', vk: '' },
      projects: [{ name: '0', value: '', _id: '' }],
      pubs: [{ name: '0', value: '', _id: '' }],
      avatar: ' ',
      academic_title: 1,
      academic_degree: 1,
      scien_for_degree: 1,
      created_at: '',
      directions: '',
      edu: '',
      edu_ind: '',
      edu_prog: '',
      full_name: '',
      full_name_low: '',
      interests: '',
      portfolio: '',
      preffered: '',
      updated_at: '',
      is_private: false,
    },
  };

  constructor(rootStore: IRootStore) {
    this.rootStore = rootStore;

    makeAutoObservable(this, {
      rootStore: false,
    });
    this.onChangeInput = this.onChangeInput.bind(this);
    this.onChangeSelect = this.onChangeSelect.bind(this);
    this.setActiveProfile = this.setActiveProfile.bind(this);
    this.update = this.update.bind(this);
  }

  updateProfile(name: string, value: string | boolean): void {
    if (typeof value === 'string') {
      if (name.includes('achieve')) {
        this.profileData.profile.achieve[+name.slice(7)].value = value;
      }
      else if (name.includes('pubs')) {
        this.profileData.profile.pubs[+name.slice(4)].value = value;
      }
      else if (name.includes('projects')) {
        this.profileData.profile.projects[+name.slice(8)].value = value;
      }
      if (name === 'telegram' || name === 'email' || name === 'vk') {
        this.profileData.profile.contacts[name] = value;
      }
      else {
        // @ts-ignore
        this.profileData.profile[name] = value;
      }
    }
  }
  update(body: IProfileDate): void {
    this.profileData = body;
  }
  onChangeInput(e: ChangeEvent<HTMLInputElement>): void {
    const name = e.target.name;
    const value = e.target.value;
    if (name === 'is_private') {
      this.updateProfile(name, e.target.checked);
    }
    this.updateProfile(name, value);
  }

  onChangeSelect(e: ChangeEvent<HTMLSelectElement>): void {
    const name = e.target.name;
    // eslint-disable-next-line init-declarations
    let value;
    if (name === 'academic_title') {
      value = academic_title_back[e.target.value];
    }
    else
    if (name === 'academic_degree') {
      value = academic_degree_back[e.target.value];
    }
    else if (name === 'scien_for_degree') {
      value = scien_title_back[e.target.value];
    }
    this.updateProfile(name, value);
  }

  addInputLink(name: 'achieve' | 'pubs' | 'projects'): void {
    const len = this.profileData.profile[name].length;
    const id = uuidv1();
    this.profileData.profile[name].push({ name: len.toString(), value: ' ', _id: id });
  }

  deleteInputLink(name: 'achieve' | 'pubs' | 'projects', id: string): void {
    this.profileData.profile[name] = this.profileData.profile[name].filter((el) => el._id !== id);
  }
  setActiveProfile(): void {
    this.profileData.profile.is_private = !this.profileData.profile.is_private;
  }

}
