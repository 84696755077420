import { makeAutoObservable } from 'mobx';
import { IRootStore } from '../../models/stores/root.store';
import { IManageSubsData, ISubsDeleteData, ISubsStore } from '../../models/stores/subscriber.store';

export class SubscriberStore implements ISubsStore {

  public readonly rootStore: IRootStore;

  constructor(rootStore: IRootStore) {
    this.rootStore = rootStore;

    makeAutoObservable(this, {
      rootStore: false,
    });
  }

  public async leaveGroups(id: string): Promise<void> {
    try {
      await this.rootStore.requests.json<void>({
        baseURL: this.rootStore.config.baseURL + this.rootStore.config.baseURLPostfix,
        headers: {
          authorization: this.rootStore.user.token || '',
        },
        method: 'DELETE',
        route: `subscriptions/delete-subscription?id=${id}`,
      });
    }
    catch (error) {
      console.log(error);
    }
  }

  public async subscribeToGroup(id: string): Promise<void> {
    try {
      await this.rootStore.requests.json<void>({
        baseURL: this.rootStore.config.baseURL + this.rootStore.config.baseURLPostfix,
        headers: {
          authorization: this.rootStore.user.token || '',
        },
        method: 'POST',
        route: `subscriptions/subscribe-to-group?id=${id}`,
      }).then(() => this.rootStore.group.getGroupsSubscribers({ groupId: id, page: 1, limit: 10 }));
    }
    catch (error) {
      console.log(error);
    }
  }

  public async deleteSubs(data: ISubsDeleteData): Promise<void> {
    try {
      await this.rootStore.requests.json<void>({
        baseURL: this.rootStore.config.baseURL + this.rootStore.config.baseURLPostfix,
        headers: {
          authorization: this.rootStore.user.token || '',
        },
        method: 'PUT',
        route: `subscriptions/manage-group-subs?groupId=${data.groupId}&subscriberId=${data.subscriberId}&status=${data.status}`,
      });
    }
    catch (error) {
      console.log(error);
    }
  }

  public async manageSubsRole(data: IManageSubsData): Promise<void> {
    try {
      await this.rootStore.requests.json<void>({
        baseURL: this.rootStore.config.baseURL + this.rootStore.config.baseURLPostfix,
        headers: {
          authorization: this.rootStore.user.token || '',
        },
        method: 'PUT',
        route: `subscriptions/assign-role?groupId=${data.groupId}&subscriberId=${data.subscriberId}&role=${data.role}`,
      });
    }
    catch (error) {
      console.log(error);
    }
  }

}
