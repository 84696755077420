import React, { useContext, useState } from 'react';
import { useNavigate } from 'react-router';
import classNames from 'classnames/bind';
import avatar from '../../../../assets/icons/Avatar.svg';
import check from '../../../../assets/icons/check.svg';
import groupCircle1 from '../../../../assets/icons/circle1.svg';
import groupCircle2 from '../../../../assets/icons/circle2.svg';
import groupCircle3 from '../../../../assets/icons/circle3.svg';
import groupCircle4 from '../../../../assets/icons/circle4.svg';
import settings from '../../../../assets/icons/settings.svg';
import lockIcon from '../../../../assets/icons/whiteLock.svg';
import { appStoreContext } from '../../../../store/context/context.store';
import { useStyles } from './groupHeader.styles';
import { IProps } from './types';

export const GroupHeader = ({ setSettingsVisible, setUserAddVisible, groupDetails, leaveGroup }: IProps): JSX.Element => {
  const [isRequested, setIsRequested] = useState(false);
  const searchString = new URLSearchParams(window.location.search);
  const groupId = searchString.get('groupId');
  const navigate = useNavigate();
  const appStore = useContext(appStoreContext);
  const groupController = appStore.user.isSuperAdmin ? 'admin' : 'groups';
  const isSuperAdmin = appStore.user.isSuperAdmin;
  const isAdmin = appStore.group.isAdmin;
  const isOwner = appStore.group.isOwner;
  const classes = useStyles();

  const cx = classNames.bind(classes);

  const BtnAdmin = cx({
    btnAdmin: isAdmin && !isOwner,
  });
  const nameAdmin = cx({
    nameAdmin: isAdmin && !isOwner,
  });

  const leaveGroupFunc = async(): Promise<void> => {
    await appStore.subscriber.leaveGroups(groupId);
    navigate('/groups');
    appStore.group.getMyGroupsSubscriptions({ limit: 10, page: 1 });
  };

  const handleSendRequest = (): void => {
    if (groupDetails?.type === 'Закрытый') {
      setIsRequested(true);
      handleAddToGroup();
    }
    else {
      handleAddToGroup();
    }
  };

  const handleAddToGroup = async(): Promise<void> => {
    await appStore.subscriber.subscribeToGroup(groupId);
    await appStore.document.getDocuments(groupId, groupController);
  };

  const returnBtnGroup = (): JSX.Element => {
    if (isAdmin && !isOwner) {
      return <div className={classes.adminBtns}>
        <button
          className={`${classes.btnCreate} ${BtnAdmin}`}
          onClick={(): void => setUserAddVisible && setUserAddVisible(true)}
        >
          + Пригласить участников
        </button>
        <button
          className={`${classes.btnCreateGroup} ${BtnAdmin}`}
          onClick={leaveGroupFunc}
        >
          Покинуть группу
        </button>
      </div>;
    }
    if (isAdmin || isOwner) {
      return <button
        className={classes.btnCreate}
        onClick={(): void => setUserAddVisible && setUserAddVisible(true)}
      >
        + Пригласить участников
      </button>;
    }
    if (groupDetails?.type === 'Закрытый' && isRequested) {
      return <button
        className={classes.btnCreateGroup}
      >
        Заявка отправлена
        <img className={classes.check} src={check} />
      </button>;
    }
    if (leaveGroup) {
      return <button
        className={classes.btnCreateGroup}
        onClick={leaveGroupFunc}
      >
        Покинуть группу
      </button>;
    }
    if (groupDetails?.type === 'Закрытый') {
      return <button
        className={classes.btnCreateGroup}
        onClick={handleSendRequest}
      >
      + Отправить заявку
      </button>;
    }
    return <button
      className={classes.btnCreateGroup}
      onClick={(): Promise<void> => handleAddToGroup()}
    >
    + Вступить в группу
    </button>;
  };


  return (
    <div className={classes.groupRequest}>
      <div className={classes.displayFlex}>
        <div>
          <img src={groupDetails?.avatar ? groupDetails.avatar : avatar} alt="" className={classes.cardImg} />
        </div>
        <div className={classes.wrapper}>
          <div className={classes.cardName}>
            <div className={`${classes.name} ${nameAdmin}`}>
              {groupDetails?.name}
            </div>
          </div>
          {returnBtnGroup()}
        </div>
      </div>
      <div className={classes.displayFlex2}>
        <div className={classes.circleMainBlock}>
          <div className={classes.circleBlock}>
            <img src={groupCircle1} alt="" className={classes.firstCircle} />
            <img src={groupCircle2} alt="" className={classes.secondCircle} />
          </div>
          <div className={classes.circleBlock}>
            <img src={groupCircle3} alt="" className={classes.thirdCircle} />
            <img src={groupCircle4} alt="" className={classes.fourthCircle} />
          </div>
        </div>
        <div className={classes.displayFlex3}>
          <div className={classes.statusBlock}>
            {groupDetails?.type === 'Закрытый' &&
              <img
                src={lockIcon}
                alt=""
                className={classes.lockIcon}
              />
            }
            <div className={classes.cardStatus}>{groupDetails?.type}</div>
          </div>
          {(isOwner || isSuperAdmin) &&
            <div
              className={classes.settingsBlock}
              onClick={(): void => setSettingsVisible && setSettingsVisible(true)}
            >
              <img src={settings} alt="" />
            </div>
          }
        </div>
      </div>
    </div>
  );
};
