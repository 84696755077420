import React, { useRef } from 'react';
import ReactQuill from 'react-quill';
import classNames from 'classnames/bind';
import { useStyles } from './instrumentInput.styles';
import { formats, modules, QuillToolbar } from './toolbar/Toolbar';
import { IInstrument } from './types';
import 'react-quill/dist/quill.snow.css';

export const InstrumentInput = ({ description, setDescription, clicked }: IInstrument): JSX.Element => {
  const classes = useStyles();
  const focusText = useRef<ReactQuill>(null);
  const cx = classNames.bind(classes);

  const warningName = cx({
    warningName: (description === '<p><br></p>' || description === '') && clicked,
  });
  const warningDesc = cx({
    warningNull: !clicked || description !== '<p><br></p>' && description !== '',
    warning: (description === '<p><br></p>' || description === '') && clicked,
  });

  const handleChange = (value:string):void => {
    setDescription(value);
  };

  return (
    <div>
      <div className={classes.littleText}>Описание группы</div>
      <ReactQuill
        theme="snow"
        value={description}
        onChange={handleChange}
        modules={modules}
        formats={formats}
        ref={focusText}
        className={`${classes.quill} ${warningName}`}
      />
      <QuillToolbar />
      <div className={warningDesc}>Вы не заполнили поле</div>
    </div>
  );
};
