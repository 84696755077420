import React from 'react';
import { useStyles } from './blueBtn.styles';
import { IProps } from './types';

export const BlueBtn = ({ children, onClick, className }: IProps): JSX.Element => {
  const classes = useStyles();

  return (
    <button className={`${classes.button} ${className}`} onClick={onClick}>
      {children}
    </button>
  );
};
