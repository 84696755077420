import { makeAutoObservable, observable } from 'mobx';
import { IRootStore } from '../../models/stores/root.store';
import { ISubscriptions, ITableData } from '../../models/stores/subscription.store';

export class Subscriptions implements ISubscriptions {

  public readonly rootStore: IRootStore;

  @observable subscription = {
    tableDate: [] as ITableData[],
    total: 0,
  };

  constructor(rootStore: IRootStore) {
    this.rootStore = rootStore;

    makeAutoObservable(this, { rootStore: false });

    this.addFriend = this.addFriend.bind(this);
    this.deleteSubscription = this.deleteSubscription.bind(this);
    this.updateTable = this.updateTable.bind(this);
  }

  addFriend(id: string): void {
    this.rootStore.user.addProfile(id).then((res) => {
      this.rootStore.profile.profileData.isMeSubscribed = !this.rootStore.profile.profileData.isMeSubscribed;
    });
  }

  deleteSubscription(id: string): void {
    this.rootStore.user.deleteSubscription(id).then((res) => {
      this.rootStore.profile.profileData.isMeSubscribed = !this.rootStore.profile.profileData.isMeSubscribed;
    });
  }

  deleteSubscriber(id: string): void {
    this.rootStore.user.deleteSubscriber(id).then((res) => console.log(res));
  }

  updateTable(res: { total: number, subs: ITableData[] }): void {
    this.subscription.total = res.total;
    this.subscription.tableDate = res.subs;
  }

}
