import React, { FC } from 'react';
import doneSvg from '../../assets/icons/done.svg';
import { useStyles } from './notificationAuth.styles';

export const NotificationAuth: FC = () => {
  const classes = useStyles();

  return (
    <div className={classes.container}>
      <div className={classes.content}>
        <img src={doneSvg} alt="done" className={classes.img} />
        <h2 className={classes.text}>
            Вы не авторизованы
        </h2>
        <p className={classes.text2}>
            Вам необходимо войти в систему.
          <br />
            Вы будете перенаправлены на экран авторизации.
        </p>
      </div>
    </div>
  );
};
