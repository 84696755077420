import React, { useContext } from 'react';
import deleteIcon from '../../../../assets/icons/delete.svg';
import downloadIcon from '../../../../assets/icons/Download.svg';
import fileIcon from '../../../../assets/icons/file.svg';
import { appStoreContext } from '../../../../store/context/context.store';
import { useStyles } from './document.styles';
import { IProps } from './types';

export const Document = ({ item, className }: IProps): JSX.Element => {
  const searchString = new URLSearchParams(window.location.search);
  const groupId = searchString.get('groupId');
  const appStore = useContext(appStoreContext);
  const groupController = appStore.user.isSuperAdmin ? 'admin' : 'groups';
  const isSuperAdmin = appStore.user.isSuperAdmin;
  const isOwner = appStore.group.isOwner;
  const classes = useStyles();

  const deleteDocuments = async(): Promise<void> => {
    const data = {
      groupId,
      fileId: item._id,
    };
    await appStore.document.deleteDocument(data, groupController);
    appStore.document.getDocuments(groupId, groupController);
  };

  return (
    <div className={classes.document} key={item._id}>
      <div className={`${classes.documentChild} ${className}`}>
        <img src={fileIcon} alt="" className={classes.documentChildImg} />
        <div className={classes.nameBlock}>
          <div className={classes.name}>{item.name}</div>
        </div>
      </div>
      <div className={classes.documentChild2}>
        <a href={item.link} className={classes.aTag}>
          <img
            src={downloadIcon}
            alt=""
            className={classes.documentChild2Img}
          />
        </a>
        {(isOwner || isSuperAdmin) &&
          <img
            src={deleteIcon}
            alt=""
            className={classes.documentChild2Img}
            onClick={deleteDocuments}
          />}
      </div>
    </div>
  );
};
