import { createUseStyles } from 'react-jss';
import { theme } from '../theme';

const PREFIX = 'NotificationAuth';
export const useStyles = createUseStyles(
  {
    container: {
      display: 'flex',
      position: 'fixed',
      zIndex: 999999,
      left: '0',
      top: '0',
      width: '100%',
      height: '100%',
      overflow: 'auto',
      backgroundColor: '#00000073',
    },
    content: {
      position: 'relative',
      backgroundColor: '#FFFFFF',
      margin: 'auto',
      border: '1px solid #F5F6F8',
      width: '430px',
      height: '222px',
      borderRadius: '15px',
      padding: '10px 16px',
      boxShadow: '0 3px 6px -4px #0000001f, 0 6px 16px #00000014, 0 9px 28px 8px #0000000d',
      webkitAnimationName: 'animatetop',
      webkitAnimationDuration: '0.4s',
      animationName: 'animatetop',
      animationDuration: '0.4s',
      textAlign: 'center',
    },
    text: {
      marginTop: '23px',
      fontSize: '18px',
      lineHeight: '1.57',
      color: '#3B4168',
      fontFamily: theme.mont,
    },
    text2: {
      color: '#909EBB',
      fontSize: 12,
      fontFamily: theme.mont,
    },
    img: {
      marginTop: 30,
    },
  },
  { name: PREFIX },
);
