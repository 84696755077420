import { createUseStyles } from 'react-jss';

const PREFIX = 'User';
export const useStyles = createUseStyles(
  {
    userBlock: {
      display: 'flex',
      marginTop: '20px',
    },
    userBlockImg: {
      width: '40px',
      height: '40px',
      borderRadius: '8px',
      margin: '0',
    },
    userBlockText: {
      fontWeight: '400',
      fontSize: '14px',
      lineHeight: '20px',
      color: '#3B4168',
      margin: '8px 0 0 16px',
    },
  },
  { name: PREFIX },
);
