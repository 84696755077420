import React, { useContext, useState } from 'react';
import { observer } from 'mobx-react-lite';
import iconSave from '../../../assets/icons/saveIcon.svg';
import { ButtonLarge } from '../../../components/buttonLarge/ButtonLarge';
import { InputCustom } from '../../../components/inputCustom/InputCustom';
import { RadioInput } from '../../../components/radioInput/RadioInput';
import { SelectCustom } from '../../../components/selectCustom/SelectCustom';
import {
  academic_degree,
  academic_title,
  academicDegreeConst,
  academicTitleConst,
  branch,
  scien_title,
} from '../../../shared/list.constants';
import { appStoreContext } from '../../../store/context/context.store';
import { CloseProfileModal } from '../../modal/closeProfile/CloseProfileModal';
import { OpenProfileModal } from '../../modal/openProfile/OpenProfileModal';
import { SaveChangesModal } from '../../modal/saveChanges/SaveChangesModal';
import { InputLink } from '../inputLink/InputLink';
import { Switch } from '../switch/Switch';
import { useStyles } from './profileRefactor.styles';

export const ProfileRefactoring = observer(({
  active,
  setActive,
}: { active: boolean, setActive: (value: boolean) => void }): JSX.Element => {
  const [settingsVisible, setSettingsVisible] = useState<boolean>(false);
  const [closeModal, setCloseModal] = useState<boolean>(false);
  const [openModalOpenProfile, setOpenModalOpenProfile] = useState<boolean>(false);
  const [errorPortfolio, setErrorPortfolio] = useState(false);
  const classes = useStyles();
  const appStore = useContext(appStoreContext);
  const { profile } = appStore.profile.profileData;
  const closeModalSaveChanges = (): void => {
    setSettingsVisible(false);
    setActive(false);
    appStore.user.getProfile(profile._id);
  };

  const ValidURL = (str: string): boolean => {
    if (str.length > 0) {
      const regex = /(?:https?):\/\/(\w+:?\w*)?(\S+)(:\d+)?(\/|\/([\w#!:.?+=&%!\-\/]))?/;
      if (!regex.test(str)) {
        setErrorPortfolio(true);
        return false;
      }
      return true;
    }
    return true;
  };

  const openModalSaveChanges = (): void => {
    if (ValidURL(appStore.profile.profileData.profile.portfolio)) {
      setSettingsVisible(true);
    }
  };

  const saveChanges = (): void => {
    setActive(!active);
    appStore.user.updateProfile({
      _id: profile._id,
      full_name: profile.full_name,
      work: profile.work,
      edu: '',
      academic_degree: +profile.academic_degree,
      academic_title: +profile.academic_title,
      scien_for_degree: +profile.scien_for_degree,
      edu_prog: '',
      edu_ind: '',
      contacts: { telegram: profile.contacts.telegram, email: profile.contacts.email, vk: profile.contacts.vk },
      preffered: profile.preffered,
      avatar: profile.avatar,
      interests: profile.interests,
      directions: profile.directions,
      portfolio: profile.portfolio,
      is_private: profile.is_private,
      projects: profile.projects.map((el) => ({
        value: el.value, name: el.name,
      })).filter((el) => el.value?.trim() !== ''),
      achieve: profile.achieve.map((el) => ({
        value: el.value, name: el.name,
      })).filter((el) => el.value?.trim() !== ''),
      pubs: profile.pubs.map((el) => ({
        value: el.value, name: el.name,
      })).filter((el) => el.value?.trim() !== ''),
    });
  };

  const closeModalCloseProfile = (): void => {
    setCloseModal(false);
  };

  const closedProfile = (): void => {
    appStore.profile.setActiveProfile();
    setCloseModal(false);
  };

  const closeModalOpenProfile = (): void => {
    setOpenModalOpenProfile(false);
  };

  const saveOpenProfile = (): void => {
    appStore.profile.setActiveProfile();
    setOpenModalOpenProfile(false);
  };

  return (
    <div>
      <div className={classes.cap}>
        <div className={classes.avatar}>
          <img className={classes.img} src={process.env.REACT_APP_S3_URL + profile.avatar} alt="" />
        </div>
        <div className={classes.data}>
          <div>
            <div className={classes.header}>
              <div className={classes.id}>ID: {profile._id}</div>
              <div className={classes.openProfile}>
                <Switch setActive={setCloseModal} activeModalOpenProfile={setOpenModalOpenProfile} />
                Закрыть профиль
              </div>
            </div>
            <div className={classes.name}>
              <h2 className={classes.nameUser}>{profile.full_name}</h2>
            </div>
          </div>
        </div>
      </div>
      <div className={classes.body}>
        <hr className={classes.lineShareSection} />
        <div className={classes.item}>
          <h5 className={classes.titleSectionProfile}>Сфера деятельности и интересов</h5>
          <div className={classes.top}>
            <InputCustom value={profile.work} name="work" placeholder="Введите наименование организации"
              label="Наименование организации" />
            <SelectCustom value={academic_degree[profile.academic_degree]} name="academic_degree" label="Ученая степень"
              option={academicDegreeConst} submit={appStore.profile.onChangeSelect} />
            <SelectCustom value={academic_title[profile.academic_title]} name="academic_title" label="Ученое звание"
              option={academicTitleConst} submit={appStore.profile.onChangeSelect} />
          </div>
          <div className={classes.bottom}>
            <div className={classes.left}>
              <SelectCustom value={scien_title[profile.scien_for_degree]} name="scien_for_degree"
                label="Отрасль" option={branch} submit={appStore.profile.onChangeSelect} />
            </div>
            <div className={classes.right}>
              <InputCustom value={profile.directions} name="directions" placeholder="Введите данные"
                label="Профильное направление, специфика экспертной позиции" />
            </div>
          </div>
        </div>
        <hr className={classes.lineShareSection} />
        <div className={classes.item}><h5 className={classes.titleSectionProfile}>Контактные данные</h5>
          <div className={classes.contacts}>
            <InputCustom value={profile.contacts.email} name="email" placeholder="Введите e-mail" label="E-mail" />
            <div className={classes.spanBefore}>
              <span className={classes.spanLabel}>@</span>
              <InputCustom value={profile.contacts.telegram} name="telegram" placeholder="ваш никнейм"
                label="Telegram" />
            </div>
            <div className={classes.spanBefore}>
              <span className={classes.spanLabel}>vk.com/</span>
              <InputCustom value={profile.contacts.vk} name="vk" placeholder="ваш ID" label="VK" />
            </div>
            <div className={classes.radio}>
              <label className={classes.label} htmlFor="">Предпочитаю связываться по</label>
              <div className={classes.radioItems}>
                <RadioInput name="preffered" value="e-mail" label="E-mail" />
                <RadioInput name="preffered" value="telegram" label="Telegram" />
                <RadioInput name="preffered" value="vk" label="VK" />
              </div>
            </div>
          </div>
        </div>
        <hr className={classes.lineShareSection} />
        <div className={classes.item}>
          <div>
            <h5 className={classes.titleSectionProfile}>Портфолио</h5>
          </div>
          <div className={classes.portfolio}>
            <InputCustom value={profile.interests} name="interests" placeholder="Введите Научные интересы"
              label="Научные интересы" />
            <InputCustom value={profile.portfolio} name="portfolio"
              placeholder="Введите ссылку на портфолио (https://example.com)" label="Портфолио"
              error={errorPortfolio} setError={setErrorPortfolio} />
            {errorPortfolio && <span className={classes.error}>Пожалуйста введите ссылку</span>}
            <InputLink name="projects" title="Реализованные проекты" />
          </div>
        </div>
        <hr className={classes.lineShareSection} />
        <div className={classes.item}>
          <div className={classes.achievements}>
            <h5 className={classes.titleSectionProfile}>Мои достижения</h5>
            <p className={classes.textProfile}>сертификаты, дипломы, участие в проектах</p>
          </div>
          <InputLink name="achieve" title="Ссылка на достижение" />
        </div>
        <hr className={classes.lineShareSection} />
        <div className={classes.item}>
          <h5 className={classes.titleSectionProfile}>Список моих публикаций</h5>
          <InputLink name="pubs" title="Ссылка на публикацию" /></div>
      </div>
      <hr className={classes.lineShareSection} />
      <div className={classes.footer}>
        <ButtonLarge type="submit" title="Сохранить изменения" icon={iconSave} callback={openModalSaveChanges} />
      </div>
      <SaveChangesModal
        visible={settingsVisible}
        onClose={closeModalSaveChanges}
        save={saveChanges}
      />
      <CloseProfileModal
        visible={closeModal}
        onClose={closeModalCloseProfile}
        save={closedProfile}
      />
      <OpenProfileModal
        visible={openModalOpenProfile}
        onClose={closeModalOpenProfile}
        save={saveOpenProfile}
      />
    </div>
  );
});
