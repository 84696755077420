import { createUseStyles } from 'react-jss';

const PREFIX = 'User';
export const useStyles = createUseStyles(
  {
    appUsers: {
      display: 'flex',
      justifyContent: 'space-between',
      padding: '10px 0',
      borderBottom: '1px solid #E4E7F2',
    },
    appUsersBlock: {
      display: 'flex',
    },
    appUsersImg: {
      width: '40px',
      height: '40px',
      borderRadius: '8px',
      margin: '0 0 0 19px',
    },
    appModalName: {
      width: '350px',
      color: '#3B4168',
      fontWeight: '400',
      fontSize: '16px',
      lineHeight: '20px',
      margin: '9px 0 0 50px',
    },
    dropImg: {
      height: '20px',
      cursor: 'pointer',
    },
    menu: {
      width: '132px',
      border: '1px solid #E4E7F2',
      backgroundColor: '#FFFFFF',
      borderRadius: '8px',
      marginLeft: '-150px',
      marginTop: '5px',
      display: 'flex',
      flexDirection: 'column',
      alignContent: 'center',
      justifyContent: 'center',
    },
    menuItem: {
      fontSize: '14px',
      fontWeight: '400',
      lineHeight: '20px',
      color: '#3B4168',
      marginLeft: '-24px',
      marginTop: '8px',
      cursor: 'pointer',
      listStyleType: 'none',

      '&:hover': {
        color: '#0071CE',
      },
      '&:last-child': {
        marginBottom: '8px',
      },
    },
    roleBlock: {
      width: '200px',
      marginTop: '9px',
      fontWeight: '400',
      fontSize: '16px',
      lineHeight: '20px',
    },
    dropBlock: {
      marginRight: '19px',
      marginTop: '9px',
    },
    owner: {
      color: '#15B012',
    },
    admin: {
      color: '#0071CE',
    },
    participant: {
      color: '#909EBB',
    },
  },
  { name: PREFIX },
);
