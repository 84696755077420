import { createUseStyles } from 'react-jss';

const PREFIX = 'BlueButton';
export const useStyles = createUseStyles(
  {
    button: {
      fontWeight: '500',
      fontSize: '16px',
      lineHeight: '20px',
      color: '#0071CE',
      border: '1px solid #0071CE',
      borderRadius: '4px',
      background: 'white',
      cursor: 'pointer',
      padding: '12px 24px',
    },
  },
  { name: PREFIX },
);
