export const convertType = (type: string | undefined): string => {
  if (type === 'private') {
    return 'Закрытый';
  }
  if (type === 'public') {
    return 'Открытый';
  }
  if (type === 'Закрытый') {
    return 'private';
  }
  return 'public';
};
