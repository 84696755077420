import { createUseStyles } from 'react-jss';
import arrowSelect from '../../../assets/icons/arrowSelect.svg';
import { theme } from '../../../components/theme';

const PREFIX = 'Search';
export const useStyles = createUseStyles({
  container: {
    display: 'grid',
    gap: '28px',
    width: '100%',
    padding: '48px 0',
  },
  searchInput: {
    fontFamily: theme.ibm,
    fontWeight: 400,
    lineHeight: '20px',
    letterSpacing: '0px',
    textAlign: 'left',
    outline: 'none',
    width: '100%',
    fontSize: '16px',
    background: '#F5FBFF',
    border: 'none',
    borderRadius: '0 8px 8px 0',
    '&::placeholder': {
      color: theme.textColorPlaceholder,
    },
  },
  selectInput: {
    paddingRight: '24px',
    fontFamily: theme.ibm,
    fontWeight: 400,
    lineHeight: '20px',
    letterSpacing: '0px',
    textAlign: 'left',
    color: '#3B4168',
    fontSize: '16px',
    border: 'none',
    background: '#F5FBFF',
    outline: 'none',
    appearance: 'none',
    backgroundImage: `url(${arrowSelect})`,
    backgroundRepeat: 'no-repeat',
    backgroundPositionX: 'right',
    backgroundPositionY: 'center',
  },
  inputBlock: {
    boxSizing: 'border-box',
    display: 'flex',
    alignItems: 'center',
    gap: '24px',
    height: '48px',
    borderRadius: '8px',
    background: 'rgba(153, 198, 235, 0.07)',
    color: '#3B4168',
    border: '1px solid #99C6EB',
    paddingLeft: '24px',
    '&::placeholder': {
      color: theme.textColorPlaceholder,
    },
  },
  vector: {
    width: '2px',
    height: '20px',
    background: '#E4E7F2',
  },
  searchIcon: {
    padding: '14px 24px 14px 0',
  },
  selects: {
    height: '36px',
    display: 'grid',
    gridTemplateColumns: 'repeat(5, 1fr)',
    gap: '24px',
  },
  selectSearch: {
    boxShadow: '0px 4px 15px rgba(0, 0, 0, 0.05)',
    borderRadius: '10px',
    border: 'none',
  },
  clearFiltersBtn: {
    height: 36,
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    color: '#6F7C98',
    fontSize: '14px',
    cursor: 'pointer',
    fontFamily: 'IBM Plex Sans',
    fontWeight: 400,
    lineheight: 20,
  },
}, { name: PREFIX });
