import { IDocumentsStore } from '../../models/stores/documents.store';
import { IPagination } from '../../models/stores/pagination.store';
import { IProfiles } from '../../models/stores/profile.store';
import { RequestsService } from '../../models/stores/requests.service';
import { IRootStore } from '../../models/stores/root.store';
import { ISearchStore } from '../../models/stores/search.store';
import { ISubsStore } from '../../models/stores/subscriber.store';
import { ISubscriptions } from '../../models/stores/subscription.store';
import { ConfigStore } from '../config/config.store';
import { DocumentsStore } from '../documents/documents.store';
import { GroupStore } from '../group/group.store';
import { PaginationStore } from '../pagination/pagination.store';
import { ProfileStore } from '../profiles/profile.store';
import { SearchStore } from '../search/search.store';
import { SubscriberStore } from '../subscriber/subscriber.store';
import { Subscriptions } from '../subscriptions/subscriptions';
import { UserStore } from '../user/userStore';

export class RootStore implements IRootStore {

  public readonly config: ConfigStore;
  public readonly requests: RequestsService;
  public readonly user: UserStore;
  public readonly group: GroupStore;
  public readonly document: IDocumentsStore;
  public readonly subscriptions: ISubscriptions;
  public readonly pagination: IPagination;
  public readonly profile: IProfiles;
  public readonly search: ISearchStore;
  public readonly subscriber: ISubsStore;

  constructor() {
    this.config = new ConfigStore();
    this.requests = new RequestsService(this);
    this.user = new UserStore(this);
    this.requests = new RequestsService(this);
    this.group = new GroupStore(this);
    this.document = new DocumentsStore(this);
    this.subscriptions = new Subscriptions(this);
    this.pagination = new PaginationStore(this);
    this.profile = new ProfileStore(this);
    this.search = new SearchStore(this);
    this.subscriber = new SubscriberStore(this);
  }

}
