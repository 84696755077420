import React from 'react';
import { Outlet } from 'react-router';
import { Sidebar } from '../../components/sidebar/Sidebar';
import { useStyles } from './adminLayout.styles';

export const AdminLayout = (): JSX.Element => {
  const classes = useStyles();
  return (
    <>
      <div className={classes.container}>
        <Sidebar />
        <div className={classes.pages}>
          <Outlet />
        </div>
      </div>
    </>
  );
};
