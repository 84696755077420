import React, { ChangeEvent, useContext, useState } from 'react';
import Dialog from 'rc-dialog';
import { BlueBtn } from '../../../components/blueBtn/BlueBtn';
import { DragAndDrop } from '../../../components/dragAndDrop/DragAndDrop';
import { TransparentBtn } from '../../../components/transparentBtn/TransparentBtn';
import { appStoreContext } from '../../../store/context/context.store';
import { Document } from '../../adminLayout/admin/documents/Document';
import { useStyles } from './documentAdd.styles';
import { IProps } from './types';
import 'rc-dialog/assets/index.css';

export const DocumentAddModal = ({ visible, onClose, setDocAddVisible }: IProps): JSX.Element => {
  const classes = useStyles();
  const appStore = useContext(appStoreContext);
  const [clicked, setClicked] = useState(false);
  const [imageName, setImageName] = useState('');
  const [image, setImage] = useState<string | Blob>();
  const searchString = new URLSearchParams(window.location.search);
  const groupController = appStore.user.isSuperAdmin ? 'admin' : 'groups';
  const groupId = searchString.get('groupId');
  const documents = appStore.document.documents;

  const handleClose = ():void => {
    setDocAddVisible && setDocAddVisible(false);
    setClicked(false);
    setImage('');
  };

  const handleChangeImage = (e: ChangeEvent<HTMLInputElement>): void => {
    e.preventDefault();
    if (e.target.files) {
      setImage(e.target.files[0]);
      setImageName(e.target.files[0].name);
    }
  };

  const handleRequest = (): void => {
    setClicked(true);
    const file = new FormData();
    if (image) {
      file.append('file', image);
      appStore.document.uploadDocument(file, groupId, imageName, groupController);
      handleClose();
    }
  };

  return (
    <Dialog
      title="Документы группы"
      className={classes.appModal}
      animation="zoom"
      maskAnimation="fade"
      visible={visible}
      onClose={handleClose}
    >
      <div className={classes.documentBlock}>
        {documents?.map((item) => (
          <Document item={item} className={classes.textHidden} />
        ))}
      </div>
      <div>
        <div className={classes.littleText}>Добавить документ (макс. 200мб)</div>
        <DragAndDrop
          image={image}
          clicked={clicked}
          setImage={setImage}
          imageName={imageName}
          setImageName={setImageName}
          handleChangeImage={handleChangeImage}
        />
      </div>
      <div className={classes.line}></div>
      <div className={classes.btnBlock}>
        <TransparentBtn
          className={classes.blockTransparentBtn}
          onClick={handleClose}
        >
          Отменить
        </TransparentBtn>
        <BlueBtn
          onClick={():void => handleRequest()}
        >
          Сохранить изменения
        </BlueBtn>
      </div>
    </Dialog>
  );
};
