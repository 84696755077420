import React, { useContext } from 'react';
import smile from '../../../../../assets/icons/smile.svg';
import { Avatar } from '../../../../../components/avatar/Avatar';
import { BlueBtn } from '../../../../../components/blueBtn/BlueBtn';
import { TransparentBtn } from '../../../../../components/transparentBtn/TransparentBtn';
import { appStoreContext } from '../../../../../store/context/context.store';
import { useStyles } from '../userApplications.styles';
import { IProps, IReturnData } from './types';

export const UserRequests = ({ setAppVisible }: IProps): JSX.Element => {
  const searchString = new URLSearchParams(window.location.search);
  const groupId = searchString.get('groupId');
  const classes = useStyles();
  const appStore = useContext(appStoreContext);
  const requested = appStore.group.requested;

  const bodyGetGroupsSubs = {
    page: 1,
    limit: 10,
    groupId,
  };

  const returnData = (sub_id: string | undefined, status: number): IReturnData => {
    const data = {
      groupId,
      subscriberId: sub_id,
      status,
    };
    return data;
  };

  const rejectRequest = async(sub_id: string | undefined): Promise<void> => {
    await appStore.subscriber.deleteSubs(returnData(sub_id, 2));
    appStore.group.getGroupsRequests(bodyGetGroupsSubs);
  };

  const acceptRequest = async(sub_id: string | undefined): Promise<void> => {
    await appStore.subscriber.deleteSubs(returnData(sub_id, 1));
    appStore.group.getGroupsRequests(bodyGetGroupsSubs);
    appStore.group.getGroupsSubscribers(bodyGetGroupsSubs);
  };

  return (
    <>
      {requested.length > 1
        ? <>
          <div className={classes.userRequestChild}>
            <div className={classes.userRequestChildImg}>
              <img src={smile} alt="" />
            </div>
            <div>
              <div className={classes.textRequest1}>Всего пользователей: {requested.length}</div>
              <div className={classes.textRequest2}>Хотят вступить в группу</div>
            </div>
          </div>
          <div className={classes.userRequestChild2}>
            <BlueBtn onClick={(): void => setAppVisible(true)}>
                Посмотреть заявки
            </BlueBtn>
          </div>
        </>
        : <>
          <div className={classes.userRequestChild}>
            {
              requested[0].avatar_sub
                ? <img
                  src={`${process.env.REACT_APP_S3_URL}${requested[0].avatar_sub}`}
                  alt="avatar"
                  className={classes.userRequestChildImg}
                />
                : <Avatar
                  name={requested[0].name_sub || 'Нет имени'}
                  className={classes.userRequestChildImg}
                />
            }
            <div>
              <div className={classes.textRequest1}>{requested[0].name_sub || 'Нет имени'}</div>
              <div className={classes.textRequest2}>Хочет вступить в группу</div>
            </div>
          </div>
          <div className={classes.userRequestChild2}>
            <TransparentBtn
              className={classes.requestBtn}
              onClick={(): Promise<void> => rejectRequest(requested[0].sub_id)}
            >
              Отклонить
            </TransparentBtn>
            <BlueBtn
              onClick={(): Promise<void> => acceptRequest(requested[0].sub_id)}
            >
              Принять
            </BlueBtn>
          </div>
        </>
      }
    </>
  );
};
