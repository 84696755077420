import { Outlet } from 'react-router-dom';
import { Sidebar } from '../sidebar/Sidebar';
import { useStyles } from './layout.styles';

export const Layout = (): JSX.Element => {
  const classes = useStyles();

  return (
    <>
      <div className={classes.container}>
        <Sidebar />
        <div className={classes.pages}>
          <Outlet />
        </div>
      </div>
    </>
  );
};
