import { createUseStyles } from 'react-jss';

const PREFIX = 'UserApplications';
export const useStyles = createUseStyles(
  {
    userRequest: {
      width: '100%',
      height: '96px',
      background: '#FBFDFF',
      border: '1px solid #E4E7F2',
      borderRadius: '16px',
      display: 'flex',
      justifyContent: 'space-around',
    },
    userRequestChild: {
      display: 'flex',
      margin: '10px 24px',
      height: '44px',
    },
    userRequestChildImg: {
      width: '53px',
      height: '53px',
      borderRadius: '16px',
      marginRight: '18px',
      background: '#0071CE',
      display: 'flex',
      justifyContent: 'center',
      alignItems: 'center',
    },
    textRequest1: {
      fontWeight: '600',
      fontSize: '18px',
      color: '#3B4168',
      lineHeight: '20px',
      marginTop: '4px',
    },
    textRequest2: {
      fontWeight: '400',
      fontSize: '14px',
      color: '#6F7C98',
      lineHeight: '16px',
      marginTop: '4px',
    },
    userRequestChild2: {
      margin: '26px 24px 0 0',
      display: 'flex',
      height: '44px',
    },
    '@media (max-width: 1460px)': {
      userRequestChild: {
        margin: '20px 0px',
      },
      userRequestChildImg: {
        marginLeft: '20px',
      },
    },
    requestBtn: {
      marginRight: '10px',
    },
  },
  { name: PREFIX },
);
