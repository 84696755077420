import { createUseStyles } from 'react-jss';
import { theme } from '../../components/theme';

const PREFIX = 'Subscriptions';
export const useStyles = createUseStyles({
  titleSubscription: {
    margin: 0,
    fontFamily: theme.mont,
    fontSize: '28px',
    fontWeight: 700,
    lineHeight: '36px',
    letterSpacing: '0px',
    textAlign: 'left',
    color: '#3B4168',
  },
  textSubscription: {
    margin: 0,
    fontFamily: theme.ibm,
    fontSize: '16px',
    fontWeight: 400,
    lineHeight: '20px',
    letterSpacing: '0px',
    textAlign: 'center',
    color: '#909EBB',
  },
  headerTop: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',
  },
  footer: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    gap: '32px',
  },
  footerTop: {
    display: 'flex',
    width: '100%',
    justifyContent: 'space-between',
    alignItems: 'center',
    fontFamily: theme.ibm,
    fontSize: '16px',
    fontWeight: 400,
    color: theme.textColorPlaceholder,
  },
  selectProfile: {
    border: 'none',
    color: theme.colorPrimary,
    width: '65px',
  },
  pagination: {
    display: 'flex',
    alignItems: 'center',
  },
}, { name: PREFIX });
