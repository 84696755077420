import React, { MouseEvent, useContext, useState } from 'react';
import classNames from 'classnames/bind';
import { observer } from 'mobx-react-lite';
import { select_search } from '../../shared/list.constants';
import { appStoreContext } from '../../store/context/context.store';
import { useStyles } from './selectSearcgInput.style';

export const SelectSearchInput = observer(({ optionsList, name }: { optionsList: string[], name: string }): JSX.Element => {
  const [showOptionList, setShowOptionList] = useState(false);
  const appStore = useContext(appStoreContext);
  const classes = useStyles();
  const cx = classNames.bind(classes);
  const selectedText = cx(classes.selectedText, {
    selectedTextActive: showOptionList,
  });
  const handleListDisplay = (): void => {
    setShowOptionList(!showOptionList);
  };
  const handleOptionClick = (e: MouseEvent): void => {
    // @ts-ignore
    appStore.search.onChangeSelectCustom(name, e.target.getAttribute('data-name'));
    setShowOptionList(false);
  };
  return (
    <div className={classes.customSelectContainer}>
      <div
        className={selectedText}
        onClick={handleListDisplay}
      >
        {select_search[appStore.search.searchData.selectSearch[0]]}
      </div>
      {showOptionList && (
        <div className={classes.containerOptions}>
          <ul className={classes.selectOptions}>
            {optionsList.map((option, i) => (
              <li
                className={classes.customSelectOption}
                data-name={option}
                key={i}
                onClick={handleOptionClick}
              >
                {option}
              </li>
            ))}
          </ul>
        </div>
      )}
    </div>

  );
});
