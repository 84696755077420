import { createUseStyles } from 'react-jss';

const PREFIX = 'Layout';
export const useStyles = createUseStyles({
  container: {
    display: 'flex',
    gap: '32px',
    background: '#F5F6F8',
    minHeight: '100vh',
    padding: '40px 40px 40px 40px',
  },
  pages: {
    width: '100%',
    minHeight: '100vh',
    backgroundColor: '#fff',
    borderRadius: '16px',
    padding: '40px 48px 40px 48px',
  },
}, { name: PREFIX });
