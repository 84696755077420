import { createUseStyles } from 'react-jss';

const PREFIX = 'UsersList';
export const useStyles = createUseStyles(
  {
    appModal: {
      width: '800px',
      height: '647px',
      fontFamily: 'Montserrat, sans-serif',

      '& .rc-dialog-title': {
        fontWeight: '600',
        fontSize: '24px',
        lineHeight: '28px',
        color: '#3B4168',
      },
      '& .rc-dialog-close': {
        width: '28px',
        height: '28px',
        color: '#909EBB',
        fontSize: '35px',
        fontWeight: '100',
        opacity: '1',
        fontStyle: 'normal',
        right: '41px',
        top: '27px',
      },
      '& .rc-dialog-content': {
        padding: '28px 40px 32px',
      },
      '& .rc-dialog-header': {
        padding: '0 0 24px 0',
      },
      '& .rc-dialog-body': {
        padding: '0',
        paddingTop: '32px',
      },
    },
    appUsers: {
      display: 'flex',
      justifyContent: 'space-between',
      padding: '10px 0',
      borderBottom: '1px solid #E4E7F2',
    },
    searchInput: {
      width: '96.3%',
      height: '48px',
      borderRadius: '8px',
      background: 'rgba(153, 198, 235, 0.07)',
      color: '#3B4168',
      border: '1px solid #99C6EB',
      paddingLeft: '24px',
      fontSize: '16px',
    },
    inputBlock: {
      position: 'relative',
    },
    searchIcon: {
      position: 'absolute',
      right: '25px',
      top: '18px',
    },
    appAvatar: {
      fontWeight: '400',
      fontSize: '14px',
      lineHeight: '20px',
      color: '#6F7C98',
      marginLeft: '19px',
    },
    appFio: {
      fontWeight: '400',
      fontSize: '14px',
      lineHeight: '20px',
      color: '#6F7C98',
      marginRight: '290px',
    },
    appRole: {
      fontWeight: '400',
      fontSize: '14px',
      lineHeight: '20px',
      color: '#6F7C98',
      marginRight: '228px',
    },
    paddingBody: {
      paddingTop: '40px',
    },
    btnBlock: {
      display: 'flex',
      justifyContent: 'flex-end',
      marginTop: '32px',
    },
    blockTransparentBtn: {
      marginRight: '16px',
    },
  },
  { name: PREFIX },
);
