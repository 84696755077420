import { createUseStyles } from 'react-jss';
import arrow from '../../assets/icons/arrowSidebar.svg';
import groupSidebar from '../../assets/icons/groupSidebar.svg';
import profileSidebar from '../../assets/icons/profileSidebar.svg';
import subscribeSidebar from '../../assets/icons/subsribeSidebar.svg';
import { theme } from '../theme';

const PREFIX = 'Sidebar';
export const useStyles = createUseStyles({
  container: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'flex-start',
    padding: '0px',
    width: '366px',
    height: '316px',
    background: '#FFFFFF',
    boxShadow: '0px 4px 12px rgba(0, 0, 0, 0.03)',
    borderRadius: '16px',
  },
  titleSidebar: {
    margin: 0,
    fontStyle: 'normal',
    fontWeight: 700,
    fontSize: '24px',
    lineHeight: '28px',
    color: '#FFFFFF',
    fontFamily: theme.mont,
    letterSpacing: '0px',
  },
  club: {
    background: theme.backgroundMain,
    width: '100%',
    borderTopLeftRadius: '16px',
    borderTopRightRadius: '16px',
    boxSizing: 'border-box',
    padding: '26px 24px 26px 32px',
  },
  item: {
    padding: '28px 24px 28px 32px',
    gap: '8px',
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    justifyContent: 'space-between',
  },
  link: {
    height: '80px',
    textDecoration: 'none',
    fontFamily: theme.ibm,
    fontSize: '16px',
    fontWeight: 500,
    lineHeight: '20px',
    letterSpacing: '0px',
    textAlign: 'left',
    color: theme.textColorH,
  },
  active: {
    padding: '28px 24px 28px 32px',
    gap: '8px',
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    justifyContent: 'space-between',
    background: '#F5FBFF',
  },
  activeSub: {
    boxSizing: 'border-box',
    borderBottomLeftRadius: '16px',
    borderBottomRightRadius: '16px',
    padding: '28px 24px 24px 32px',
    gap: '8px',
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    justifyContent: 'space-between',
    background: '#F5FBFF',
  },
  arrow: {
    content: `url(${arrow})`,
    width: '20px',
    height: '20px',
    padding: 0,
  },
  profileSidebar: {
    content: `url(${profileSidebar})`,
    width: '24px',
    height: '24px',
    padding: 0,
  },
  groupSidebar: {
    content: `url(${groupSidebar})`,
    width: '24px',
    height: '24px',
    padding: 0,
  },
  subscribeSidebar: {
    content: `url(${subscribeSidebar})`,
    width: '20px',
    height: '20px',
    padding: 0,
  },
  elem: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    padding: '0px',
    gap: '20px',
    width: '282px',
    height: '24px',
  },
}, { name: PREFIX });
