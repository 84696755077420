import React, { useContext } from 'react';
import { observer } from 'mobx-react-lite';
import { appStoreContext } from '../../store/context/context.store';
import { useStyles } from './modalradioInput.styles';
import { IProps } from './types';

export const ModalRadioInput = observer(({ value, label, name, checked }: IProps): JSX.Element => {
  const classes = useStyles();
  const appStore = useContext(appStoreContext);

  return (
    <div>
      <input
        value={value}
        type="radio"
        name={name}
        checked={checked}
        className={classes.checkAllUsers}
        onChange={appStore.profile.onChangeInput}
      />
      <label className={classes.bigText} htmlFor="label">{label}</label>
    </div>
  );
});
