import React from 'react';
import Dialog from 'rc-dialog';
import vectorImg from '../../../assets/icons/Vector.svg';
import { BlueBtn } from '../../../components/blueBtn/BlueBtn';
import { TransparentBtn } from '../../../components/transparentBtn/TransparentBtn';
import { useStyles } from './confirmModal.styles';
import { IProps } from './types';
import 'rc-dialog/assets/index.css';

export const ConfirmModal = ({ visible, onClose, appendData, groupName }: IProps): JSX.Element => {
  const classes = useStyles();

  return (
    <Dialog
      title={<img src={vectorImg} />}
      className={classes.confirmModal}
      animation="zoom"
      maskAnimation="fade"
      visible={visible}
      onClose={onClose}
    >
      <div className={classes.confirmContent}>
        <div className={classes.confirmContentBlock}>Сохранить изменения?</div>
        <p className={classes.confirmContentText}>
          Вы внесли изменения в настройки группы {groupName}, сохранить их?
        </p>
      </div>
      <div>
        <BlueBtn
          className={classes.confirmBtn}
          onClick={appendData}
        >
          Сохранить изменения
        </BlueBtn>
        <TransparentBtn
          className={classes.cancelBtn}
          onClick={onClose}>
          Отменить
        </TransparentBtn>
      </div>
    </Dialog>
  );
};
