import { createUseStyles } from 'react-jss';

const PREFIX = 'DragAndDrop';
export const useStyles = createUseStyles(
  {
    borderNotImage: {
      height: '100%',
      display: 'flex',
      justifyContent: 'center',
      alignItems: 'center',
      color: '#6F7C98',
      position: 'relative',
    },
    inputStyle: {
      cursor: 'pointer',
      position: 'absolute',
      top: '0',
      bottom: '0',
      left: '0',
      right: '0',
      opacity: '0',
    },
    textBorder: {
      display: 'flex',
      alignItems: 'center',
      fontSize: '16px',
      lineHeight: '1.25',
      color: '#6F7C98',
    },
    textChooseFile: {
      color: '#0071CE',
      cursor: 'pointer',
    },
    paperClip: {
      marginRight: '40px',
      width: '32px',
      height: '32px',
    },
    avatarDasher: {
      border: '0.2rem dashed #E4E7F2',
      height: '80px',
      marginTop: '4px',
    },
    avatarLabel: {
      height: '100%',
      display: 'block',
    },
    warningDocument: {
      border: '1px solid red',
    },
    warningNull: {
      color: 'red',
      position: 'absolute',
      lineHeight: '3px',
      fontSize: '12px',
      opacity: '0',
      transition: '0.2s',
      marginTop: '10px',
    },
    warning: {
      color: 'red',
      position: 'absolute',
      lineHeight: '3px',
      fontSize: '12px',
      marginTop: '10px',
      opacity: '1',
      transition: '0.2s',
    },
    name: {
      whiteSpace: 'nowrap',
      overflow: 'hidden',
      textOverflow: 'ellipsis',
      maxWidth: '80%',
    },
  },
  { name: PREFIX },
);
