import { createUseStyles } from 'react-jss';
import arrowSelect from '../../assets/icons/arrowSelect.svg';

const PREFIX = 'MyCustomSelect';

export const useStyles = createUseStyles(
  {
    main: {
      fontFamily: 'sans-serif',
      background: '#FFFFFF',
      borderRadius: '4px',
      height: '100%',
      cursor: 'pointer',
      marginTop: '1px',
    },
    dropDownContainer: {
      width: '10.5em',
      margin: '0 auto',
      fontWeight: '100',
      fontSize: '1.1rem',
    },
    dropDownHeader: {
      color: '#3B4168',
      backgroundImage: `url(${arrowSelect})`,
      backgroundRepeat: 'no-repeat',
      backgroundPositionX: '98%',
      backgroundPositionY: 'center',
      background: '#FFFFFF',
      border: '1px solid #E4E7F2',
      height: '40px',
      paddingLeft: '1em',
      borderRadius: '4px',
      display: 'flex',
      alignItems: 'center',
    },
    dropDownListContainer: {
      position: 'relative',
      width: '100%',
      marginTop: '-16px',
    },
    dropDownList: {
      listStyle: 'none',
      width: '100%',
      maxHeight: '165px',
      overflowY: 'scroll',
      zIndex: '3',
      position: 'absolute',
      paddingLeft: '1em',
      boxSizing: 'border-box',
      background: '#FFFFFF',
      boxShadow: '0px 4px 15px rgba(0, 0, 0, 0.05) !important',
      borderRadius: '4px !important',
      '&::-webkit-scrollbar': {
        display: 'none !important',
      },
    },
    listItem: {
      height: '100%',
      display: 'flex',
      alignItems: 'center',
      padding: '8px 0',
      marginLeft: '-1em',
      color: '#3B4168',
      fontWeight: '400',
      fontSize: '16px',
      lineHeight: '20px',
      '&:hover': {
        background: '#F5FBFF',
      },
      '& div': {
        marginLeft: '1em',
      },
    },
    error: {
      border: '1px solid red',
    },
    textWidth: {
      width: '480px',
    },
    textWrapper: {
      width: '202px',
      overflowX: 'hidden',
    },
  },
  { name: PREFIX },
);
