import { createUseStyles } from 'react-jss';

const PREFIX = 'Document';
export const useStyles = createUseStyles(
  {
    document: {
      width: '100%',
      padding: '13.67px 0',
      background: '#F5FBFF',
      borderRadius: '8px',
      color: '#909EBB',
      fontWeight: '400',
      fontSize: '16px',
      lineHeight: '20px',
      display: 'flex',
      justifyContent: 'space-between',
      marginTop: '12px',
    },
    documentChild: {
      display: 'flex',
      width: '400px',
      marginLeft: '22.5px',
    },
    nameBlock: {
      maxWidth: '100%',
    },
    name: {
      whiteSpace: 'nowrap',
      overflow: 'hidden',
      textOverflow: 'ellipsis',
    },
    documentChildImg: {
      width: '20px',
      height: '20px',
      marginRight: '18.5px',
    },
    documentChild2: {
      display: 'flex',
      justifyContent: 'flex-end',
      marginRight: '13.67px',
      width: '20%',
    },
    documentChild2Img: {
      marginLeft: '22.5px',
      cursor: 'pointer',
    },
    aTag: {
      marginBottom: '-5px',
    },
  },
  { name: PREFIX },
);
