import { createUseStyles } from 'react-jss';
import { theme } from '../../components/theme';

const PREFIX = 'Profile';
export const useStyles = createUseStyles({
  label: {
    fontFamily: 'IBM Plex Sans',
    fontSize: '14px',
    fontWeight: 400,
    lineHeight: '20px',
    letterSpacing: '0px',
    textAlign: 'left',
    color: '#6F7C98',
    display: 'flex',
    flexDirection: 'column',
  },
  userLink: {
    margin: 0,
    width: 'fit-content',
    textDecoration: 'none',
    color: '#005EAC',
    fontFamily: 'IBM Plex Sans',
    fontSize: '16px',
    fontWeight: 400,
    lineHeight: '20px',
    letterSpacing: '0px',
    textAlign: 'left',
  },
  textUser: {
    margin: 0,
    fontFamily: 'IBM Plex Sans',
    fontSize: '16px',
    fontWeight: 400,
    lineHeight: '20px',
    letterSpacing: '0px',
    textAlign: 'left',
    color: '#3B4168',
    wordWrap: 'break-word',
  },
  titleSection: {
    margin: 0,
    fontFamily: theme.ibm,
    fontSize: '16px',
    fontWeight: 500,
    lineHeight: '20px',
    letterSpacing: '0px',
    textAlign: 'left',
    color: '#3B4168',
  },
  lineShareSection: {
    background: '#E4E7F2',
    height: '1px',
    border: '0px',
    width: '100%',
    margin: 0,
  },
  breadCrumb: {
    display: 'flex',
    gap: '16px',
    alignItems: 'center',
    marginBottom: '24px',
    color: '#909EBB',
    fontFamily: theme.ibm,
  },
  breadCrumbLine: {
    color: '#E4E7F2',
  },
  containerA: {
    display: 'flex',
    flexDirection: 'column',
    gap: '12px',
  },
  cap: {
    marginTop: '32px',
    display: 'flex',
    gap: '32px',
  },
  avatar: {
    width: '152px',
    height: '152px',
    borderRadius: '100px',
  },
  img: {
    width: '152px',
    height: '152px',
    borderRadius: '100px',
  },
  data: {
    width: '100%',
  },
  header: {
    width: '100%',
    display: 'flex',
    justifyContent: 'space-between',
  },
  openProfile: {
    display: 'flex',
    gap: '16px',
    fontFamily: theme.ibm,
    fontSize: '16px',
    fontWeight: 400,
    lineHeight: '20px',
    letterSpacing: '0px',
    textAlign: 'left',
  },
  nameUser: {
    width: '388px',
    fontFamily: 'Montserrat',
    fontSize: '28px',
    fontWeight: 700,
    lineHeight: '36px',
    letterSpacing: '0px',
    textAlign: 'left',
    color: theme.textColorH,
  },
  buttons: {
    display: 'flex',
    gap: '24px',
    '& button': {
      height: '44px',
      marginBottom: '20px',
    },
  },
  id: {
    fontFamily: 'IBM Plex Sans',
    fontSize: '14px',
    fontWeight: 400,
    lineHeight: '20px',
    letterSpacing: '0px',
    textAlign: 'left',
    padding: '6px 20px 6px 20px',
    backgroundColor: '#F5FBFF',
    width: 'fit-content',
    borderRadius: '12px',
  },
  body: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'flex-end',
    padding: '0px 0px 0px 184px',
    gap: '32px',
  },
  item: {
    width: '100%',
    display: 'flex',
    flexDirection: 'column',
    gap: '24px',
  },
  subTitle: {
    margin: 0,
    color: theme.textColorLabelAndP,
    fontFamily: theme.ibm,
    fontSize: '14px',
    fontWeight: 400,
    lineHeight: '20px',
    letterSpacing: '0px',
    textAlign: 'left',
  },
  radioItems: {
    display: 'flex',
    justifyContent: 'space-between',
  },
  contacts: {
    display: 'grid',
    gridTemplateColumns: '1fr 1fr 1fr',
    gap: '24px',
  },
  achievements: {
    display: 'grid',
    gap: '24px',
  },
  portfolio: {
    display: 'flex',
    flexDirection: 'column',
  },
  top: {
    display: 'grid',
    gridTemplateColumns: '1fr 1fr 1fr',
    gap: '32px',
  },
  bottom: {
    display: 'grid',
    gridTemplateColumns: '1fr 2fr 0fr',
    gap: '32px',
  },
  left: { gridColumn: 'span 1/2' },
  right: { gridColumn: 'span 2/4' },
  containerMinBlock: {
    display: 'flex',
    flexDirection: 'column',
    gap: '12px',
    width: '100%',
  },
  containerPortfolio: {
    display: 'flex',
    flexDirection: 'column',
  },
  interest: {
    marginBottom: '24px',
  },
  lineOne: {
    width: '600px',
  },
}, { name: PREFIX });
