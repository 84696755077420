import { createUseStyles } from 'react-jss';
import { theme } from '../theme';

const PREFIX = 'InputCustom';
export const useStyles = createUseStyles({
  container: {
    display: 'flex',
    flexDirection: 'column',
    gap: '4px',
    width: '100%',
  },
  iconContainer: {
    display: 'flex',
    alignItems: 'center',
  },
  input: {
    outline: 'none',
    boxSizing: 'border-box',
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    padding: '12px 16px',
    width: '100%',
    height: '40px',
    background: '#FFFFFF',
    color: '#3B4168',
    border: '1px solid #E4E7F2',
    borderRadius: '4px',
    fontFamily: theme.ibm,
    fontSize: '16px',
    fontWeight: 400,
    lineHeight: '20px',
    letterSpacing: '0px',
    textAlign: 'left',
    textOverflow: 'ellipsis',
    '&::placeholder': {
      color: theme.textColorPlaceholder,
    },
  },
  inputTelegram: {
    paddingLeft: '30px',
  },
  inputVk: {
    paddingLeft: '70px',
  },
  label: {
    fontFamily: theme.ibm,
    fontSize: '14px',
    fontWeight: 400,
    lineHeight: '20px',
    letterSpacing: '0px',
    textAlign: 'left',
    color: theme.textColorLabelAndP,
  },
  error: {
    borderColor: '#EB5757',
  },
  marg: {
    marginRight: '64px',
  },
}, { name: PREFIX });
