import { useContext, useEffect, useRef } from 'react';
import { useNavigate } from 'react-router';
import { observer } from 'mobx-react';
import { Avatar } from '../../../components/avatar/Avatar';
import { useDebounce } from '../../../hooks/useDebounce';
import { academic_degree, academic_title, scien_title } from '../../../shared/list.constants';
import { appStoreContext } from '../../../store/context/context.store';
import { Search } from '../search/Search';
import { useStyles } from './table.styles';

const usePrevious = <T extends unknown>(value: T): T | undefined => {
  const ref = useRef<T>();
  useEffect(() => {
    ref.current = value;
  });
  return ref.current;
};

export const TableCustom = observer(({ mySub }: { mySub: boolean }): JSX.Element => {
  const prevMySub = usePrevious({ mySub });
  const appStore = useContext(appStoreContext);
  const profileController = appStore.user.isSuperAdmin ? 'admin' : 'profiles';
  const { pagination } = appStore.pagination;
  const valueInput = useDebounce(appStore.search.searchData.inputSearch, 800);
  const navigate = useNavigate();
  const navigateOnClickTr = (id: string): void => {
    appStore.user.getProfile(id).then((res) => {
      navigate(`user/${id}`);
    });
  };
  const classes = useStyles();
  useEffect(() => {
    if (mySub) {
      appStore.user.getProfilesMeSubscribed({
        limit: pagination.pageSize,
        page: pagination.current,
        name: appStore.search.searchData.inputSearch,
        degree: appStore.search.searchData.filter.academic_degree,
        title: appStore.search.searchData.filter.academic_title,
        scienForDegree: appStore.search.searchData.filter.scien_for_degree,
      });
    }
    else if (!mySub) {
      appStore.user.filterProfile({ limit: pagination.pageSize, page: pagination.current, filter: appStore.search.searchData.filter }, profileController);
    }
  }, [
    mySub,
    valueInput,
    pagination.pageSize,
    appStore.search.searchData.filter.academic_degree,
    appStore.search.searchData.filter.academic_title,
    appStore.search.searchData.filter.scien_for_degree,
    pagination.current,
  ]);

  useEffect(() => {
    if (prevMySub?.mySub !== mySub && prevMySub?.mySub !== undefined) {
      appStore.search.clearFilters();
    }
  }, [mySub]);

  return (<>
    <Search />
    <div className={classes.container}>
      <table className={classes.table}>
        <thead>
          <tr className={classes.row}>
            <th className={classes.tableCapCell}>Аватар</th>
            <th className={classes.tableCapCell}>ФИО</th>
            <th className={classes.tableCapCell}>Отрасль</th>
            <th className={classes.tableCapCell}>Ученая степень</th>
            <th className={classes.tableCapCell}>Ученое звание</th>
            <th className={classes.six}></th>
          </tr>
        </thead>
        <tbody>
          {appStore.subscriptions.subscription.tableDate && appStore.subscriptions.subscription.tableDate.map((el, i) => <tr key={i} className={classes.tableRow}
            onClick={(): void => navigateOnClickTr(el._id)}>
            <td className={classes.tableBodyCell}>
              {
                el.avatar ? (
                  <img className={classes.image} src={appStore.config.baseS3URL + el.avatar} alt="" />
                ) : (
                  <Avatar name={el.name} />
                )
              }
            </td>
            <td className={classes.tableBodyCell}>{el.name}</td>
            <td className={classes.tableBodyCell}>{el.scien_for_degree > 0 && scien_title[el.scien_for_degree]}</td>
            <td className={classes.tableBodyCell}>{el.academic_degree > 0 && academic_degree[el.academic_degree]}</td>
            <td className={classes.tableBodyCell}>{el.academic_title > 0 && academic_title[el.academic_title]}</td>
            <td className={classes.tableBodyCell}></td>
          </tr>)}
        </tbody>
      </table>
    </div>
  </>
  );
});
