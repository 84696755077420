import { createUseStyles } from 'react-jss';
import { theme } from '../../../components/theme';

const PREFIX = 'UserAddModal';
export const useStyles = createUseStyles(
  {
    appModal: {
      width: '440px',
      height: '364px',
      fontFamily: 'Montserrat, sans-serif',

      '& .rc-dialog-title': {
        fontWeight: '600',
        fontSize: '24px',
        lineHeight: '28px',
        color: '#3B4168',
      },
      '& .rc-dialog-close': {
        width: '28px',
        height: '28px',
        color: '#909EBB',
        fontSize: '35px',
        fontWeight: '100',
        opacity: '1',
        fontStyle: 'normal',
        right: '41px',
        top: '27px',
      },
      '& .rc-dialog-content': {
        padding: '28px 40px 32px',

        '& img': {
          marginTop: '5px',
        },
      },
      '& .rc-dialog-header': {
        padding: '0 0 12px 0',
        borderBottom: 'none',
      },
      '& .rc-dialog-body': {
        padding: '0',
        paddingTop: '32px',
      },
    },
    userAddContent: {
      marginBottom: '40px',
    },
    userAddContentBlock: {
      fontWeight: '600',
      fontSize: '24px',
      lineHeight: '28px',
      color: '#3B4168',
    },
    userAddContentText: {
      fontWeight: '400',
      fontSize: '16px',
      lineHeight: '20px',
      color: '#6F7C98',
    },
    addUserInp: {
      width: '98%',
      height: '40px',
      border: '1px solid #E4E7F2',
      borderRadius: '4px',
      margin: '4px 0 16px 0',
      color: '#3B4168',
      '&::placeholder': {
        color: '#909EBB',
        fontFamily: theme.ibm,
        fontSize: '16px',
        fontWeight: '400',
        lineHeight: '20px',
        textAlign: 'center',
      },
    },
    addUserInpActive: {
      borderColor: '#99C6EB',
    },
    addUserBtn: {
      width: '100%',
    },
  },
  { name: PREFIX },
);
