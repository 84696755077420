import React, { ChangeEvent, useContext, useEffect, useRef, useState } from 'react';
import classNames from 'classnames/bind';
import Dialog from 'rc-dialog';
import Input from 'rc-input';
import { BlueBtn } from '../../../components/blueBtn/BlueBtn';
import { DragAndDrop } from '../../../components/dragAndDrop/DragAndDrop';
import { InstrumentInput } from '../../../components/instrumentInput/InstrumentInput';
import { ModalRadioInput } from '../../../components/modalRadioInput/ModalRadioInput';
import { MyCustomSelect } from '../../../components/MySelect/MyCustomSelect';
import { TransparentBtn } from '../../../components/transparentBtn/TransparentBtn';
import { appStoreContext } from '../../../store/context/context.store';
import { useStyles } from './groupCreate.styles';
import { subjects } from './mockValues';
import { IProps } from './types';
import 'rc-dialog/assets/index.css';

export const GroupCreate = ({ visible, setCreateVisible }: IProps): JSX.Element => {
  const classes = useStyles();
  const inputRef = useRef<HTMLInputElement>(null);
  const [image, setImage] = useState<string | Blob>();
  const [type, setType] = useState('public');
  const [name, setName] = useState<string>('');
  const [imageName, setImageName] = useState('');
  const [description, setDescription] = useState<string | undefined>('');
  const [selectedOption, setSelectedOption] = useState<string>('');
  const [subject, setSubject] = useState<number>(0);
  const [clicked, setClicked] = useState<boolean>(false);
  const appStore = useContext(appStoreContext);
  const isSuperAdmin = appStore.user.isSuperAdmin;
  const cx = classNames.bind(classes);

  const warningName = cx({
    warningName: name === '' && clicked,
  });

  const handleChangeImage = (e: ChangeEvent<HTMLInputElement>):void => {
    e.preventDefault();
    if (e.target.files && e.target.files[0]) {
      setImageName(e.target.files[0].name);
      const FReader = new FileReader();
      FReader.onload = (event: ProgressEvent<FileReader>):void => {
        setImage(String(event?.target?.result));
      };
      FReader.readAsDataURL(e.target.files[0]);
    }
  };

  const appendData = (): void => {
    const data = {
      name,
      desc: description,
      subject: String(subject),
      type,
      avatar: image,
    };
    appStore.group.createGroup(data);
  };

  const handleClean = (): void => {
    setCreateVisible && setCreateVisible(false);
    cleanFields();
    setClicked(false);
  };

  const cleanFields = ():void => {
    setImage('');
    setType('public');
    setName('');
    setDescription('');
    setSubject(0);
    setSelectedOption('');
  };

  const handleSubmit = (): void => {
    setClicked(true);
    if (name !== '' && subject !== 0) {
      appendData();
      cleanFields();
      setCreateVisible && setCreateVisible(false);
      setClicked(false);
    }
  };

  return (
    <Dialog
      title="Создание группы"
      className={classes.appModal}
      animation="zoom"
      maskAnimation="fade"
      visible={visible}
      onClose={handleClean}
    >
      <div>
        <div className={classes.littleText}>Тип группы</div>
        <div className={classes.radioItems}>
          <div onClick={(): void => setType('public')}>
            <ModalRadioInput
              name="preffered"
              value="public"
              checked={type === 'public'}
              label="Открытая"
            />
          </div>
          {isSuperAdmin &&
            <div
              className={classes.checkAdmin}
              onClick={(): void => setType('private')}
            >
              <ModalRadioInput
                name="preffered"
                value="private"
                checked={type === 'private'}
                label="Закрытая"
              />
            </div>
          }
        </div>
      </div>
      <div>
        <div className={classes.littleText}> Название группы </div>
        <Input
          value={name}
          placeholder="Название группы"
          className={`${classes.inpNameGroup} ${warningName}`}
          onChange={(event): void => setName(event.target.value)}
        />
      </div>
      {(clicked && name === '') && <div className={classes.warning}>Вы не заполнили поле</div>}
      <div>
        <InstrumentInput description={description} setDescription={setDescription} />
      </div>
      <div>
        <div className={classes.littleText}>Отрасль</div>
        <MyCustomSelect
          option={subjects.slice(1)}
          setSubject={setSubject}
          clicked={clicked}
          selectWidth={classes.selectWidth}
          selectedOption={selectedOption}
          setSelectedOption={setSelectedOption}
        />
      </div>
      {(clicked && subject === 0) && <div className={classes.industryWarning}>Вы не заполнили поле</div>}
      <div>
        <div className={classes.littleText}>Аватар группы</div>
        <DragAndDrop
          image={image}
          handleChangeImage={handleChangeImage}
          setImage={setImage}
          imageName={imageName}
          setImageName={setImageName}
        />
      </div>
      <div className={classes.line}></div>
      {image && (
        <div className={classes.addButton}>
          <input
            ref={inputRef}
            id="input"
            className={classes.inputStyle}
            type="file"
            accept=".png,.jpeg,.jpg"
            onChange={(e): void => handleChangeImage(e)}
          />
          <TransparentBtn
            className={classes.blockTransparentBtn}
            onClick={(): void => inputRef.current?.click()}
          >
            Выбрать другой файл
          </TransparentBtn>
        </div>
      )}
      <div className={classes.btnBlock}>
        <TransparentBtn
          className={classes.blockTransparentBtn}
          onClick={handleClean}
        >
          Отменить
        </TransparentBtn>
        <BlueBtn onClick={handleSubmit}>Создать группу</BlueBtn>
      </div>
    </Dialog>
  );
};
