import React from 'react';
import { useStyles } from './toolbar.styles';

export const modules = {
  toolbar: {
    container: '#toolbar',
  },
  history: {
    delay: 500,
    maxStack: 100,
    userOnly: true,
  },
};

export const formats = [
  'header',
  'font',
  'size',
  'bold',
  'italic',
  'underline',
  'align',
  'script',
  'list',
  'bullet',
  'strike',
];

export const QuillToolbar = (): JSX.Element => {
  const classes = useStyles();

  return (
    <div id="toolbar" className={classes.toolbar}>
      <span className="ql-formats">
        <button className="ql-list" value="ordered" />
        <button className="ql-list" value="bullet" />
        <button className="ql-align" value=""></button>
        <button className="ql-align" value="center"></button>
        <button className="ql-align" value="right" />
        <button className="ql-strike" />
        <button className="ql-bold" />
        <button className="ql-italic" />
        <button className="ql-underline" />
      </span>
    </div>
  );
};
