import React, { useState } from 'react';
import classNames from 'classnames/bind';
import PaperClipIcon from '../../assets/icons/PaperClipIcon.svg';
import { useStyles } from './dragAndDrop.styles';
import { IDragAndDrop } from './types';

export const DragAndDrop = ({ image, handleChangeImage, setImage, clicked, setImageName, imageName }: IDragAndDrop): JSX.Element => {
  const classes = useStyles();
  const [drag, setDrag] = useState(false);
  const cx = classNames.bind(classes);

  const documentWarning = cx({
    warningDocument: image === '' && clicked,
  });

  const warningOpacity = cx({
    warningNull: !clicked || image !== '',
    warning: image === '' && clicked,
  });

  const onDragStartHandler = (e: React.DragEvent<HTMLDivElement>):void => {
    e.preventDefault();
    setDrag(true);
  };

  const onDragLeaveHandler = (e: React.DragEvent<HTMLDivElement>):void => {
    e.preventDefault();
    setDrag(false);
  };

  const onDropHandler = (e: React.DragEvent<HTMLDivElement>):void => {
    e.preventDefault();
    const FReader = new FileReader();
    FReader.onload = (event: ProgressEvent<FileReader>):void => {
      setImage(String(event?.target?.result));
    };
    FReader.readAsDataURL(e.dataTransfer.files[0]);
    setImageName && setImageName(e.dataTransfer.files[0].name);
  };

  const returnImageName = (): string => {
    if (imageName) {
      return imageName;
    }
    if (typeof image === 'string') {
      return image;
    }
    return 'Файл прикреплен';
  };

  return (
    <label className={classes.avatarLabel}>
      <div className={`${classes.avatarDasher} ${documentWarning}`}>
        { image ? (
          <div className={classes.borderNotImage}>
            <input
              className={classes.inputStyle}
              type="file"
              accept=".png,.jpeg,.jpg"
              onChange={handleChangeImage}
            />
            <img className={classes.paperClip} src={PaperClipIcon} />
            <div className={classes.name}
            >{returnImageName()}</div>
          </div>
        ) : (
          <div className={classes.borderNotImage}>
            <input
              className={classes.inputStyle}
              type="file"
              accept=".png,.jpeg,.jpg"
              onChange={handleChangeImage}
            />
            <div className={classes.textBorder}>
              <img className={classes.paperClip} src={PaperClipIcon} />
              {drag
                ? <div
                  onDragStart={onDragStartHandler}
                  onDragLeave={onDragLeaveHandler}
                  onDragOver={onDragStartHandler}
                  onDrop={onDropHandler}
                >Отпустите файлы, чтобы загрузить их</div>
                : (
                  <div
                    onDragStart={onDragStartHandler}
                    onDragLeave={onDragLeaveHandler}
                    onDragOver={onDragStartHandler}
                  >
                    Переместите файлы сюда или{' '}
                    <span className={classes.textChooseFile}>выберите файлы</span>
                  </div>
                )
              }
            </div>
          </div>
        )
        }
      </div>
      <div className={`${warningOpacity}`}>
        Вы не добавили файл
      </div>
    </label>
  );
};
