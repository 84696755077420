import React from 'react';
import { Link } from 'react-router-dom';
import avatar from '../../../assets/icons/Avatar.svg';
import lockIcon from '../../../assets/icons/lock.svg';
import { IGroup } from '../../../models/stores/group.store';
import { sortText } from '../../../utils/sort/sort';
import { useStyles } from './card.styles';
import { IGroupList } from './types';

export const Card = ({ groupList, returnCountGroup }: IGroupList): JSX.Element => {
  const classes = useStyles();

  const sorted = (item: IGroup): string => sortText(
    {
      itemCount: item.count,
      countGroup: returnCountGroup && returnCountGroup(item._id || ''),
    },
  );

  return (
    <div className={classes.cardBlock}>
      {groupList ? groupList.map((item: IGroup, index) => (
        <Link to={`/group?groupId=${item._id}`} className={classes.link} key={index + 1}>
          <div
            key={item._id}
            className={classes.card}
          >
            <div>
              <img src={item.avatar ? item.avatar : avatar} alt="" className={classes.cardImg} />
            </div>
            <div className={classes.textBlock}>
              <div className={classes.statusBlock}>
                {item.type === 'Закрытый' && (
                  <img src={lockIcon} alt="" className={classes.lockIcon} />
                )}
                <div className={classes.cardStatus}>{item.type}</div>
              </div>
              <div className={classes.cardName}>
                <div className={classes.name}>
                  {item.name}
                </div>
              </div>
              <div className={classes.cardUsers}>
                {sorted(item)}
              </div>
            </div>
          </div>
        </Link>
      ))
        : <div>Нет таких групп</div>
      }
    </div>
  );
};
