import { ChangeEvent } from 'react';
import { makeAutoObservable, observable } from 'mobx';
import { IRootStore } from '../../models/stores/root.store';
import { ISearch, ISearchStore } from '../../models/stores/search.store';
import {
  academic_degree,
  academic_degree_back, academic_title,
  academic_title_back,
  scien_title_back,
  select_search_back,
} from '../../shared/list.constants';

export class SearchStore implements ISearchStore {

  public readonly rootStore: IRootStore;
  @observable searchData: ISearch = {
    inputSearch: '',
    selectSearch: [0],
    groupSearch: '',
    filter: {
      edu: '',
      edu_ind: '',
      work: '',
      full_name_low: '',
      academic_degree: undefined,
      academic_title: undefined,
      scien_for_degree: undefined,
    },
  };

  constructor(rootStore: IRootStore) {
    this.rootStore = rootStore;

    makeAutoObservable(this, {
      rootStore: false,
    });
    this.updateInputSearch = this.updateInputSearch.bind(this);
    this.onChangeSelect = this.onChangeSelect.bind(this);
    this.clearFilters = this.clearFilters.bind(this);
    this.onChangeSelectCustom = this.onChangeSelectCustom.bind(this);
  }

  updateInputSearch(value: string, name: string): void {
    if (name === 'inputSearch') {
      if (this.searchData.selectSearch) {
        const filterValue = +this.searchData.selectSearch[0] === 0 ? 'edu' : +this.searchData.selectSearch[0] === 1 ? 'work' : 'full_name_low';
        // @ts-ignore
        this.searchData.filter[filterValue] = value;
      }
    }
    // @ts-ignore
    this.searchData[name] = value;
  }

  onChangeSelect(e: ChangeEvent<HTMLSelectElement>): void {
    const name = e.target.name;
    // eslint-disable-next-line init-declarations
    let value;
    if (name === 'academic_title') {
      value = academic_title_back[e.target.value];
    }
    else
    if (name === 'academic_degree') {
      value = academic_degree_back[e.target.value];
    }
    else if (name === 'scien_for_degree') {
      value = scien_title_back[e.target.value];
    }
    else if (name === 'selectSearch') {
      this.clearFilters();
      value = select_search_back[e.target.value];
      // @ts-ignore
      this.searchData[name] = [+value];
      return;
    }
    // @ts-ignore
    this.searchData.filter[name] = [+value];
  }
  onChangeSelectCustom(name: string, value: string): void {
    if (name === 'academic_title') {
      if (+academic_title_back[value] === 0) {
        this.searchData.filter.academic_title = undefined;
        return;
      }
      // @ts-ignore
      this.searchData.filter.academic_title = [+academic_title_back[value]];
      return;
    }
    if (name === 'academic_degree') {
      if (+academic_degree_back[value] === 0) {
        this.searchData.filter.academic_degree = undefined;
        return;
      }
      // @ts-ignore
      this.searchData.filter.academic_degree = [+academic_degree_back[value]];
      return;
    }
    if (name === 'scien_for_degree') {
      if (+scien_title_back[value] === 0) {
        this.searchData.filter.scien_for_degree = undefined;
        return;
      }
      // @ts-ignore
      this.searchData.filter.scien_for_degree = [+scien_title_back[value]];
      return;
    }
    this.searchData.selectSearch = select_search_back[value];
    this.clearFilters();
  }

  clearFilters(): void {
    this.searchData.filter = {
      edu: '',
      edu_ind: '',
      work: '',
      full_name_low: '',
      academic_degree: undefined,
      academic_title: undefined,
      scien_for_degree: undefined,
    };
    this.searchData.inputSearch = '';
  }

}
