import { Footer } from '@unione-pro/unione.commons.webpack';
import oracle from '../../assets/backgrounds/groupOracle.svg';
import triangle from '../../assets/backgrounds/groupTriangle.svg';
import { Body } from './body/Body';
import { useStyles } from './main.styles';

export const Main = (): JSX.Element => {
  const classes = useStyles();

  return (
    <div className={classes.col}>
      <div className={classes.container}>
        <img className={classes.triangle} src={triangle} alt="" />
        <Body />
        <div className={classes.footer}>
          <Footer />
        </div>
        <img className={classes.oracle} src={oracle} alt="" />
      </div>
    </div>
  );
};
