import React, { useContext } from 'react';
import Dialog from 'rc-dialog';
import { Avatar } from '../../../components/avatar/Avatar';
import { TransparentBtn } from '../../../components/transparentBtn/TransparentBtn';
import { appStoreContext } from '../../../store/context/context.store';
import { useStyles } from './applications.styles';
import { IProps, IReturnData } from './types';
import 'rc-dialog/assets/index.css';

export const ApplicationsModal = ({ visible, onClose, setAppVisible }: IProps): JSX.Element => {
  const searchString = new URLSearchParams(window.location.search);
  const groupId = searchString.get('groupId');
  const appStore = useContext(appStoreContext);
  const requested = appStore.group.requested;
  const classes = useStyles();
  const bodyGetGroupsSubs = {
    page: 1,
    limit: 10,
    groupId,
  };

  const returnData = (sub_id: string | undefined, status: number): IReturnData => {
    const data = {
      groupId,
      subscriberId: sub_id,
      status,
    };
    return data;
  };

  const rejectRequest = async(sub_id: string | undefined): Promise<void> => {
    await appStore.subscriber.deleteSubs(returnData(sub_id, 2));
    appStore.group.getGroupsRequests(bodyGetGroupsSubs);
    setAppVisible(false);
  };

  const acceptRequest = async(sub_id: string | undefined): Promise<void> => {
    await appStore.subscriber.deleteSubs(returnData(sub_id, 1));
    appStore.group.getGroupsRequests(bodyGetGroupsSubs);
    appStore.group.getGroupsSubscribers(bodyGetGroupsSubs);
    setAppVisible(false);
  };

  return (
    <Dialog
      title="Заявки на вступление"
      visible={visible}
      className={classes.appModal}
      onClose={onClose}
      animation="zoom"
      maskAnimation="fade"
    >
      <p className={classes.appModalTitle}>
        Всего заявок: {requested.length}
      </p>
      {requested.map((item) => (
        <div className={classes.appUsers}>
          <div className={classes.appUsersBlock}>
            {
              item.avatar_sub
                ? <img
                  src={`${process.env.REACT_APP_S3_URL}${item.avatar_sub}`}
                  alt="avatar"
                  className={classes.appUsersImg}
                />
                : <Avatar
                  name={item.name_sub || 'Нет имени'}
                  className={classes.appUsersImg}
                />
            }
            <div className={classes.appModalName}>{item.name_sub || 'Нет имени'}</div>
          </div>
          <div className={classes.appUsersBlock}>
            <TransparentBtn
              className={classes.appModalGrayBtn}
              onClick={(): Promise<void> => rejectRequest(item.sub_id)}>
              Отклонить
            </TransparentBtn>
            <TransparentBtn
              className={classes.appModalBtn}
              onClick={(): Promise<void> => acceptRequest(item.sub_id)}>
              Принять
            </TransparentBtn>
          </div>
        </div>
      ))}
    </Dialog>
  );
};
