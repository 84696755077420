import React from 'react';
import classNames from 'classnames';
import { useStyles } from './buttonLarge.styles';
import { IButtonLarge } from './types';

export const ButtonLarge = ({
  title,
  icon,
  disabled,
  type,
  callback,
}: IButtonLarge): JSX.Element => {
  const classes = useStyles();
  const iconActive = classNames(icon !== undefined ? classes.icon : undefined);

  return (
    <button type={type} disabled={disabled} className={classes.button} onClick={ (): void => (callback ? callback() : undefined)}>
      <img className={iconActive} src={icon} alt="" />
      {title}
    </button>
  );
};
