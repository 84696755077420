import { createUseStyles } from 'react-jss';

const PREFIX = 'Admin';
export const useStyles = createUseStyles(
  {
    mainBlock: {
      color: '#3B4168',
      lineHeight: '20px',
      borderRadius: '16px',
      fontFamily: 'Montserrat, sans-serif',
      background: '#FFFFFF',
    },
    mainHeader: {
      display: 'flex',
    },
    textGroup2: {
      color: '#909EBB',
      fontSize: '16px',
      fontWeight: '500',
      marginTop: '8px',
      cursor: 'pointer',
    },
    link: {
      textDecoration: 'none',
    },
    darkText: {
      color: '#3B4168',
    },
    line: {
      height: '15px',
      width: '1.5px',
      background: '#909EBB',
      margin: '10px 16px',
    },
    contentBlock: {
      display: 'flex',
      marginTop: '32px',
    },
    childContent1: {
      width: '67%',
    },
    childContent2: {
      width: '33%',
      height: '388px',
      display: 'flex',
      justifyContent: 'flex-end',
    },
    btnReject: {
      marginRight: '10px',
    },
    textBlock: {
      marginTop: '32px',
    },
    blockText1: {
      fontWeight: '500',
      fontSize: '16px',
      lineHeight: '20px',
      color: '#3B4168',
    },
    blockText2: {
      marginTop: '24px',
      fontWeight: '400',
      fontSize: '16px',
      lineHeight: '20px',
      color: '#6F7C98',
    },
    grayLine: {
      width: '100%',
      height: '1px',
      background: '#E4E7F2',
      margin: '32px 0',
    },
    uploadBtn: {
      border: 'none',
      padding: '0px',
    },
    docBlock: {
      display: 'flex',
      justifyContent: 'space-between',
      marginBottom: '24px',
    },
    usersList: {
      width: '80%',
      padding: '24px 0 32px 24px',
      border: '1px solid #E4E7F2',
      borderRadius: '16px',
      cursor: 'pointer',
    },
    userCount: {
      marginRight: '24px',
      marginTop: '-5px',
      width: '37px',
      height: '28px',
      borderRadius: '4px',
      background: '#F5FBFF',
      fontWeight: '400',
      fontSize: '14px',
      lineHeight: '20px',
      color: '#909EBB',
      display: 'flex',
      justifyContent: 'center',
      alignItems: 'center',
    },
    userListHeader: {
      display: 'flex',
      justifyContent: 'space-between',
    },
    userListBody: {
      height: '290px',
      overflowY: 'scroll',
      marginTop: '28px',
    },
  },
  { name: PREFIX },
);
