import React, { useContext, useEffect, useState } from 'react';
import { Link } from 'react-router-dom';
import { observer } from 'mobx-react';
import { TransparentBtn } from '../../../components/transparentBtn/TransparentBtn';
import { appStoreContext } from '../../../store/context/context.store';
import { documents, users } from '../../group/mockValues';
import { ApplicationsModal } from '../../modal/applications/Applications';
import { DocumentAddModal } from '../../modal/documentAdd/DocumentAdd';
import { GroupDeleteModal } from '../../modal/groupDelete/GroupDelete';
import { GroupSettings } from '../../modal/groupSettings/GroupSettings';
import { UserAddModal } from '../../modal/userAdd/UserAdd';
import { UsersListModal } from '../../modal/usersList/UsersList';
import { useStyles } from './admin.styles';
import { Document } from './documents/Document';
import { GroupHeader } from './groupHeader/GroupHeader';
import { UserApplication } from './userApplications/UserApplication';
import 'rc-tabs/assets/index.css';
import 'rc-input/assets/index.css';

export const Admin = observer((): JSX.Element => {
  const [appVisible, setAppVisible] = useState(false);
  const [usersVisible, setUsersVisible] = useState(false);
  const [settingsVisible, setSettingsVisible] = useState(false);
  const [docAddVisible, setDocAddVisible] = useState(false);
  const [userAddVisible, setUserAddVisible] = useState(false);
  const [groupDeleteVisible, setGroupDeleteVisible] = useState(false);
  const searchString = new URLSearchParams(window.location.search);
  const appStore = useContext(appStoreContext);
  const groupDetails = appStore.group.groupDetails;
  const groupId = searchString.get('groupId');
  const classes = useStyles();

  useEffect(() => {
    appStore.group.findGroupById(groupId);
  }, []);

  const deleteGroup = (): void => {
    setSettingsVisible(false);
    setGroupDeleteVisible(true);
  };

  return (
    <div className={classes.mainBlock}>
      <div className={classes.mainHeader}>
        <Link to="/groups" className={classes.link}>
          <div className={classes.textGroup2}>
            Группы
          </div>
        </Link>
        <span className={classes.line}></span>
        <div className={`${classes.textGroup2} ${classes.darkText}`}>
          {groupDetails?.name}
        </div>
      </div>
      <GroupHeader
        setSettingsVisible={setSettingsVisible}
        setUserAddVisible={setUserAddVisible}
        groupDetails={groupDetails}
      />
      <div className={classes.contentBlock}>
        <div className={classes.childContent1}>
          <UserApplication setAppVisible={setAppVisible} />
          <div className={classes.textBlock}>
            <div className={classes.blockText1}>Описание группы</div>
            <div className={classes.blockText2}>
              {groupDetails?.desc}
            </div>
          </div>
          <div className={classes.grayLine}></div>
          <div>
            <div className={classes.docBlock}>
              <div className={classes.blockText1}>Документы группы</div>
              <div>
                <TransparentBtn
                  className={classes.uploadBtn}
                  onClick={(): void => setDocAddVisible(true)}
                >
                  + Добавить документ
                </TransparentBtn>
              </div>
            </div>
            <div>
              {documents.map((item) => (
                <Document item={item} />
              ))}
            </div>
          </div>
        </div>
        <div className={classes.childContent2}>
          <div
            className={classes.usersList}
            onClick={(): void => setUsersVisible(true)}
          >
            <div className={classes.userListHeader}>
              <div className={classes.blockText1}>Список участников</div>
              <div className={classes.userCount}>{users.length}</div>
            </div>
            <div className={classes.userListBody}>
              {/* {users.map((item) => (
                <User item={item} />
              ))} */}
            </div>
          </div>
        </div>
      </div>
      <ApplicationsModal
        visible={appVisible}
        onClose={(): void => setAppVisible(false)}
        setAppVisible={setAppVisible}
      />
      <UsersListModal
        visible={usersVisible}
        onClose={(): void => setUsersVisible(false)}
      />
      <GroupSettings
        visible={settingsVisible}
        onClose={(): void => setSettingsVisible(false)}
        onDelete={deleteGroup}
        setSettingsVisible={setSettingsVisible}
        groupDetails={groupDetails}
      />
      <DocumentAddModal
        visible={docAddVisible}
        onClose={(): void => setDocAddVisible(false)}
      />
      <UserAddModal
        visible={userAddVisible}
        onClose={(): void => setUserAddVisible(false)}
      />
      <GroupDeleteModal
        visible={groupDeleteVisible}
        onClose={(): void => setGroupDeleteVisible(false)}
      />
    </div>
  );
});
