import React from 'react';
import { IProps } from './types';
import { useStyles } from './userApplications.styles';
import { UserRequests } from './userRequests/UserRequests';

export const UserApplication = ({ setAppVisible }: IProps): JSX.Element => {
  const classes = useStyles();

  return (
    <div className={classes.userRequest}>
      <UserRequests setAppVisible={setAppVisible} />
    </div>
  );
};
