import { createUseStyles } from 'react-jss';
import { theme } from '../../../components/theme';

const PREFIX = 'Switch';
export const useStyles = createUseStyles({
  reactSwitchCheckbox: {
    height: 0,
    width: 0,
    visibility: 'hidden',
  },
  reactSwitchLabel: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',
    width: '32px',
    height: '18px',
    background: 'grey',
    borderRadius: '100px',
    position: 'relative',
    transition: 'background-color .2s',
    marginTop: '2px',
    cursor: 'pointer',
    '&:active $reactSwitchLabel, &$reactSwitchButton': {
      width: '60px',
    },
  },
  disabled: {
    cursor: 'default',
  },
  reactSwitchButton: {
    content: '""',
    position: 'absolute',
    top: '2px',
    left: '2px',
    width: '14px',
    height: '14px',
    borderRadius: '50%',
    transition: '0.2s',
    background: '#fff',
    boxShadow: '0 0 2px 0 rgba(10, 10, 10, 0.29)',
  },
  reactSwitchActive: {
    background: theme.colorPrimary,
  },
  reactSwitchButtonActive: {
    left: 'calc(100% - 16px)',
  },
}, { name: PREFIX });
